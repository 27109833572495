import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Operation, Permission, Role, RoleType } from "../../api/Authz";
import { roleKey, Roles } from "../../api/portal/hooks/Roles";
import { useAddCustomerPermissionBaseRoleMutation, useAddCustomerPermissionCommonRoleMutation, useRemoveCustomerPermissionBaseRoleMutation, useRemoveCustomerPermissionCommonRoleMutation } from "../../api/portal/Permissions";
import { useCustomerPermission } from "../../auth/AuthorizerProvider";
import { LoadingDialog } from "../common/dialog/LoadingDialog";
import { SavedIndicator } from "../common/dialog/SavedIndicator";
import { ImpliedRolesTooltip } from "./ImpliedRolesTooltip";
import { RoleChip } from "./RoleChip";

export interface EditRolesDialogProps {
    customerID: number
    permission: Permission
    roles: Roles
    onClose: () => void
}

export function EditRolesDialog(props: EditRolesDialogProps) {
    const { customerID, permission, roles, onClose } = props

    const { t } = useTranslation()
    const theme = useTheme()

    const baseRoles = useMemo(() => roles.customerRoles.filter(r => r.type === RoleType.BASE_ROLE), [roles.customerRoles])
    const commonRoles = useMemo(() => roles.customerRoles.filter(r => r.type === RoleType.COMMON_ROLE), [roles.customerRoles])

    const [addBaseRole, addBaseRoleMutation] = useAddCustomerPermissionBaseRoleMutation()
    const [removeBaseRole, removeBaseRoleMutation] = useRemoveCustomerPermissionBaseRoleMutation()
    const [addCommonRole, addCommonRoleMutation] = useAddCustomerPermissionCommonRoleMutation()
    const [removeCommonRole, removeCommonRoleMutation] = useRemoveCustomerPermissionCommonRoleMutation()

    const allowAddRole = useCustomerPermission(Operation.ADD_CUSTOMER_PERMISSION_ROLE, customerID)
    const allowRemoveRole = useCustomerPermission(Operation.REMOVE_CUSTOMER_PERMISSION_ROLE, customerID)

    const selected = useMemo(() =>
        new Map(permission.roles?.map(r => [roleKey(r), r])) || [],
        [permission.roles],
    )
    const isSelected = useCallback((r: Role) => !!selected.get(roleKey(r)), [selected])

    const toggleRole = (r: Role) => {
        const task = {
            customerID: customerID,
            permissionID: +permission.id,
            roleID: +r.id,
        }
        switch (r.type) {
            case RoleType.BASE_ROLE:
                if (isSelected(r)) {
                    removeBaseRole(task)
                } else {
                    addBaseRole(task)
                }
                break;
            case RoleType.COMMON_ROLE:
                if (isSelected(r)) {
                    removeCommonRole(task)
                } else {
                    addCommonRole(task)
                }
                break;
            default:
                console.error("Unknown role type", r.type)
        }
    }

    const renderRole = (r: Role) => {
        const impliedBy = roles.baseRoleImpliedBy(r, permission.roles)
        const isImplied = impliedBy.length > 0 && r.type === RoleType.BASE_ROLE
        return (
            <ListItem key={roleKey(r)} onClick={() => toggleRole(r)} sx={{ p: 0 }}>
                <ListItemButton disabled={isSelected(r) ? !allowRemoveRole : !allowAddRole}>
                    <ListItemIcon>
                        <Tooltip title={t("action.grant_role_tooltip")} disableInteractive>
                            <Checkbox checked={isSelected(r)} size="small" />
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText>
                        <RoleChip key={roleKey(r)} role={r} roles={roles} />
                    </ListItemText>
                    {isImplied &&
                        <ListItemIcon sx={{ minWidth: 0, pl: 1 }}>
                            <Tooltip disableHoverListener={!isImplied} title={<ImpliedRolesTooltip impliedBy={impliedBy} roles={roles} />}>
                                <CheckCircle fontSize="small" htmlColor={theme.palette.text.secondary} />
                            </Tooltip>
                        </ListItemIcon>
                    }
                </ListItemButton>
            </ListItem>
        )
    }

    return (
        <>
            <Dialog onClose={onClose} aria-labelledby="edit-roles-dialog-title" open={true}>
                <DialogTitle id="edit-roles-dialog-title">
                    {t("dialog.permission.edit_roles_title")}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack direction="row" spacing={1}>
                        <List dense>
                            {commonRoles.length > 0 &&
                                <>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                {t("role.common_roles")}:
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    {commonRoles.map(renderRole)}
                                </>
                            }
                            {baseRoles.length > 0 &&
                                <>
                                    <ListItem>
                                        <ListItemText>
                                            {t("role.base_roles")}:
                                        </ListItemText>
                                    </ListItem>
                                    {baseRoles.map(renderRole)}
                                </>
                            }
                        </List>
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ px: 2 }}>
                    <SavedIndicator
                        mutations={[
                            addBaseRoleMutation,
                            removeBaseRoleMutation,
                            addCommonRoleMutation,
                            removeCommonRoleMutation,
                        ]}
                    />
                    <Box flexGrow={1} />
                    <Button onClick={onClose} variant="contained" color="primary">
                        {t("action.close")}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingDialog
                queries={roles.roleQueries}
                mutations={[
                    addBaseRoleMutation,
                    removeBaseRoleMutation,
                    addCommonRoleMutation,
                    removeCommonRoleMutation,
                ]}
            />
        </>
    )
}

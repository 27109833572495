import { CalendarMonth, Close, Download, Expand, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, MoveUp, Pause, PlayArrow, SlowMotionVideo } from "@mui/icons-material"
import { Stack, useTheme } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Site, Unit } from "../../api/Customer"
import { AddDelay, CurrentDelay, CurrentTime, IsLive, Live, Paused, Playback, Resumed, SetSpeed, SetTime } from "../../api/Video"
import { Minute, Second } from "../../config/time"
import { NewFilter } from "../../services/EventFilter"
import { ArchiveExportDialog } from "../video/ArchiveExportDialog"
import { DateTimePickerDialog } from "./DateTimePickerDialog"
import { TimeShiftButton } from "./TimeShiftButton"
import { TimelineChart } from "./TimelineChart"
import { AsChoice, DoubleSpeed, HalfSpeed, NormalSpeed, NumberChoice, OneDayWindow, OneHourWindow, QuadrupleSpeed, SixteenTimesSpeed, TenMinutesWindow, ThirtyTwoTimesSpeed, TimelineChoice, TwelveHoursWindow } from "./TimelineChoice"

export interface TimelineProps {
    small: boolean
    playback: Playback
    overlay?: boolean
    parent?: Element
    hasEvents: boolean
    eventsPlayback: boolean
    window: NumberChoice
    allowDownload: boolean
    site: Site
    units: Unit[]
    onWindowChange?: (window: NumberChoice) => void
    onPlaybackChange: (playback: Playback) => void
    onClose: () => void
    onSetEventsPlayback: (value: boolean) => void
}

const speeds = [HalfSpeed, NormalSpeed, DoubleSpeed, QuadrupleSpeed, SixteenTimesSpeed, ThirtyTwoTimesSpeed]
const windows = [TenMinutesWindow, OneHourWindow, TwelveHoursWindow, OneDayWindow]
const delayDiff = 30 * Second

export const Timeline = (props: TimelineProps) => {
    const {
        small, playback, overlay, parent, hasEvents, eventsPlayback, window, allowDownload, site, units,
        onWindowChange, onPlaybackChange, onClose, onSetEventsPlayback } = props

    const { t } = useTranslation()
    const theme = useTheme()

    const [pickingDate, setPickingDate] = useState(false)
    const [exportingArchive, setExportingArchive] = useState(false)

    const onSpeedChange = (speed: NumberChoice) => onPlaybackChange(SetSpeed(playback, speed.value))
    const onPageBackward = () => onPlaybackChange(AddDelay(playback, window.value))
    const onPageForward = () => onPlaybackChange(AddDelay(playback, -window.value))
    const onJumpBackward = () => onPlaybackChange(AddDelay(playback, IsLive(playback) ? 2 * Minute : delayDiff))
    const onJumpForward = () => onPlaybackChange(CurrentDelay(playback) <= 130 * Second ? Live() : AddDelay(playback, -delayDiff))
    const onPlay = () => onPlaybackChange(Resumed(playback))
    const onPause = () => onPlaybackChange(Paused(playback))

    const pickDateButton = <TimeShiftButton
        icon={<CalendarMonth />}
        tooltip={t("dialog.pick_date_time_tooltip")}
        onClick={() => setPickingDate(true)}
    />

    const archiveExportButton = <TimeShiftButton
        icon={<Download />}
        tooltip={t("dialog.archive_export.tooltip")}
        disabledHint={t("dialog.archive_export.missing_rights")}
        onClick={() => setExportingArchive(true)}
        disabled={!allowDownload}
    />

    const jumpRangeBack = <TimeShiftButton
        icon={<FirstPage />}
        tooltip={t("camera.jump_back", { "step": window.label })}
        onClick={onPageBackward}
    />

    const jump30sBack = <TimeShiftButton
        icon={<KeyboardArrowLeft />}
        tooltip={t("camera.jump_back", { "step": "30s" })}
        onClick={onJumpBackward}
    />

    const togglePlayButton = playback.Paused ?
        <TimeShiftButton
            icon={<PlayArrow />}
            tooltip={t("camera.playback_resume_tooltip")}
            onClick={onPlay}
        /> :
        <TimeShiftButton
            icon={<Pause />}
            tooltip={t("camera.playback_pause_tooltip")}
            disabled={IsLive(playback)}
            onClick={onPause}
        />

    const jump30sForward = <TimeShiftButton
        icon={<KeyboardArrowRight />}
        tooltip={t("camera.jump_forward", { "step": "30s" })}
        disabled={IsLive(playback)}
        onClick={onJumpForward}
    />

    const jumpRangeForward = <TimeShiftButton
        icon={<LastPage />}
        tooltip={t("camera.jump_forward", { "step": window.label })}
        disabled={IsLive(playback)}
        onClick={onPageForward}
    />

    const playbackSpeedChoice = <TimelineChoice
        tooltip="camera.playback_speed_tooltip"
        disabled={IsLive(playback)}
        icon={<SlowMotionVideo />}
        choice={AsChoice(playback.Speed, speeds)}
        choices={speeds}
        parent={parent}
        onChange={onSpeedChange}
    />

    const timelineRangeChoice = <TimelineChoice
        tooltip="camera.timeline_range_tooltip"
        icon={<Expand sx={{ transform: "rotate(90deg)" }} />}
        choice={window}
        choices={windows}
        parent={parent}
        onChange={window => onWindowChange?.(window)}
    />

    const exitArchiveButton = <TimeShiftButton
        icon={<Close />}
        tooltip={t("camera.archive_disable_tooltip")}
        onClick={onClose}
        color="error"
    />

    const toggleEventsPlayback = <TimeShiftButton
        icon={<MoveUp sx={{ transform: "scale(0.9)" }} />}
        tooltip={t("camera.skip_events_tooltip")}
        disabled={IsLive(playback) || !hasEvents}
        onClick={() => onSetEventsPlayback(!eventsPlayback)}
        color={eventsPlayback ? "warning" : "primary"}
    />

    return (
        <>
            {small ?
                <Stack
                    width="100%" spacing={0.5} alignItems="center"
                    sx={{
                        borderWidth: overlay ? 0 : 1,
                        borderStyle: "solid",
                        borderColor: theme.palette.panel.border,
                        backgroundColor: theme.palette.panel.background,
                    }}
                    p={overlay ? 1 : 0.5}
                >
                    <Grid width="100%" container columns={11} spacing={0.5} alignItems="center">
                        <Grid xs={1}>
                            {pickDateButton}
                        </Grid>
                        <Grid xs={1}>
                            {archiveExportButton}
                        </Grid>
                        <Grid xs={9}>
                            <TimelineChart
                                step={Second}
                                window={window.value}
                                small={small}
                                playback={playback}
                                onPlaybackChange={onPlaybackChange}
                            />
                        </Grid>
                        <Grid xs={2}>
                            {playbackSpeedChoice}
                        </Grid>
                        <Grid xs={2}>
                            {timelineRangeChoice}
                        </Grid>
                        <Grid xs={1}>
                            {toggleEventsPlayback}
                        </Grid>
                        <Grid xs={1}>
                            {jumpRangeBack}
                        </Grid>
                        <Grid xs={1}>
                            {jump30sBack}
                        </Grid>
                        <Grid xs={1}>
                            {togglePlayButton}
                        </Grid>
                        <Grid xs={1}>
                            {jump30sForward}
                        </Grid>
                        <Grid xs={1}>
                            {jumpRangeForward}
                        </Grid>
                        <Grid xs={1}>
                            {exitArchiveButton}
                        </Grid>
                    </Grid>
                </Stack >
                :
                <Stack
                    width="100%" spacing={0.5} alignItems="center"
                    direction="row"
                    sx={{
                        borderWidth: overlay ? 0 : 1,
                        borderStyle: "solid",
                        borderColor: theme.palette.panel.border,
                        backgroundColor: theme.palette.panel.background,
                    }}
                    p={overlay ? 1 : 0.5}
                >
                    {pickDateButton}
                    {archiveExportButton}
                    <TimelineChart
                        step={Second}
                        window={window.value}
                        small={small}
                        playback={playback}
                        onPlaybackChange={onPlaybackChange}
                    />
                    {jumpRangeBack}
                    {jump30sBack}
                    {togglePlayButton}
                    {jump30sForward}
                    {jumpRangeForward}
                    {playbackSpeedChoice}
                    {timelineRangeChoice}
                    {toggleEventsPlayback}
                    {exitArchiveButton}
                </Stack>
            }
            {pickingDate &&
                <DateTimePickerDialog
                    initialValue={new Date(CurrentTime(playback))}
                    onClose={() =>
                        setPickingDate(false)
                    }
                    onPicked={value => {
                        setPickingDate(false)
                        onPlaybackChange(SetTime(playback, value.getTime()))
                    }}
                    parent={parent}
                />
            }
            {exportingArchive &&
                <ArchiveExportDialog
                    initialTime={new Date(CurrentTime(playback))}
                    initialFilter={NewFilter(true, new Set(), [], [])}
                    site={site}
                    units={units}
                    parent={parent}
                    onClose={() => setExportingArchive(false)}
                />
            }
        </>
    )
}

import { Box, Button, Typography } from "@mui/material"
import { createRef, memo, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { AutoSizer, List, ListRowProps } from 'react-virtualized'
import { Event } from "../../api/Event"
import { CurrentTime, IsLive, NewPlayback, Playback } from "../../api/Video"
import { CropToLocalDate, EqualLocalDay, IsEmpty, Second, Since, TimeRange, setIntervalAndRun } from "../../config/time"
import { CurrentTimeBox } from "./CurrentTimeBox"
import { DateBox } from "./DateBox"
import { EventBox } from "./EventBox"
import { LoadingBox } from "./LoadingBox"

const archiveJumpAhead = 5 * Second
const archiveKeepEventFor = 5 * Second
const minimalGap = 20 * Second

export type EventListRowsProps = {
    events: Event[]
    playback: Playback
    eventsPlayback: boolean,
    fetching: boolean
    online: boolean
    error: Error | null
    range: TimeRange
    onFetchNewer: () => void
    onFetchOlder: () => void
    onPlayback: (playback: Playback) => void
}

type Row =
    | { type: "FetchNewer" }
    | { type: "Empty" }
    | { type: "Error", error: Error }
    | { type: "Event", event: Event }
    | { type: "Fetching" }
    | { type: "Timestamp" }
    | { type: "Date", date: Date }
    | { type: "FetchOlder" }
    ;

export const EventListRows = memo((props: EventListRowsProps) => {
    const { events, playback, eventsPlayback, fetching, online, range, error, onFetchNewer, onFetchOlder, onPlayback } = props
    const { t } = useTranslation()

    const [currentTime, setCurrentTime] = useState(CurrentTime(playback))
    const [lastLive, setLastLive] = useState(IsLive(playback))

    const live = useMemo(() => IsLive(playback), [playback])

    const onFocusEvent = useCallback((event: Event) => {
        const delay = Since(event.timestamp) + archiveJumpAhead
        onPlayback(NewPlayback(delay, false, playback.Speed))
    }, [onPlayback, playback.Speed])

    const position = useMemo(() => {
        if (live) {
            return 0
        }
        const i = events.findIndex(e => currentTime > e.timestamp.getTime())
        return i === -1 ? events.length : i
    }, [live, currentTime, events])

    const rows = useMemo((): Row[] => {
        const merged: Row[] = []

        // Add FetchNewer button.
        if (!fetching && !online && range.end !== null) {
            merged.push({ type: "FetchNewer" })
        }
        // Add error message.
        if (error != null) {
            merged.push({ type: "Error", error: error })
        }

        // Add all the messages.
        if (events.length > 0) {
            var lastTimestamp = events[0].timestamp

            if (!live) {
                merged.push({ type: "Date", date: CropToLocalDate(lastTimestamp) })
            }

            events.forEach((e, i) => {
                if (!EqualLocalDay(lastTimestamp, e.timestamp)) {
                    merged.push({ type: "Date", date: CropToLocalDate(e.timestamp) })
                }
                if (i === position) {
                    merged.push({ type: "Timestamp" })
                }
                lastTimestamp = e.timestamp
                merged.push({ type: "Event", event: e })
            })
            if (position === events.length) {
                merged.push({ type: "Timestamp" })
            }
        } else {
            merged.push({ type: "Empty" })
        }

        // Add loading indicator.
        if (fetching) {
            merged.push({ type: "Fetching" })
        } else {
            merged.push({ type: "FetchOlder" })
        }
        return merged
    }, [events, online, fetching, error, position, live, range.end])

    const rowRenderer = (row: Row) => {
        switch (row.type) {
            case "Empty":
                return <Typography fontSize="small" noWrap textOverflow="ellipsis" fontStyle="italic">{t("event.no_events")}</Typography>
            case "Event":
                return <EventBox event={row.event} onFocusEvent={onFocusEvent} />
            case "Error":
                return <Typography fontSize="small" noWrap textOverflow="ellipsis" color="orange" fontStyle="italic">{t("error")}: {row.error.message}</Typography>
            case "Date":
                return <DateBox date={row.date} />
            case "Timestamp":
                return <CurrentTimeBox live={live} playback={playback} />
            case "Fetching":
                return <LoadingBox />
            case "FetchNewer":
                return <Button variant="outlined" size="small" sx={{ width: "100%" }} onClick={onFetchNewer}>{t("action.load_newer")}</Button>
            case "FetchOlder":
                return <Button variant="outlined" size="small" sx={{ width: "100%" }} onClick={onFetchOlder}>{t("action.load_older")}</Button>
        }
    }

    const rowHeight = (params: { index: number }) => {
        const r = rows[params.index]
        switch (r.type) {
            case "FetchOlder":
            case "FetchNewer":
                return 36
            case "Event":
            case "Fetching":
            case "Empty":
            case "Error":
                return 30
            case "Date":
                return 18
            case "Timestamp":
                return 8
        }
    }

    const rowCachedRender = ({ index, key, style }: ListRowProps) =>
        <Box key={key} style={style} width="100%" px={0.5} py={0.25}>
            {rowRenderer(rows[index])}
        </Box>

    const listRef = createRef<List>()

    useEffect(() => {
        listRef.current?.recomputeRowHeights(0)
    }, [rows, listRef])

    useEffect(() => {
        const interval = setIntervalAndRun(() => setCurrentTime(CurrentTime(playback)), Second)
        return () => clearInterval(interval)
    }, [playback])

    useEffect(() => {
        // Jump to the top when switching to live.
        if (live && !lastLive) {
            listRef.current?.scrollToRow(0)
        }
        setLastLive(live)
    }, [listRef, live, lastLive])

    useEffect(() => {
        if (live || !eventsPlayback || events.length === 0) {
            return
        }

        const current = events.findIndex(e => currentTime > e.timestamp.getTime())
        const next = (current < 0 ? events.length : current) - 1
        if (next < 0) {
            // No next event. Keep going at current speed.
            return
        }
        if (current >= 0 && currentTime <= (events[current].timestamp.getTime() + archiveKeepEventFor * playback.Speed)) {
            // The current event is still playing, keep going.
            return
        }
        if (currentTime >= (events[next].timestamp.getTime() - minimalGap)) {
            // Too small a gap, keep going.
            return
        }

        onFocusEvent(events[next])

    }, [live, currentTime, events, eventsPlayback, onFocusEvent, playback.Speed])

    return (
        <AutoSizer>
            {({ width, height }) =>
                <List
                    ref={listRef}
                    direction="column"
                    height={height}
                    width={width}
                    rowHeight={rowHeight}
                    rowCount={rows.length}
                    rowRenderer={rowCachedRender}
                    overscanRowCount={50}
                    style={{ outline: "none" }}
                    onScroll={({ clientHeight, scrollTop, scrollHeight }) => {
                        if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
                            if (!fetching && !IsEmpty(range)) {
                                onFetchOlder()
                            }
                        } else if (scrollTop === 0) {
                            if (!fetching && !online && !IsEmpty(range)) {
                                onFetchNewer()
                                // Scroll a small bit down to be able to receive the next scroll event.
                                listRef.current?.scrollToPosition(1)
                            }
                        }
                    }}
                />
            }
        </AutoSizer >
    )
})

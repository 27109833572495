import { Check, Error } from "@mui/icons-material";
import { CircularProgress, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RTKMutation } from "../../../api/portal/hooks/RTK";
import { errorMessage } from "../../../config/error";

export interface SavedIndicatorProps {
    mutations?: RTKMutation[]
}

const compareMutations = (m1: RTKMutation, m2: RTKMutation) => {
    if (!m1.startedTimeStamp && !m2.startedTimeStamp) {
        return 0
    }
    if (!m2.startedTimeStamp) {
        return -1
    }
    if (!m1.startedTimeStamp) {
        return 1
    }
    return m2.startedTimeStamp - m1.startedTimeStamp
}

export const SavedIndicator = (props: SavedIndicatorProps) => {
    const { mutations } = props

    const { t } = useTranslation()
    const theme = useTheme()

    const lastAction = useMemo(() => [...mutations || []].sort(compareMutations).at(0), [mutations])
    const isActive = useMemo(() => !!mutations?.find(m => m.isLoading), [mutations])
    const isError = useMemo(() => !isActive && lastAction?.isError, [lastAction, isActive])
    const error = useMemo(() => errorMessage(lastAction?.error), [lastAction])
    const isSuccess = useMemo(() => !isActive && lastAction?.isSuccess, [lastAction, isActive])

    return (
        <>
            {isActive &&
                <CircularProgress size="18px" sx={{ color: theme.palette.grey[500] }} />
            }
            {
                isError &&
                <Tooltip title={`${t("error")}: ${error}.`}>
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Error fontSize="small" color="error" />
                        <Typography variant="body2" color={theme.palette.error.main}>
                            {t("message.saving_failed")}
                        </Typography>
                    </Stack>
                </Tooltip>
            }
            {
                isSuccess &&
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Check fontSize="small" htmlColor={theme.palette.grey[500]} />
                    <Typography variant="body2" color={theme.palette.grey[500]}>
                        {t("message.saved")}
                    </Typography>
                </Stack>
            }
        </>
    )
}

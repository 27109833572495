import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Unit } from "../../api/Customer";
import { Item, Payload } from "../../api/OpenHAB";
import { OpenHABStream } from "../../api/OpenHABStream";
import { http } from "../../backend/request";
import { openHabEndpointURL } from "../../config/urls";
import { MultiChart } from "../common/MultiChart";
import { OneDayRange, RangeChoice } from "../common/RangeChoice";

const itemExists = (item?: Item) => !!item
const itemValue = (item?: Item) => !item || item.state === "NULL" || item.state === "UNDEF" ?
    "?" : (+item.state).toFixed(2)

export interface StatisticsProps {
    unit: Unit
}

export function Statistics(props: StatisticsProps) {
    const { unit } = props

    const [items, setItems] = useState<Item[]>([])
    const [range, setRange] = useState(OneDayRange)

    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()

    const relevantZones = useMemo(
        () => new Set([
            "PRO380_ActivePower_Total",
            "EPEVER_3102_number",
            "EPEVER2_3102_number",
            "EPEVER3_3102_number",
            "EPEVER4_3102_number",
            "BatVoltageMAX",
            "LoadPowerSUM",
        ]),
        []
    )
    const powerLines = useMemo(() => [
        {
            name: "PRO380_ActivePower_Total",
            label: `Line 1`,
            color: theme.palette.primary.main,
        }], [theme])
    const solarPanels = useMemo(() => [
        {
            name: "EPEVER_3102_number",
            label: "Panel 1",
            color: "rgb(255,200,50)",
        },
        {
            name: "EPEVER2_3102_number",
            label: "Panel 2",
            color: "rgb(255,80,0)",
        },
        {
            name: "EPEVER3_3102_number",
            label: "Panel 3",
            color: "rgb(255,40,10)",
        },
        {
            name: "EPEVER4_3102_number",
            label: "Panel 4",
            color: "rgb(255,160,40)",
        },
    ], [])
    const batteries = useMemo(() => [
        {
            name: "BatVoltageMAX",
            label: "Battery 1",
            color: "#09c809",
        },
    ], [])
    const loads = useMemo(() => [
        {
            name: "LoadPowerSUM",
            label: "Load",
            color: "rgb(255,50,20)",
        },
    ], [])
    const isRelevant = useCallback(
        (name: string) => relevantZones.has(name),
        [relevantZones]
    )

    const reloadItems = useCallback(
        () => {
            http<Item[]>('Getting unit items', openHabEndpointURL(unit, `items`), snackbar)
                .then(result => setItems(
                    result
                        .filter(item => isRelevant(item.name))
                        .sort((a, b) => a.name > b.name ? 1 : -1)
                ))
                .catch(e => console.log(e))
        },
        [isRelevant, snackbar, unit]
    )

    const onOpenHABMessage = useCallback(
        (name: string, payload: Payload) => {
            console.log("changing item", name, "to", payload.value)
            setItems(old => old.map(item => item.name === name ? {
                name: item.name,
                groupNames: item.groupNames,
                tags: item.tags,
                label: item.label,
                state: payload.value,
            } : item))
        },
        [])

    const onOpenHABConnected = useCallback(
        () => {
            console.log("stream connected")
            reloadItems()
        },
        [reloadItems]
    )

    const activePower = useMemo(
        () => items.find(item => item.name === "PRO380_ActivePower_Total"), [items])
    const solar1 = useMemo(
        () => items.find(item => item.name === "EPEVER_3102_number"), [items])
    const solar2 = useMemo(
        () => items.find(item => item.name === "EPEVER2_3102_number"), [items])
    const solar3 = useMemo(
        () => items.find(item => item.name === "EPEVER3_3102_number"), [items])
    const solar4 = useMemo(
        () => items.find(item => item.name === "EPEVER4_3102_number"), [items])
    const battery = useMemo(
        () => items.find(item => item.name === "BatVoltageMAX"), [items])
    const load = useMemo(
        () => items.find(item => item.name === "LoadPowerSUM"), [items])

    const renderValue = useCallback(
        (label: string, item: Item | undefined, valueUnit: string, color?: string) =>
            itemExists(item) ?
                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Stack sx={{ backgroundColor: theme.palette.background.paper }} p={1}>
                        <Typography>{label}</Typography>
                        <Typography color={color || theme.palette.primary.main}>
                            {itemValue(item)} {valueUnit}
                        </Typography>
                    </Stack>
                </Grid> :
                null, [theme])

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                        <OpenHABStream
                            unit={unit}
                            itemFilter={isRelevant}
                            onMessage={onOpenHABMessage}
                            onConnected={onOpenHABConnected}
                        />
                    </Box>
                    <RangeChoice value={range} onChange={setRange} />
                </Stack>
                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.power_output")}</Typography>
                    </Grid>
                    {itemExists(activePower) ?
                        <>
                            {renderValue(t("statistics.power_line", { idx: 1 }), activePower, "kW", powerLines[0].color)}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        width: "100%",
                                        height: {
                                            xs: "150px",
                                            sm: "200px",
                                            md: "250px",
                                            lg: "300px",
                                        }
                                    }}
                                    p={1}
                                >
                                    <MultiChart
                                        unit={unit}
                                        items={powerLines}
                                        millis={range.millis}
                                        valueUnit="kW"
                                        startValue={0}
                                    />
                                </Box>
                            </Grid>
                        </> :
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_power_statistics")}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.solar_panels")}</Typography>
                    </Grid>
                    {(itemExists(solar1) || itemExists(solar2) || itemExists(solar3) || itemExists(solar4)) ?
                        <>
                            {renderValue(t("statistics.solar_panel", { idx: 1 }), solar1, "W", solarPanels[0].color)}
                            {renderValue(t("statistics.solar_panel", { idx: 2 }), solar2, "W", solarPanels[1].color)}
                            {renderValue(t("statistics.solar_panel", { idx: 3 }), solar3, "W", solarPanels[2].color)}
                            {renderValue(t("statistics.solar_panel", { idx: 4 }), solar4, "W", solarPanels[3].color)}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        width: "100%",
                                        height: {
                                            xs: "150px",
                                            sm: "200px",
                                            md: "250px",
                                            lg: "300px",
                                        }
                                    }}
                                    p={1}
                                >
                                    <MultiChart
                                        unit={unit}
                                        items={solarPanels}
                                        millis={range.millis}
                                        valueUnit="W"
                                        startValue={0}
                                    />
                                </Box>
                            </Grid>
                        </> :
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_solar_statistics")}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.batteries")}</Typography>
                    </Grid>
                    {itemExists(battery) ?
                        <>
                            {renderValue(t("statistics.battery", { idx: 1 }), battery, "V", batteries[0].color)}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        width: "100%",
                                        height: {
                                            xs: "150px",
                                            sm: "200px",
                                            md: "250px",
                                            lg: "300px",
                                        }
                                    }}
                                    p={1}
                                >
                                    <MultiChart
                                        unit={unit}
                                        items={batteries}
                                        millis={range.millis}
                                        valueUnit="V"
                                        rulers={[
                                            {
                                                label: t("statistics.battery_full"),
                                                value: 25.5,
                                                color: "green",
                                            },
                                            {
                                                label: t("statistics.battery_low"),
                                                value: 23.1,
                                                color: "orange",
                                            },
                                            {
                                                label: t("statistics.battery_off"),
                                                value: 22.0,
                                                color: "red",
                                            },
                                        ]} />
                                </Box>
                            </Grid>
                        </> :
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_battery_statistics")}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.load")}</Typography>
                    </Grid>
                    {itemExists(load) ?
                        <>
                            {renderValue(t("statistics.load"), load, "W", loads[0].color)}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        width: "100%",
                                        height: {
                                            xs: "150px",
                                            sm: "200px",
                                            md: "250px",
                                            lg: "300px",
                                        }
                                    }}
                                    p={1}
                                >
                                    <MultiChart
                                        unit={unit}
                                        items={loads}
                                        millis={range.millis}
                                        valueUnit="W"
                                    />
                                </Box>
                            </Grid>
                        </> :
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_load_statistics")}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Stack >
    )
}

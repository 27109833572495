import { MobiledataOff, TaskAlt } from "@mui/icons-material";
import { Tooltip, Typography, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { emphasized } from "../../theme/Theme";
import { useTranslation } from "react-i18next";

export interface ConnectionStatusProps {
    online: boolean;
}

export function ConnectionStatus(props: ConnectionStatusProps) {
    const { online } = props;

    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Stack direction="row" sx={{ width: "100%" }}>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip
                title={
                    online ? t("action.online_tooltip") : t("action.offline_tooltip")
                }
                disableInteractive
            >
                <Stack direction="row" sx={{ alignItems: "center" }} spacing={0.2}>
                    <Typography
                        variant="caption"
                        color={online ? emphasized(0.2, theme) : theme.palette.warning.main}
                    >
                        {online ? t("action.online") : t("action.offline")}
                    </Typography>
                    {online ? (
                        <TaskAlt fontSize="small" htmlColor={emphasized(0.2, theme)} />
                    ) : (
                        <MobiledataOff
                            fontSize="small"
                            htmlColor={theme.palette.warning.main}
                        />
                    )}
                </Stack>
            </Tooltip>
        </Stack>
    );
}

import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Customer, Site, Unit } from "../api/Customer"
import { User } from "../api/User"
import { minPageWidth } from "../config/sizing"
import { PageError } from "./PageError"
import { UnitAppBar } from "./UnitAppBar"

export interface UnitPageProps {
    actor: User
    unit?: Unit
    site?: Site
    customers: Customer[]
    allowed: boolean
    render: (unit: Unit, site: Site) => React.ReactNode
}

export const UnitPage = (props: UnitPageProps) => {
    const { actor, unit, site, customers, allowed, render } = props

    const { shortName } = useParams()
    const { t } = useTranslation()

    const renderBody = () => {
        if (!allowed) {
            return <PageError message={t("message.forbidden_page")} />
        }
        if (!unit) {
            return <PageError message={t("message.unit_not_found", { unit: shortName })} />
        }
        if (!site) {
            return <PageError message={t("message.site_not_found", { id: unit?.SiteID })} />
        }
        return render(unit, site)
    }

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", minWidth: minPageWidth, flexDirection: "column" }}>
            <UnitAppBar
                actor={actor}
                unit={unit}
                site={site}
                customers={customers}
            />
            {renderBody()}
        </Box>
    )
}

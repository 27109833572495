import { useCallback, useEffect, useMemo, useReducer } from "react"
import { useTranslation } from "react-i18next"
import { Event, loadEvents } from "../api/Event"
import { DoOverlap, EmptyRange, Hour, IsEmpty, IsInside, MergeRanges, NewRange, Second, TimeRange } from "../config/time"
import { useCooldown } from "../hooks/cooldown"
import { useEventStream } from "./EventStream"

const retryCooldown = 5 * Second

type State = {
    events: Event[],
    range: TimeRange,
    fetching: boolean,
    error: Error | null,
}

type Action =
    | { type: "Set", events: Event[], range: TimeRange }
    | { type: "Add", events: Event[], range: TimeRange }
    | { type: "New", event: Event }
    | { type: "Fetching" }
    | { type: "Error", error: Error }
    ;

function newestFirst(a: Event, b: Event) {
    return b.timestamp.getTime() - a.timestamp.getTime()
}

function reducer(state: State, action: Action) {
    switch (action.type) {
        case "Set":
            return {
                ...state,
                events: [...action.events].sort(newestFirst),
                range: action.range,
                fetching: false,
                error: null,
            }
        case "New":
            if (state.range.end !== null) {
                console.warn("Current range is not open, ignoring new events.")
                return state
            }
            return {
                ...state,
                events: [...state.events, action.event].sort(newestFirst),
            }
        case "Add":
            if (!DoOverlap(action.range, state.range)) {
                // Non overlapping range, just ignore the new addition.
                console.warn("New events range does not overlap with the existing one.")
                return {
                    ...state,
                    fetching: false,
                    error: null,
                }
            }
            // Merge the new events into our existing event list.
            return {
                ...state,
                events: [...state.events, ...action.events].sort(newestFirst),
                range: MergeRanges(action.range, state.range),
                fetching: false,
                error: null,
            }
        case "Fetching":
            return {
                ...state,
                fetching: true,
                error: null,
            }
        case "Error":
            return {
                ...state,
                fetching: false,
                error: action.error,
            }
        default:
            throw Error('Unknown action: ' + action);
    }
}

export function useEvents(
    siteID: number,
    unit: string | undefined,
    requiredRange: TimeRange,
    filter: (event: Event) => boolean,
) {
    const [state, dispatch] = useReducer(reducer, {
        events: [],
        range: EmptyRange(),
        fetching: false,
        error: null,
    })
    const { t } = useTranslation()
    const { cooling, trigger } = useCooldown(retryCooldown)

    const onMessage = useCallback((e: Event) => dispatch({ type: "New", event: e }), [])

    const live = useMemo(() => state.range.end === null, [state.range.end])

    const { online } = useEventStream(siteID, unit, live, false, filter, onMessage)

    const fetch = useCallback((init: boolean, range: TimeRange) => {
        dispatch({ type: "Fetching" })
        loadEvents(siteID, unit, range, t)
            .then(resp =>
                dispatch({
                    type: init ? "Set" : "Add",
                    events: resp.events.filter(filter),
                    range: resp.continuation === "" ? range : NewRange(new Date(resp.continuation).getTime(), range.end),
                })
            ).catch(error => {
                trigger()
                dispatch({
                    type: "Error",
                    error: error,
                })
            })
    }, [siteID, unit, filter, trigger, t])

    const fetchInit = useCallback(() => {
        fetch(true, requiredRange)
    }, [fetch, requiredRange])

    const fetchNewer = useCallback(() => {
        if (online || state.range.end === null) {
            console.log("Not fetching newer since the event stream is online.")
            return
        }
        if (IsEmpty(state.range)) {
            fetchInit()
            return
        }
        const ts = state.range.end + 24 * Hour
        fetch(false, { start: state.range.end, end: ts < Date.now() ? ts : null })
    }, [online, fetch, fetchInit, state.range])

    const fetchOlder = useCallback(() => {
        if (IsEmpty(state.range)) {
            fetchInit()
            return
        }
        fetch(false, { start: state.range.start - 24 * Hour, end: state.range.start })
    }, [fetch, fetchInit, state.range])

    useEffect(() => {
        if (cooling) {
            // Still cooling down since the last error.
            return
        }
        if (state.fetching) {
            // Already fetching, noop
            return

        }
        if (IsEmpty(requiredRange)) {
            // No range required, noop.
            return
        }
        if (IsInside(requiredRange, state.range)) {
            // Already covered by the current range, noop.
            return
        }
        if (IsEmpty(state.range)) {
            // No range yet, fetch new.
            fetchInit()
            return
        }
        if (!DoOverlap(requiredRange, state.range)) {
            // Nonoverlapping range, reinitialize.
            fetchInit()
            return
        }
        if (state.range.start > requiredRange.start) {
            fetchOlder()
        } else {
            fetchNewer()
        }
    }, [requiredRange, state, fetch, fetchOlder, fetchNewer, fetchInit, cooling])

    return {
        events: state.events,
        fetching: state.fetching,
        error: state.error,
        range: state.range,
        online,
        fetchOlder,
        fetchNewer,
    }
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { request } from '../../config/headers'
import { endpointURL } from '../../config/urls'
import { CommonRole, ListAllCommonRolesResponse } from '../Authz'

export const commonRoleApi = createApi({
    reducerPath: 'commonRoleApi',
    baseQuery: fetchBaseQuery({ ...request, baseUrl: endpointURL("") }),
    tagTypes: ["CommonRoles"],
    endpoints: (builder) => ({
        getCommonRoles: builder.query<CommonRole[], void>({
            query: () => `common-roles`,
            transformResponse: (response: ListAllCommonRolesResponse) => response.data,
            providesTags: [{ type: "CommonRoles", id: "LIST" }]
        }),
    }),
})

export const { useGetCommonRolesQuery } = commonRoleApi

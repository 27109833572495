import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { Operation } from "../api/Authz"
import { Customer, Site, Unit } from "../api/Customer"
import { User } from "../api/User"
import { useUnitPermission } from "../auth/AuthorizerProvider"
import { UnitPage } from "../components/UnitPage"
import { Access } from "../features/access/Access"

export interface UnitAccessProps {
    actor: User
    customers: Customer[]
}

export function UnitAccess(props: UnitAccessProps) {
    const { actor, customers } = props

    const { shortName } = useParams();

    const unit = useMemo(
        () => customers.flatMap(c => c.Units).find(u => u.ShortName === shortName),
        [customers, shortName],
    )
    const site = useMemo(
        () => customers.flatMap(c => c.Sites).find(s => s.ID === unit?.SiteID),
        [customers, unit],
    )
    const allowed = useUnitPermission(Operation.UI_VIEW_ACCESS, unit)

    return (
        <UnitPage
            actor={actor}
            unit={unit}
            site={site}
            customers={customers}
            allowed={allowed}
            render={(unit: Unit, site: Site) => <Access unit={unit} site={site} />}
        />
    )
}

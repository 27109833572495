import { Link, Stack, Typography, useTheme } from "@mui/material"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { User } from "../../api/User"

export interface UserTooltipProps {
    user?: User
}

export const UserTooltip = (props: UserTooltipProps) => {
    const { user } = props

    const { t } = useTranslation()
    const theme = useTheme()

    const labelColor = theme.palette.grey[300]

    const renderField = (field: string, value: ReactNode) => (
        <Stack direction="row" spacing={0.5}>
            <Typography variant="body2" color={labelColor}>{t(field)}:</Typography>
            <Typography variant="body2">
                {value ?
                    value :
                    <i>{t("field.unknown")}</i>
                }
            </Typography>
        </Stack>
    )
    return (
        <Stack m={1} mb={1.5} alignItems="flex-start">
            {user &&
                <>
                    {renderField("field.first_name", user.Name)}
                    {renderField("field.surname", user.Surname)}
                    {renderField("field.username", user.Username)}
                    {renderField(
                        "field.email",
                        <Link href={"mailto:" + user?.Email}>{user.Email}</Link>
                    )}
                </>
            }
        </Stack >
    )
}

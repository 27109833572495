import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { cloneElement, memo } from "react";
import { useTranslation } from "react-i18next";
import { Event } from "../../api/Event";
import { ToLongLocalDateTime, ToLongLocalTime } from "../../config/time";
import { emphasize } from "../../theme/Theme";

export interface EventBoxProps {
    event: Event
    onFocusEvent: (event: Event) => void
}

export const EventBox = memo((props: EventBoxProps) => {
    const { event, onFocusEvent } = props

    const theme = useTheme()
    const { t } = useTranslation()

    const { message, color, icon } = event

    const tooltip = (message: string, event: Event) =>
        <Stack direction="column">
            <Typography fontSize="small"><b>{t("timestamp")}:</b> {ToLongLocalDateTime(event.timestamp)}</Typography>
            <Typography fontSize="small">{message}</Typography>
        </Stack>

    return (
        <Tooltip title={tooltip(message, event)} disableInteractive PopperProps={{ disablePortal: true }}>
            <Stack
                direction="row"
                spacing={0.5}
                width="100%"
                height="100%"
                alignItems="center"
                sx={[{
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                    borderLeftColor: color,
                    backgroundColor: theme.palette.background.paper,
                    cursor: "pointer",
                    userSelect: "none",
                }, {
                    "&:hover": {
                        backgroundImage: emphasize(0.1, theme),
                    }
                }]}
                p={0.5}
                onClick={() => onFocusEvent(event)}
            >
                {cloneElement(icon, {
                    fontSize: "small",
                    htmlColor: color
                })}
                <Typography fontSize="small" color={theme.palette.text.secondary} flexShrink={0} noWrap textOverflow={"ellipsis"}>
                    {ToLongLocalTime(event.timestamp)}:
                </Typography>
                <Typography fontSize="small" flexGrow={1} flexShrink={1} noWrap textOverflow={"ellipsis"}>
                    {message}
                </Typography>
            </Stack >
        </Tooltip>
    )
})

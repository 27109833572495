import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { Customer, Site, Unit } from "../api/Customer"
import { User } from "../api/User"
import { UnitPage } from "../components/UnitPage"
import { NewAlarm } from "../features/newalarm/Alarm"
import { useUnitPermission } from "../auth/AuthorizerProvider"
import { Operation } from "../api/Authz"

export interface NewUnitAlarmProps {
    actor: User
    customers: Customer[]
}

export function NewUnitAlarm(props: NewUnitAlarmProps) {
    const { actor, customers } = props

    const { shortName } = useParams();

    const unit = useMemo(
        () => customers.flatMap(c => c.Units).find(u => u.ShortName === shortName),
        [customers, shortName],
    )
    const site = useMemo(
        () => customers.flatMap(c => c.Sites).find(s => s.ID === unit?.SiteID),
        [customers, unit],
    )
    const allowed = useUnitPermission(Operation.UI_VIEW_ALARM, unit)

    return (
        <UnitPage
            actor={actor}
            unit={unit}
            site={site}
            customers={customers}
            allowed={allowed}
            render={(unit: Unit, site: Site) =>
                <NewAlarm site={site} unit={unit} />
            }
        />
    )
}

import { Download, ZoomOutMap } from "@mui/icons-material";
import { IconButton, ImageListItem, ImageListItemBar, Stack, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Operation } from "../api/Authz";
import { Unit } from "../api/Customer";
import { useCameraPermission } from "../auth/AuthorizerProvider";
import { retainConfig } from "../config/urls";
import { ImageWithLoading } from "./ImageWithLoading";
import { SnapshotPreset } from "./SnapshotPresetList";

export interface PresetWithSnapshotProps {
    unit: Unit
    preset: SnapshotPreset
    camID: number
    handleZoomButton: (snapshot: string) => void;
    handleExportButton: () => void;
}

export default function PresetWithSnapshot(props: PresetWithSnapshotProps) {
    const { unit, preset, camID, handleZoomButton, handleExportButton } = props

    const [hasError, setHasError] = useState<boolean>(false);

    const theme = useTheme()
    const { t } = useTranslation()
    const allowedExport = useCameraPermission(Operation.EXPORT_CAMERA_TIMELAPSE, unit, camID)

    const renderPresetImage = (preset: SnapshotPreset) => {
        if (hasError) {
            return (
                <ImageWithLoading
                    src={preset.lastSnapshot}
                    alt={preset.preset}
                    onError={() => setHasError(true)}
                />
            )
        }

        return (
            <Stack
                component={Link}
                to={retainConfig({
                    pathname: `cameras/${camID}/presets/${preset.preset}`,
                })}
            >
                <ImageWithLoading
                    src={preset.lastSnapshot}
                    alt={preset.preset}
                    onError={() => setHasError(true)}
                />
            </Stack>
        )
    }

    return (

        <ImageListItem
            key={preset.preset}
            sx={{
                position: "relative",
                backgroundColor: theme.palette.background.default,
                borderStyle: "solid",
                borderColor: theme.palette.panel.border,
                borderWidth: 1,
                boxShadow: 3,
                overflow: "hidden"
            }}>
            {renderPresetImage(preset)}
            <ImageListItemBar
                title={`${preset.preset}`}
                actionIcon={
                    <Stack direction="row">
                        <Tooltip title={t(allowedExport ? "dialog.snapshot.export_tooltip" : "dialog.snapshot.export_tooltip_forbidden")} disableInteractive>
                            <span>
                                <IconButton color='primary'
                                    onClick={handleExportButton}
                                    disabled={hasError || !allowedExport}
                                >
                                    <Download />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={t("dialog.snapshot.zoom_last_snapshot_tooltip")} disableInteractive>
                            <span>
                                <IconButton color='primary'
                                    onClick={() => handleZoomButton(preset.lastSnapshot)}
                                    disabled={hasError}
                                >
                                    <ZoomOutMap />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                }
            />
        </ImageListItem>
    )
}

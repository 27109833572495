import { Box, Button, Tooltip, useTheme } from "@mui/material";
import { ReactElement } from "react";

export interface TimeShiftButtonProps {
    disabled?: boolean
    icon: ReactElement
    tooltip: string
    disabledHint?: string
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
    onClick: () => void
}

export function TimeShiftButton(props: TimeShiftButtonProps) {
    const { disabled, icon, tooltip, disabledHint, color, onClick } = props

    const theme = useTheme()

    const fullTooltip = disabled && disabledHint ? `${tooltip} ${disabledHint}` : tooltip

    return (
        <Tooltip title={fullTooltip} disableInteractive>
            <Box>
                <Button
                    variant="outlined"
                    sx={{ padding: "2px", minWidth: "0px", backgroundColor: theme.palette.panel.background }}
                    disabled={disabled}
                    onClick={onClick}
                    fullWidth
                    color={color}
                >
                    {icon}
                </Button>
            </Box>
        </Tooltip>
    )
}

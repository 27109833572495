import { Site, Unit } from "./Customer"

export interface ListCustomerPermissionsResponse {
    data?: Permission[]
}

export interface ListOwnPermissionsResponse {
    data?: ActorPermission[]
    included?: ListPermissionsInclusion
}

export interface ListPermissionsInclusion {
    baseRole?: Object
    commonRole?: Object
}

export interface CreateCustomerPermissionRequest {
    customer_id: string
    sites: SiteScope[]
    expiration?: Date
}

export interface UpdateCustomerPermissionRequest {
    id: string
    sites: SiteScope[]
    expiration?: Date
}

export interface Permission {
    id: string
    scope: Scope
    actors?: Actor[]
    roles?: Role[]
    expiration?: Date
}

export interface ActorPermission {
    id: string
    scope: Scope
    roles?: Role[]
}

export interface Scope {
    customers?: CustomerScope[]
}

export interface CustomerScope {
    id?: string
    all?: boolean
    sites?: SiteScope[]
}

export interface SiteScope {
    id?: string
    all?: boolean
    units?: UnitScope[]
}

export interface UnitScope {
    shortName?: string
    all?: boolean
    cameras?: CameraScope[]
    gates?: GateScope[]
    alarms?: AlarmScope[]
}

export interface CameraScope {
    id?: string
    all?: boolean
}

export interface GateScope {
    id?: string
    all?: boolean
}

export interface AlarmScope {
    id?: string
    all?: boolean
    partitions?: PartitionScope[]
}

export interface PartitionScope {
    id?: string
    all?: boolean
}

export enum ActorType {
    USER = "USER",
    GROUP = "GROUP",
}

export interface Actor {
    type: ActorType
    id: string
}

export enum RoleType {
    BASE_ROLE = "BASE_ROLE",
    COMMON_ROLE = "COMMON_ROLE",
}

export interface Role {
    type: RoleType
    id: string
}

export enum SubjectType {
    SUBJECT_TYPE_UNSPECIFIED = "SUBJECT_TYPE_UNSPECIFIED",
    GLOBAL = "GLOBAL",
    CUSTOMER = "CUSTOMER",
    SITE = "SITE",
    UNIT = "UNIT",
    CAMERA = "CAMERA",
    PARTITION = "PARTITION",
    GATE = "GATE",
}

export interface Subject {
    type: SubjectType
    customer?: CustomerSubject
    site?: SiteSubject
    unit?: UnitSubject
    camera?: CameraSubject
    partition?: PartitionSubject
    gate?: GateSubject
}

export interface CustomerSubject {
    customerId: string
}

export interface SiteSubject {
    customerId: string
    siteId: string
}

export interface UnitSubject {
    customerId: string
    siteId: string
    unitName: string
}

export interface CameraSubject {
    customerId: string
    siteId: string
    unitName: string
    cameraId: string
}

export interface PartitionSubject {
    customerId: string
    siteId: string
    unitName: string
    alarmId: string
    partitionId: string
}

export interface GateSubject {
    customerId: string
    siteId: string
    unitName: string
    gateId: string
}

export interface ListAllBaseRolesResponse {
    data: BaseRole[]
}

export enum Operation {
    OPERATION_UNSPECIFIED = "OPERATION_UNSPECIFIED",

    // Global scope.
    GET_SELF = "GET_SELF",
    LIST_OWN_CUSTOMERS = "LIST_OWN_CUSTOMERS",
    LIST_OWN_PERMISSIONS = "LIST_OWN_PERMISSIONS",
    LIST_OFFERINGS = "LIST_OFFERINGS",
    LIST_ALL_USERS = "LIST_ALL_USERS",
    LIST_ALL_GROUPS = "LIST_ALL_GROUPS",
    LIST_ALL_SITES = "LIST_ALL_SITES",
    LIST_ALL_UNITS = "LIST_ALL_UNITS",
    LIST_ACTOR_PERMISSIONS = "LIST_ACTOR_PERMISSIONS",
    LIST_WORK_QUEUES = "LIST_WORK_QUEUES",
    LIST_WORK_QUEUE_TASKS = "LIST_WORK_QUEUE_TASKS",
    LEASE_WORK_QUEUE_TASK = "LEASE_WORK_QUEUE_TASK",
    VERIFY_USER_HOOK = "VERIFY_USER_HOOK",
    UPDATE_WORK_QUEUE_TASK = "UPDATE_WORK_QUEUE_TASK",
    LIST_ALL_USER_PERMISSIONS = "LIST_ALL_USER_PERMISSIONS",
    LIST_ALL_BASE_ROLES = "LIST_ALL_BASE_ROLES",
    LIST_ALL_COMMON_ROLES = "LIST_ALL_COMMON_ROLES",

    // Customer scope.
    GET_CUSTOMER = "GET_CUSTOMER",
    LIST_CUSTOMER_USERS = "LIST_CUSTOMER_USERS",
    CREATE_CUSTOMER_USER = "CREATE_CUSTOMER_USER",
    UPDATE_CUSTOMER_USER = "UPDATE_CUSTOMER_USER",
    DELETE_CUSTOMER_USER = "DELETE_CUSTOMER_USER",
    RESET_CUSTOMER_USER_PASSWORD = "RESET_CUSTOMER_USER_PASSWORD",
    LIST_CUSTOMER_IDENTIFIERS = "LIST_CUSTOMER_IDENTIFIERS",
    CREATE_CUSTOMER_IDENTIFIER = "CREATE_CUSTOMER_IDENTIFIER",
    UPDATE_CUSTOMER_IDENTIFIER = "UPDATE_CUSTOMER_IDENTIFIER",
    DELETE_CUSTOMER_IDENTIFIER = "DELETE_CUSTOMER_IDENTIFIER",
    LIST_CUSTOMER_ROLES = "LIST_CUSTOMER_ROLES",
    UPSERT_CUSTOMER_ROLE = "UPSERT_CUSTOMER_ROLE",
    DELETE_CUSTOMER_ROLE = "DELETE_CUSTOMER_ROLE",
    LIST_CUSTOMER_PERMISSIONS = "LIST_CUSTOMER_PERMISSIONS",
    CREATE_CUSTOMER_PERMISSION = "CREATE_CUSTOMER_PERMISSION",
    DELETE_CUSTOMER_PERMISSION = "DELETE_CUSTOMER_PERMISSION",
    UPDATE_CUSTOMER_PERMISSION = "UPDATE_CUSTOMER_PERMISSION",
    ADD_CUSTOMER_PERMISSION_ACTOR = "ADD_CUSTOMER_PERMISSION_ACTOR",
    REMOVE_CUSTOMER_PERMISSION_ACTOR = "REMOVE_CUSTOMER_PERMISSION_ACTOR",
    ADD_CUSTOMER_PERMISSION_ROLE = "ADD_CUSTOMER_PERMISSION_ROLE",
    REMOVE_CUSTOMER_PERMISSION_ROLE = "REMOVE_CUSTOMER_PERMISSION_ROLE",
    LIST_CUSTOMER_GROUP_USERS = "LIST_CUSTOMER_GROUP_USERS",
    LIST_CUSTOMER_GROUPS = "LIST_CUSTOMER_GROUPS",
    CREATE_CUSTOMER_GROUP = "CREATE_CUSTOMER_GROUP",
    UPDATE_CUSTOMER_GROUP = "UPDATE_CUSTOMER_GROUP",
    DELETE_CUSTOMER_GROUP = "DELETE_CUSTOMER_GROUP",
    ADD_CUSTOMER_GROUP_USER = "ADD_CUSTOMER_GROUP_USER",
    REMOVE_CUSTOMER_GROUP_USER = "REMOVE_CUSTOMER_GROUP_USER",

    // Site scope.
    GET_SITE = "GET_SITE",
    LIST_SITE_IDENTIFIERS = "LIST_SITE_IDENTIFIERS",
    MANAGE_SITE_ACCESS = "MANAGE_SITE_ACCESS",
    LIST_SITE_ROLES = "LIST_SITE_ROLES",
    UPSERT_SITE_ROLE = "UPSERT_SITE_ROLE",
    DELETE_SITE_ROLE = "DELETE_SITE_ROLE",

    // Camera scope.
    GET_CAMERA = "GET_CAMERA",
    STREAM_CAMERA_LIVE = "STREAM_CAMERA_LIVE",
    STREAM_CAMERA_ARCHIVE = "STREAM_CAMERA_ARCHIVE",
    EXPORT_CAMERA_ARCHIVE = "EXPORT_CAMERA_ARCHIVE",
    MOVE_CAMERA = "MOVE_CAMERA",
    LIST_CAMERA_PRESETS = "LIST_CAMERA_PRESETS",
    CREATE_CAMERA_PRESET = "CREATE_CAMERA_PRESET",
    DELETE_CAMERA_PRESET = "DELETE_CAMERA_PRESET",
    UPDATE_CAMERA_PRESET = "UPDATE_CAMERA_PRESET",
    LIST_CAMERA_COMMANDS = "LIST_CAMERA_COMMANDS",
    EXECUTE_CAMERA_COMMAND = "EXECUTE_CAMERA_COMMAND",
    LIST_CAMERA_SNAPSHOTS = "LIST_CAMERA_SNAPSHOTS",
    VIEW_CAMERA_LATEST_SNAPSHOT = "VIEW_CAMERA_LATEST_SNAPSHOT",
    VIEW_CAMERA_SNAPSHOT = "VIEW_CAMERA_SNAPSHOT",
    EXPORT_CAMERA_TIMELAPSE = "EXPORT_CAMERA_TIMELAPSE",
    UPDATE_CAMERA_HOME = "UPDATE_CAMERA_HOME",
    REBOOT_CAMERA = "REBOOT_CAMERA",

    // Partition scope.
    GET_PARTITION = "GET_PARTITION",
    GET_SYSTEM_PARTITION = "GET_SYSTEM_PARTITION",
    ARM_PARTITION = "ARM_PARTITION",
    ARM_SYSTEM_PARTITION = "ARM_SYSTEM_PARTITION",
    DISARM_PARTITION = "DISARM_PARTITION",
    DISARM_SYSTEM_PARTITION = "DISARM_SYSTEM_PARTITION",
    GET_ZONE = "GET_ZONE",
    GET_SYSTEM_ZONE = "GET_SYSTEM_ZONE",
    BYPASS_ZONE = "BYPASS_ZONE",
    BYPASS_SYSTEM_ZONE = "BYPASS_SYSTEM_ZONE",

    // Gate scope.
    GET_GATE = "GET_GATE",
    OPEN_GATE = "OPEN_GATE",
    CLOSE_GATE = "CLOSE_GATE",

    // Unit scope.
    GET_UNIT = "GET_UNIT",
    GET_THING = "GET_THING",
    GET_POWER_SAVE = "GET_POWER_SAVE",
    DISABLE_POWER_SAVE = "DISABLE_POWER_SAVE",
    VIEW_TRACKING = "VIEW_TRACKING",
    MANAGE_TRACKING = "MANAGE_TRACKING",
    MANAGE_OPENHAB = "MANAGE_OPENHAB",
    SWITCH_LIGHT = "SWITCH_LIGHT",
    SWITCH_AUTO_ARM = "SWITCH_AUTO_ARM",

    // UI operations.
    // These do not correspond to a backend check and are only used to facilitate
    // decisions about enabling/disabling certain UI views/pages/panes.
    UI_VIEW_ACCESS = "UI_VIEW_ACCESS",
    UI_VIEW_ALARM = "UI_VIEW_ALARM",
    UI_VIEW_EVENTS = "UI_VIEW_EVENTS",
    UI_VIEW_STATISTICS = "UI_VIEW_STATISTICS",
    UI_VIEW_TIMELAPSE = "UI_VIEW_TIMELAPSE",
    UI_VIEW_TRACKING = "UI_VIEW_TRACKING",
    UI_VIEW_VIDEO = "UI_VIEW_VIDEO",
    UI_VIEW_WEATHER = "UI_VIEW_WEATHER",
    UI_VIEW_CUSTOMER_PERMISSIONS = "UI_VIEW_CUSTOMER_PERMISSIONS",
    UI_VIEW_CAMERA_SNAPSHOTS = "UI_VIEW_CAMERA_SNAPSHOTS",
}

const operationSubject = new Map<Operation, SubjectType>(
    [
        [Operation.GET_SELF, SubjectType.GLOBAL],
        [Operation.LIST_OWN_CUSTOMERS, SubjectType.GLOBAL],
        [Operation.LIST_OWN_PERMISSIONS, SubjectType.GLOBAL],
        [Operation.LIST_OFFERINGS, SubjectType.GLOBAL],
        [Operation.LIST_ALL_USERS, SubjectType.GLOBAL],
        [Operation.LIST_ALL_GROUPS, SubjectType.GLOBAL],
        [Operation.LIST_ALL_SITES, SubjectType.GLOBAL],
        [Operation.LIST_ALL_UNITS, SubjectType.GLOBAL],
        [Operation.LIST_ACTOR_PERMISSIONS, SubjectType.GLOBAL],
        [Operation.LIST_WORK_QUEUES, SubjectType.GLOBAL],
        [Operation.LIST_WORK_QUEUE_TASKS, SubjectType.GLOBAL],
        [Operation.LEASE_WORK_QUEUE_TASK, SubjectType.GLOBAL],
        [Operation.VERIFY_USER_HOOK, SubjectType.GLOBAL],
        [Operation.UPDATE_WORK_QUEUE_TASK, SubjectType.GLOBAL],
        [Operation.LIST_ALL_USER_PERMISSIONS, SubjectType.GLOBAL],
        [Operation.LIST_ALL_BASE_ROLES, SubjectType.GLOBAL],
        [Operation.LIST_ALL_COMMON_ROLES, SubjectType.GLOBAL],
        [Operation.GET_CUSTOMER, SubjectType.CUSTOMER],
        [Operation.LIST_CUSTOMER_USERS, SubjectType.CUSTOMER],
        [Operation.CREATE_CUSTOMER_USER, SubjectType.CUSTOMER],
        [Operation.UPDATE_CUSTOMER_USER, SubjectType.CUSTOMER],
        [Operation.DELETE_CUSTOMER_USER, SubjectType.CUSTOMER],
        [Operation.RESET_CUSTOMER_USER_PASSWORD, SubjectType.CUSTOMER],
        [Operation.LIST_CUSTOMER_IDENTIFIERS, SubjectType.CUSTOMER],
        [Operation.CREATE_CUSTOMER_IDENTIFIER, SubjectType.CUSTOMER],
        [Operation.UPDATE_CUSTOMER_IDENTIFIER, SubjectType.CUSTOMER],
        [Operation.DELETE_CUSTOMER_IDENTIFIER, SubjectType.CUSTOMER],
        [Operation.LIST_CUSTOMER_ROLES, SubjectType.CUSTOMER],
        [Operation.UPSERT_CUSTOMER_ROLE, SubjectType.CUSTOMER],
        [Operation.DELETE_CUSTOMER_ROLE, SubjectType.CUSTOMER],
        [Operation.LIST_CUSTOMER_PERMISSIONS, SubjectType.CUSTOMER],
        [Operation.CREATE_CUSTOMER_PERMISSION, SubjectType.CUSTOMER],
        [Operation.DELETE_CUSTOMER_PERMISSION, SubjectType.CUSTOMER],
        [Operation.UPDATE_CUSTOMER_PERMISSION, SubjectType.CUSTOMER],
        [Operation.ADD_CUSTOMER_PERMISSION_ACTOR, SubjectType.CUSTOMER],
        [Operation.REMOVE_CUSTOMER_PERMISSION_ACTOR, SubjectType.CUSTOMER],
        [Operation.ADD_CUSTOMER_PERMISSION_ROLE, SubjectType.CUSTOMER],
        [Operation.REMOVE_CUSTOMER_PERMISSION_ROLE, SubjectType.CUSTOMER],
        [Operation.LIST_CUSTOMER_GROUP_USERS, SubjectType.CUSTOMER],
        [Operation.LIST_CUSTOMER_GROUPS, SubjectType.CUSTOMER],
        [Operation.CREATE_CUSTOMER_GROUP, SubjectType.CUSTOMER],
        [Operation.UPDATE_CUSTOMER_GROUP, SubjectType.CUSTOMER],
        [Operation.DELETE_CUSTOMER_GROUP, SubjectType.CUSTOMER],
        [Operation.ADD_CUSTOMER_GROUP_USER, SubjectType.CUSTOMER],
        [Operation.REMOVE_CUSTOMER_GROUP_USER, SubjectType.CUSTOMER],
        [Operation.GET_SITE, SubjectType.SITE],
        [Operation.LIST_SITE_IDENTIFIERS, SubjectType.SITE],
        [Operation.MANAGE_SITE_ACCESS, SubjectType.SITE],
        [Operation.LIST_SITE_ROLES, SubjectType.SITE],
        [Operation.UPSERT_SITE_ROLE, SubjectType.SITE],
        [Operation.DELETE_SITE_ROLE, SubjectType.SITE],
        [Operation.GET_CAMERA, SubjectType.CAMERA],
        [Operation.STREAM_CAMERA_LIVE, SubjectType.CAMERA],
        [Operation.STREAM_CAMERA_ARCHIVE, SubjectType.CAMERA],
        [Operation.EXPORT_CAMERA_ARCHIVE, SubjectType.CAMERA],
        [Operation.MOVE_CAMERA, SubjectType.CAMERA],
        [Operation.LIST_CAMERA_PRESETS, SubjectType.CAMERA],
        [Operation.CREATE_CAMERA_PRESET, SubjectType.CAMERA],
        [Operation.DELETE_CAMERA_PRESET, SubjectType.CAMERA],
        [Operation.UPDATE_CAMERA_PRESET, SubjectType.CAMERA],
        [Operation.LIST_CAMERA_COMMANDS, SubjectType.CAMERA],
        [Operation.EXECUTE_CAMERA_COMMAND, SubjectType.CAMERA],
        [Operation.LIST_CAMERA_SNAPSHOTS, SubjectType.CAMERA],
        [Operation.VIEW_CAMERA_LATEST_SNAPSHOT, SubjectType.CAMERA],
        [Operation.VIEW_CAMERA_SNAPSHOT, SubjectType.CAMERA],
        [Operation.EXPORT_CAMERA_TIMELAPSE, SubjectType.CAMERA],
        [Operation.UPDATE_CAMERA_HOME, SubjectType.CAMERA],
        [Operation.REBOOT_CAMERA, SubjectType.CAMERA],
        [Operation.GET_PARTITION, SubjectType.PARTITION],
        [Operation.GET_SYSTEM_PARTITION, SubjectType.PARTITION],
        [Operation.ARM_PARTITION, SubjectType.PARTITION],
        [Operation.ARM_SYSTEM_PARTITION, SubjectType.PARTITION],
        [Operation.DISARM_PARTITION, SubjectType.PARTITION],
        [Operation.DISARM_SYSTEM_PARTITION, SubjectType.PARTITION],
        [Operation.GET_ZONE, SubjectType.PARTITION],
        [Operation.GET_SYSTEM_ZONE, SubjectType.PARTITION],
        [Operation.BYPASS_ZONE, SubjectType.PARTITION],
        [Operation.BYPASS_SYSTEM_ZONE, SubjectType.PARTITION],
        [Operation.GET_GATE, SubjectType.GATE],
        [Operation.OPEN_GATE, SubjectType.GATE],
        [Operation.CLOSE_GATE, SubjectType.GATE],
        [Operation.GET_UNIT, SubjectType.UNIT],
        [Operation.GET_THING, SubjectType.UNIT],
        [Operation.GET_POWER_SAVE, SubjectType.UNIT],
        [Operation.DISABLE_POWER_SAVE, SubjectType.UNIT],
        [Operation.VIEW_TRACKING, SubjectType.UNIT],
        [Operation.MANAGE_TRACKING, SubjectType.UNIT],
        [Operation.MANAGE_OPENHAB, SubjectType.UNIT],
        [Operation.SWITCH_LIGHT, SubjectType.UNIT],
        [Operation.SWITCH_AUTO_ARM, SubjectType.UNIT],
        [Operation.UI_VIEW_ACCESS, SubjectType.UNIT],
        [Operation.UI_VIEW_ALARM, SubjectType.UNIT],
        [Operation.UI_VIEW_EVENTS, SubjectType.UNIT],
        [Operation.UI_VIEW_STATISTICS, SubjectType.UNIT],
        [Operation.UI_VIEW_TIMELAPSE, SubjectType.UNIT],
        [Operation.UI_VIEW_TRACKING, SubjectType.UNIT],
        [Operation.UI_VIEW_VIDEO, SubjectType.UNIT],
        [Operation.UI_VIEW_WEATHER, SubjectType.UNIT],
        [Operation.UI_VIEW_CUSTOMER_PERMISSIONS, SubjectType.CUSTOMER],
        [Operation.UI_VIEW_CAMERA_SNAPSHOTS, SubjectType.CAMERA],
    ]
)

export const OperationSubject = (op: Operation) => operationSubject.get(op) || SubjectType.SUBJECT_TYPE_UNSPECIFIED

export interface BaseRole {
    id: string
    name: string
    level: SubjectType
    operations?: Operation[]
}

export interface ListAllCommonRolesResponse {
    data: CommonRole[]
}

export interface CommonRole {
    id: string
    name: string
    level: SubjectType

    roles: Role[]
}

export const isAllSites = (scope: Scope, customerID: number) => {
    const cs = scope.customers?.find(c => c.id === customerID + "")
    if (!cs || cs?.all) {
        return false
    }
    return isAllScope(cs.sites)
}

interface anyScope {
    all?: boolean
}

export const isAllScope = (scope?: anyScope[]) => {
    return scope && scope.length === 1 && !!scope[0].all
}

export const toggleAllSites = (scope: Scope, customerID: number) => ({
    customers: [{
        id: customerID + "",
        all: false,
        sites: isAllSites(scope, customerID) ? [] : [{ all: true }],
    }],
})

export const toggleSite = (scope: Scope, customerID: number, siteID: number) =>
    hasSite(scope, customerID, siteID) ?
        withoutSite(scope, customerID, siteID) :
        withSite(scope, customerID, siteID)

export const hasSite = (scope: Scope, customerID: number, siteID: number) =>
    !!scope.customers?.find(c => c.id === customerID + "")?.sites?.find(s => s.id === siteID + "")


export const withSite = (scope: Scope, customerID: number, siteID: number) => ({
    customers: scope.customers?.map(
        c => c.id === customerID + "" ?
            {
                id: c.id,
                sites: [...c.sites || [], {
                    id: siteID + "",
                    units: [{
                        all: true,
                        cameras: [],
                    }],
                }],
            } :
            c,
    )
})

export const withoutSite = (scope: Scope, customerID: number, siteID: number) => ({
    customers: scope.customers?.map(
        c => c.id === customerID + "" ?
            {
                id: c.id,
                sites: c.sites?.filter(s => s.id !== siteID + ""),
            } :
            c,
    )
})

export const toggleAllUnits = (scope: Scope, customerID: number, siteID: number) => ({
    customers: scope.customers?.map(
        c => c.id === customerID + "" ?
            {
                id: c.id,
                sites: c.sites?.map(s => s.id === siteID + "" ?
                    {
                        id: s.id,
                        units: isAllScope(s.units) ? [] : [{ all: true }],
                    } :
                    s
                ),
            } :
            c,
    )
})

export const toggleUnit = (scope: Scope, customerID: number, siteID: number, unit: string) =>
    hasUnit(scope, customerID, siteID, unit) ?
        withoutUnit(scope, customerID, siteID, unit) :
        withUnit(scope, customerID, siteID, unit)

export const hasUnit = (scope: Scope, customerID: number, siteID: number, unit: string) =>
    !!scope.customers
        ?.find(c => c.id === customerID + "")?.sites
        ?.find(s => s.id === siteID + "")?.units
        ?.find(u => u.shortName === unit)


export const withUnit = (scope: Scope, customerID: number, siteID: number, unit: string) => ({
    customers: scope.customers?.map(
        c => c.id === customerID + "" ?
            {
                id: c.id,
                sites: c.sites?.map(s => s.id === siteID + "" ?
                    {
                        id: s.id,
                        units: [...s.units || [], {
                            alarms: [{ all: true }],
                            cameras: [{ all: true }],
                            gates: [{ all: true }],
                            shortName: unit,
                        }],
                    } :
                    s
                ),
            } :
            c,
    )
})

export const withoutUnit = (scope: Scope, customerID: number, siteID: number, unit: string) => ({
    customers: scope.customers?.map(
        c => c.id === customerID + "" ?
            {
                id: c.id,
                sites: c.sites?.map(s => s.id === siteID + "" ?
                    {
                        id: s.id,
                        units: s.units?.filter(u => u.shortName !== unit),
                    } :
                    s
                ),
            } :
            c,
    )
})

export const toggleAllCameras = (scope: Scope, customerID: number, siteID: number, unit: string) => ({
    customers: scope.customers?.map(
        c => c.id === customerID + "" ?
            {
                id: c.id,
                sites: c.sites?.map(s => s.id === siteID + "" ?
                    {
                        id: s.id,
                        units: s.units?.map(u => u.shortName === unit ?
                            {
                                shortName: u.shortName,
                                cameras: isAllScope(u.cameras) ? [] : [{ all: true }],
                                gates: u.gates,
                                alarms: u.alarms,
                            } :
                            u
                        ),
                    } :
                    s
                ),
            } :
            c,
    )
})

export const toggleCamera = (scope: Scope, customerID: number, siteID: number, unit: string, cameraID: number) =>
    hasCamera(scope, customerID, siteID, unit, cameraID) ?
        withoutCamera(scope, customerID, siteID, unit, cameraID) :
        withCamera(scope, customerID, siteID, unit, cameraID)

export const hasCamera = (scope: Scope, customerID: number, siteID: number, unit: string, cameraID: number) =>
    !!scope.customers
        ?.find(c => c.id === customerID + "")?.sites
        ?.find(s => s.id === siteID + "")?.units
        ?.find(u => u.shortName === unit)?.cameras
        ?.find(cam => cam.id === cameraID + "")


export const withCamera = (scope: Scope, customerID: number, siteID: number, unit: string, cameraID: number) => ({
    customers: scope.customers?.map(
        c => c.id === customerID + "" ?
            {
                id: c.id,
                sites: c.sites?.map(s => s.id === siteID + "" ?
                    {
                        id: s.id,
                        units: s.units?.map(u => u.shortName === unit ?
                            {
                                shortName: u.shortName,
                                cameras: [...u.cameras || [], {
                                    id: cameraID + "",
                                }],
                                gates: u.gates,
                                alarms: u.alarms,
                            } :
                            u,
                        ),
                    } :
                    s,
                ),
            } :
            c,
    )
})

export const withoutCamera = (scope: Scope, customerID: number, siteID: number, unit: string, cameraID: number) => ({
    customers: scope.customers?.map(
        c => c.id === customerID + "" ?
            {
                id: c.id,
                sites: c.sites?.map(s => s.id === siteID + "" ?
                    {
                        id: s.id,
                        units: s.units?.map(u => u.shortName === unit ?
                            {
                                shortName: u.shortName,
                                cameras: u.cameras?.filter(cam => cam.id !== cameraID + ""),
                                gates: u.gates,
                                alarms: u.alarms,
                            } :
                            u,
                        ),
                    } :
                    s,
                ),
            } :
            c,
    )
})

export const invalidSubject = () => ({
    type: SubjectType.SUBJECT_TYPE_UNSPECIFIED,
})

export const asCustomerSubject = (customerID?: number): Subject =>
    customerID === undefined ?
        invalidSubject() :
        {
            type: SubjectType.CUSTOMER,
            customer: {
                customerId: "" + customerID,
            }
        }

export const asSiteSubject = (site?: Site): Subject =>
    site === undefined ?
        invalidSubject() :
        {
            type: SubjectType.SITE,
            site: {
                customerId: "" + site.CustomerID,
                siteId: "" + site.ID,
            }
        }

export const asUnitSubject = (unit?: Unit): Subject =>
    unit === undefined ?
        invalidSubject() :
        {
            type: SubjectType.UNIT,
            unit: {
                customerId: "" + unit.CustomerID,
                siteId: "" + unit.SiteID,
                unitName: unit.ShortName,
            }
        }

export const asCameraSubject = (unit?: Unit, cameraID?: number): Subject =>
    (unit === undefined || cameraID === undefined) ?
        invalidSubject() :
        {
            type: SubjectType.CAMERA,
            camera: {
                customerId: "" + unit.CustomerID,
                siteId: "" + unit.SiteID,
                unitName: unit.ShortName,
                cameraId: "" + cameraID
            }
        }

export const asGateSubject = (unit?: Unit, gateID?: number): Subject =>
    (unit === undefined || gateID === undefined) ?
        invalidSubject() :
        {
            type: SubjectType.GATE,
            gate: {
                customerId: "" + unit.CustomerID,
                siteId: "" + unit.SiteID,
                unitName: unit.ShortName,
                gateId: "" + gateID
            }
        }

export const asDefaultPerimeterPartition = (unit?: Unit) => asPartitionSubject(unit, 1, 2)
export const asDefaultSystemPartition = (unit?: Unit) => asPartitionSubject(unit, 1, 1)

export const asPartitionSubject = (unit?: Unit, alarmID?: number, partitionID?: number): Subject =>
    (unit === undefined || alarmID === undefined || partitionID === undefined) ?
        invalidSubject() :
        {
            type: SubjectType.PARTITION,
            partition: {
                customerId: "" + unit.CustomerID,
                siteId: "" + unit.SiteID,
                unitName: unit.ShortName,
                alarmId: "" + alarmID,
                partitionId: "" + partitionID
            }
        }

import { Box, Stack, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Datas } from "react-csv-downloader/dist/esm/lib/csv";
import { useTranslation } from "react-i18next";
import { Site, Unit } from "../../api/Customer";
import { Event, EventType, EventTypeID, GateClosed, GateOpen, GeneratorFailure, GeneratorHighTemperature, GeneratorLowFuel, GeneratorManualRestart, GeneratorManualStart, GeneratorModeAutomatic, GeneratorModeManual, GeneratorNoFuel, GeneratorStarted, GeneratorStopped, LightOff, LightOn, OnvifCellMotionDetector, OnvifFaceDetector, OnvifFieldDetectorInside, OnvifFieldDetectorOutside, OnvifHumanDetector, OnvifLineDetector, OnvifLoiteringDetector, OnvifMotionAlarm, OnvifMotionDetector, OnvifObjectDetectorLeft, OnvifObjectDetectorRemoved, OnvifTamperDetector, PartitionAlarm, PartitionArmed, PartitionDisarmed, PowerSavingLevel1Off, PowerSavingLevel1On, SystemExternalPowerOff, SystemExternalPowerOn, SystemPowercut, ZoneAlarm, ZoneDisconnected, ZoneLowBattery, ZoneViolation } from "../../api/Event";
import { Playback } from "../../api/Video";
import { TimeRange, ToLongLocalDateTime } from "../../config/time";
import { useEventFilter } from "../../hooks/eventfilter";
import { AllIDs, Node } from "../../hooks/treeset";
import { Leaf, MatchEvent, NewFilter } from "../../services/EventFilter";
import { useEvents } from "../../services/Events";
import { EventFilterDialog } from "./EventFilterDialog";
import { EventListRows } from "./EventListRows";
import { EventListTitle } from "./EventListTitle";

export const filterTree: Node<EventType> = {
    id: "dialog.event_filter.all_events",
    children: [
        {
            id: "dialog.event_filter.alarm_events",
            children: [
                {
                    id: "dialog.event_filter.partition_events",
                    children: [Leaf(PartitionAlarm), Leaf(PartitionArmed), Leaf(PartitionDisarmed)],
                },
                {
                    id: "dialog.event_filter.zone_events",
                    children: [Leaf(ZoneAlarm), Leaf(ZoneViolation), Leaf(ZoneLowBattery), Leaf(ZoneDisconnected)],
                },
            ],
        },
        {
            id: "dialog.event_filter.camera_events",
            children: [
                Leaf(OnvifMotionAlarm),
                Leaf(OnvifMotionDetector),
                Leaf(OnvifCellMotionDetector),
                Leaf(OnvifFieldDetectorInside),
                Leaf(OnvifFieldDetectorOutside),
                Leaf(OnvifLineDetector),
                Leaf(OnvifHumanDetector),
                Leaf(OnvifFaceDetector),
                Leaf(OnvifLoiteringDetector),
                Leaf(OnvifObjectDetectorLeft),
                Leaf(OnvifObjectDetectorRemoved),
                Leaf(OnvifTamperDetector),
            ],
        },
        {
            id: "dialog.event_filter.system_events",
            children: [
                Leaf(SystemExternalPowerOn),
                Leaf(SystemExternalPowerOff),
                Leaf(SystemPowercut),
                Leaf(PowerSavingLevel1On),
                Leaf(PowerSavingLevel1Off),
                Leaf(LightOn),
                Leaf(LightOff),
            ],
        },
        {
            id: "dialog.event_filter.generator_events",
            children: [
                Leaf(GeneratorStarted),
                Leaf(GeneratorStopped),
                Leaf(GeneratorFailure),
                Leaf(GeneratorLowFuel),
                Leaf(GeneratorNoFuel),
                Leaf(GeneratorHighTemperature),
                Leaf(GeneratorManualRestart),
                Leaf(GeneratorManualStart),
                Leaf(GeneratorModeAutomatic),
                Leaf(GeneratorModeManual),
            ],
        },
        {
            id: "dialog.event_filter.access_events",
            children: [
                Leaf(GateOpen),
                Leaf(GateClosed),
            ],
        },
    ],
}

const defaultFilter = NewFilter(false, AllIDs(filterTree), [], [])

const allEventIDs = AllIDs(filterTree)

const eventFilter = (event: Event) => {
    const cls = event.labels.get("class")

    if (!cls || event.value === undefined) {
        return false
    }
    return allEventIDs.has(EventTypeID(cls, event.item, event.value))
}

const eventFilterKey = (site: Site, units: Unit[]) => {
    if (units.length > 1) {
        return `site-${site.ID}/event-filter`
    }
    return `unit-${units[0].ID}/event-filter`
}

export type EventListProps = {
    site: Site
    units: Unit[]
    isPortrait: boolean
    requiredRange: TimeRange,
    playback: Playback,
    eventsPlayback: boolean,
    onPlayback: (playback: Playback) => void
}

export function EventList(props: EventListProps) {
    const { site, units, isPortrait, requiredRange, playback, eventsPlayback, onPlayback } = props

    const theme = useTheme()
    const { t } = useTranslation()

    const {
        events,
        fetching,
        error,
        fetchOlder,
        fetchNewer,
        range,
        online,
    } = useEvents(site.ID, units.length > 1 ? undefined : units[0].ShortName, requiredRange, eventFilter)

    const [filterOpen, setFilterOpen] = useState(false)
    const { filter, update } = useEventFilter(eventFilterKey(site, units), defaultFilter)

    const filteredEvents = useMemo(() => {
        if (!filter.enabled) {
            return events
        }
        return events.filter(e => MatchEvent(filter, e))
    }, [events, filter])

    const onEventsDatas = useCallback((): Datas =>
        filteredEvents.map(event => {
            var row: { [key: string]: string } = {}
            row[t("timestamp")] = `"${ToLongLocalDateTime(event.timestamp)}"`
            row[t("events")] = `"${event.message}"`
            return row
        })
        , [t, filteredEvents])

    return (
        <Stack
            height={isPortrait ? "50%" : "100%"}
            width={isPortrait ? "100%" : "50%"}
            direction="column"
            sx={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.palette.panel.border,
                backgroundColor: theme.palette.background.default,
                // backgroundImage: darken(0.1),
            }}
        >
            <EventListTitle
                range={range}
                site={site}
                units={units}
                count={filteredEvents.length}
                playback={playback}
                filtered={filter.enabled}
                onEventsDatas={onEventsDatas}
                onFilterEvents={() => setFilterOpen(true)}
            />
            <Box
                width="100%"
                flexGrow={1}
                marginTop={0.5}
            >
                <EventListRows
                    events={filteredEvents}
                    playback={playback}
                    eventsPlayback={eventsPlayback}
                    fetching={fetching}
                    online={online}
                    error={error}
                    range={range}
                    onFetchNewer={fetchNewer}
                    onFetchOlder={fetchOlder}
                    onPlayback={onPlayback}
                />
            </Box>
            {filterOpen &&
                <EventFilterDialog
                    initialFilter={filter}
                    defaultFilter={defaultFilter}
                    filterTree={filterTree}
                    disableEmpty
                    disableFull
                    allowByText
                    onClose={() => setFilterOpen(false)}
                    onChanged={f => {
                        update(f)
                        setFilterOpen(false)
                    }} />
            }
        </Stack>
    )
}

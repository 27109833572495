import { History, NotificationsActive } from "@mui/icons-material";
import { FormControlLabel, Stack, Switch, Tooltip, useTheme } from "@mui/material";
import { t } from "i18next";
import { NotificationIcon } from "./NotificationIcon";
import { usePartitionPermission } from "../../auth/AuthorizerProvider";
import { Operation } from "../../api/Authz";
import { Unit } from "../../api/Customer";

export interface PartitionInfoProps {
    armed: boolean;
    alarmID: number;
    partitionID: number;
    isSystem: boolean;
    unit: Unit;
    alarm: boolean;
    memoryAlarm: boolean;
    flipSwitch: (switchOn: boolean) => void;
}

export const PartitionInfo = (props: PartitionInfoProps) => {
    const { armed, alarmID, partitionID, isSystem, unit, alarm, memoryAlarm, flipSwitch } = props;
    const theme = useTheme();

    const armOperation = isSystem ? Operation.ARM_SYSTEM_PARTITION : Operation.ARM_PARTITION;
    const disarmOperation = isSystem ? Operation.DISARM_SYSTEM_PARTITION : Operation.DISARM_PARTITION;
    const allowOperation = usePartitionPermission(armed ? disarmOperation : armOperation, unit, alarmID, partitionID);

    return (
        <Stack
            direction="row"
            px={2}
            height="100%"
            sx={{
                alignItems: "center",
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <FormControlLabel
                sx={{ flexGrow: 1 }}
                control={
                    <Tooltip title={armed ? t("action.disarm_tooltip") : t("action.arm_tooltip")} disableInteractive>
                        <Switch
                            checked={armed}
                            disabled={!allowOperation}
                            onClick={() => {
                                flipSwitch(armed);
                            }}
                        />
                    </Tooltip>
                }
                label={armed ? t("alarm.armed") : t("alarm.disarmed")}
            />
            <Stack
                direction="row"
                sx={{
                    alignItems: "right",
                }}
            >
                <NotificationIcon
                    onTooltip={t("alarm.alarm_active")}
                    offTooltip={t("alarm.alarm_inactive")}
                    condition={alarm}
                    iconCtor={(p) => <NotificationsActive sx={p} />}
                />
                <NotificationIcon
                    onTooltip={t("alarm.in_memory_active")}
                    offTooltip={t("alarm.in_memory_inactive")}
                    condition={memoryAlarm}
                    iconCtor={(p) => <History sx={p} />}
                />
            </Stack>
        </Stack>
    );
};

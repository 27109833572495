import { AddLocation, CenterFocusStrong, DeleteForever, Edit, GpsFixed, LocationOff } from "@mui/icons-material"
import { Box } from "@mui/material"
import { Stack } from "@mui/system"
import { useTranslation } from "react-i18next"
import { ActionButton } from "./ActionButton"

export interface DeviceActionsProps {
    admin: boolean
    moveCameras: boolean
    hasPosition: boolean
    onPickDevicePosition: () => void
    onClearDevicePosition: () => void
    onTargetDevice: () => void
    onFocusDevice: () => void
    onEditDevice: () => void
    onDeleteDevice: () => void
}

export const DeviceActions = (props: DeviceActionsProps) => {
    const {
        admin, moveCameras, hasPosition,
        onPickDevicePosition, onClearDevicePosition, onTargetDevice, onFocusDevice, onEditDevice, onDeleteDevice,
    } = props

    const { t } = useTranslation()

    return (
        <Stack direction="row" spacing={0}>
            <Box flexGrow={1} />
            <ActionButton
                tooltip={t("action.set_position_manually_tooltip")}
                icon={<AddLocation />}
                disabled={!admin}
                color="primary"
                onClick={onPickDevicePosition}
            />
            <ActionButton
                tooltip={t("action.clear_position_tooltip")}
                icon={<LocationOff />}
                color="primary"
                disabled={!hasPosition || !admin}
                onClick={onClearDevicePosition}
            />
            <ActionButton
                tooltip={t("action.locate_on_map_tooltip")}
                icon={<GpsFixed />}
                color="primary"
                disabled={!hasPosition}
                onClick={onFocusDevice}
            />
            <ActionButton
                tooltip={t("action.target_by_camera_tooltip")}
                icon={<CenterFocusStrong />}
                color="primary"
                disabled={!hasPosition || !moveCameras}
                onClick={onTargetDevice}
            />
            <ActionButton
                tooltip={t("action.edit_target_tooltip")}
                icon={<Edit />}
                disabled={!admin}
                color="primary"
                onClick={onEditDevice}
            />
            <ActionButton
                tooltip={t("action.delete_target_tooltip")}
                icon={<DeleteForever />}
                disabled={!admin}
                color="secondary"
                onClick={onDeleteDevice}
            />
        </Stack>
    )
}

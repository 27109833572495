import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CartesianGrid, Label, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Unit } from "../../api/Customer";
import { DataPoints } from "../../api/OpenHAB";
import { http } from "../../backend/request";
import { Before, Minute, ToShortLocalDateTime, ToShortLocalTime } from "../../config/time";
import { openHabEndpointURL } from "../../config/urls";

interface Value {
    time: number
    value: number
}

export interface Ruler {
    value: number
    label: string
    color: string
}

export interface Channel {
    name: string
    label: string
    color?: string
}

export interface MultiChartProps {
    unit: Unit
    items: Channel[]
    millis: number
    valueUnit: string
    rulers?: Ruler[]
    startValue?: number
    endValue?: number
}

const INTERVAL = 5 * Minute

const createQueryURL = (unit: Unit, item: string, millis: number) =>
    openHabEndpointURL(unit, `persistence/items/${item}?starttime=${new Date(Before(millis)).toISOString()}`)

export function MultiChart(props: MultiChartProps) {
    const { unit, items, millis, valueUnit, rulers, startValue, endValue } = props

    const [data, setData] = useState<Map<string, Value[]>>()

    const snackbar = useSnackbar()
    const theme = useTheme()

    const reloadData = useCallback(
        () => {
            const newData = new Map<string, Value[]>()
            Promise.all(
                items.map(
                    item => http<DataPoints>('Getting timeseries', createQueryURL(unit, item.name, millis), snackbar)
                        .then(result => newData.set(item.name, result.data.map(d => {
                            return {
                                time: d.time,
                                value: +d.state,
                            }
                        })))
                        .catch(e => console.log(e))
                )).finally(() => setData(newData))
        },
        [snackbar, unit, items, millis]
    )

    useEffect(() => reloadData(), [reloadData])

    useEffect(() => {
        const interval = setInterval(() => reloadData(), INTERVAL);
        return () => clearInterval(interval);
    }, [reloadData])

    const allValues = useMemo(() =>
        data ? Array.from(data.values()).flatMap(series => series.map(v => v.value)) : [],
        [data])
    const allTimes = useMemo(() =>
        data ? Array.from(data.values()).flatMap(series => series.map(v => v.time)) : [],
        [data])
    const allRulers = useMemo(() =>
        rulers ? rulers.map(r => r.value) : [],
        [rulers])
    const timeRange = useMemo(() => [
        Math.min(...allTimes, new Date().getTime() - millis),
        Math.max(...allTimes, new Date().getTime())
    ], [allTimes, millis])
    const valueRange = useMemo(() => [
        startValue !== undefined ?
            Math.floor(Math.min(startValue, ...allValues, ...allRulers)) :
            Math.floor(Math.min(...allValues, ...allRulers)),
        endValue !== undefined ?
            Math.ceil(Math.max(endValue, ...allValues, ...allRulers)) :
            Math.ceil(Math.max(...allValues, ...allRulers))
    ], [allValues, allRulers, startValue, endValue])

    const color = useCallback((i: number) => {
        const r = (i * 115) % 150 + 100
        const g = (70 + (i * 125)) % 150 + 100
        const b = (140 - (i * 90)) % 150 + 100
        return `rgb(${r},${g},${b})`
    }, [])

    const valueFormatter = useCallback((s: any) => (+s).toFixed(2) + " " + valueUnit, [valueUnit])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                margin={{ top: 20, right: 20, left: 5, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="1 4" opacity={0.2} />
                <Tooltip
                    isAnimationActive={false}
                    contentStyle={{ backgroundColor: theme.palette.grey[800], color: theme.palette.grey[50] }}
                    labelFormatter={ToShortLocalDateTime}
                    formatter={valueFormatter}
                    cursor={false}
                />
                <XAxis
                    dataKey="time"
                    fontSize="small"
                    scale="time"
                    tickFormatter={ToShortLocalTime}
                    type="number"
                    domain={timeRange}
                    allowDuplicatedCategory={false}
                    interval="preserveStartEnd"
                />
                <YAxis
                    unit={" " + valueUnit}
                    fontSize="small"
                    width={55}
                    dataKey="value"
                    domain={valueRange}
                    scale="linear"
                />
                {
                    items.map((item, idx) => {
                        const series = data?.get(item.name)
                        if (!series || series.length === 0) {
                            return null
                        }
                        return <Line
                            key={`line-${item.name}`}
                            isAnimationActive={false}
                            type="monotone"
                            dataKey="value"
                            data={series}
                            name={item.label}
                            stroke={item.color || color(idx)}
                            opacity={1}
                            dot={false}
                            strokeWidth={2}
                            scale="linear"
                            allowReorder="yes"
                            xAxisId={0}
                            yAxisId={0}
                        />
                    })
                }
                {rulers &&
                    rulers.map(r =>
                        <ReferenceLine key={r.label} y={r.value} stroke={r.color} strokeDasharray="4 1">
                            <Label fontSize="0.7em" value={r.label} fill={r.color} position="top" />
                        </ReferenceLine>
                    )
                }
            </LineChart>
        </ResponsiveContainer>
    )
}

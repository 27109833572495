import { Home, NavigateNext } from "@mui/icons-material"
import { AppBar, Box, Breadcrumbs, Divider, Link, Toolbar, Tooltip, Typography, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { Link as ReactLink } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { Customer, Site, Unit } from "../api/Customer"
import { User } from "../api/User"
import useConfig from "../config/Provider"
import { minPageWidth } from "../config/sizing"
import { retainConfig, staticURL } from "../config/urls"
import { commit, version } from "../config/version"
import { AccountMenu } from "./AccountMenu"

export interface UnitAppBarProps {
    actor: User
    site?: Site
    unit?: Unit
    customers: Customer[]
}

export function UnitAppBar(props: UnitAppBarProps) {
    const { actor, unit, site, customers } = props

    const theme = useTheme()
    const { embedded } = useConfig()

    const renderBuildInfo = () => (
        <Stack>
            <Typography fontSize="small" fontWeight="bold">EyeTowers Portal</Typography>
            <Divider sx={{ mb: "0.5rem" }} />
            <Typography fontSize="small"><span style={{ color: "lightgray" }}>Version:</span> {version || "unknown"}</Typography>
            <Typography fontSize="small"><span style={{ color: "lightgray" }}>Commit:</span> {commit || "unknown"}</Typography>
        </Stack>
    )

    if (embedded) {
        return null
    }

    return (
        <>
            <AppBar color="default">
                <Toolbar variant="dense">
                    <Stack direction="row" width="100%" minWidth={minPageWidth} pt={0.5} spacing={1} alignItems="center">
                        <Tooltip title={renderBuildInfo()} disableInteractive>
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: 38,
                                    content: {
                                        xs: `url(${staticURL(theme.palette.mode === "dark" ? "logo-small.svg" : "logo-small-light.svg")})`,
                                        sm: `url(${staticURL(theme.palette.mode === "dark" ? "logo.svg" : "logo-light.svg")})`,
                                    }
                                }}
                                pr={2}
                                alt="EyeTowers Logo"
                            />
                        </Tooltip>
                        <Breadcrumbs
                            separator={<NavigateNext />}
                            aria-label="breadcrumb"
                            sx={{ flexWrap: 'nowrap', display: 'flex', alignItems: "center", flexGrow: 1, flexShrink: 1 }}
                        >
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                to={retainConfig({ pathname: "/" })}
                                component={ReactLink}
                            >
                                <Home fontSize="small" sx={{ mr: 0.5 }} />
                                <Typography
                                    noWrap
                                    sx={{
                                        display: {
                                            xs: site || unit ? 'none' : 'inline-flex',
                                            lg: 'inline-flex'
                                        }
                                    }}
                                >Portal
                                </Typography>
                            </Link>
                            {site &&
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    to={retainConfig({
                                        pathname: "/",
                                        hash: `site-${site?.ID}`,
                                    })}
                                    component={HashLink}
                                >
                                    <Typography
                                        noWrap
                                        sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
                                    >
                                        {site?.DisplayName || "unknown site"}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
                                    >
                                        ...
                                    </Typography>
                                </Link>
                            }
                            {unit &&
                                <Typography
                                    noWrap
                                    sx={{ display: 'flex' }}
                                >
                                    {unit?.ShortName || "unknown"}
                                </Typography>
                            }
                        </Breadcrumbs>
                        <AccountMenu
                            actor={actor}
                            customers={customers}
                        />
                    </Stack>
                </Toolbar>
            </AppBar>
            <Toolbar variant="dense" />
        </>
    )
}

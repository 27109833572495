import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Actor, ActorType } from "../../api/Authz";
import { actorKey, CustomerActors } from "../../api/portal/hooks/Actors";
import { ActorChip } from "./ActorChip";

export interface FilterActorsDialogProps {
    initialFilter: Actor[]
    customerActors: CustomerActors
    onClose: () => void
    onSet: (filter: Actor[]) => void
}

export function FilterActorsDialog(props: FilterActorsDialogProps) {
    const { initialFilter, customerActors, onClose, onSet } = props

    const [filter, setFilter] = useState<Actor[]>(initialFilter)
    const { t } = useTranslation()

    const users = useMemo(() => customerActors.actors.filter(a => a.type === ActorType.USER), [customerActors])
    const groups = useMemo(() => customerActors.actors.filter(a => a.type === ActorType.GROUP), [customerActors])

    const selected = useMemo(() =>
        new Set(filter.map(actorKey)),
        [filter],
    )
    const isSelected = useCallback((a: Actor) => selected.has(actorKey(a)), [selected])

    const renderActor = (a: Actor) => (
        <ListItem
            key={actorKey(a)}
            onClick={() => setFilter(f => isSelected(a) ? f.filter(fa => actorKey(fa) !== actorKey(a)) : f.concat([a]))}
            sx={{ p: 0 }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <Checkbox checked={isSelected(a)} size="small" />
                </ListItemIcon>
                <ListItemText>
                    <ActorChip actor={a} customerActors={customerActors} />
                </ListItemText>
            </ListItemButton>
        </ListItem>
    )

    return (
        <Dialog onClose={onClose} aria-labelledby="filter-actors-dialog-title" open={true}>
            <DialogTitle id="filter-actors-dialog-title">
                {t("dialog.permission.filter_actors_title")}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Stack direction="row" spacing={1}>
                    <List dense>
                        {groups.length > 0 &&
                            <>
                                <ListItem>
                                    <ListItemText>
                                        <Typography>
                                            {t("field.groups")}:
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                {groups.map(renderActor)}
                            </>
                        }
                        {users.length > 0 &&
                            <>
                                <ListItem>
                                    <ListItemText>
                                        <Typography>
                                            {t("field.users")}:
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                {users.map(renderActor)}
                            </>
                        }
                    </List>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ px: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={initialFilter.length === 0}
                    onClick={() => {
                        onSet([])
                        onClose()
                    }}
                >
                    {t("action.clear_filter")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={filter.length === 0}
                    onClick={() => {
                        onSet(filter)
                        onClose()
                    }}
                >
                    {t("action.filter")}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                >
                    {t("action.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import { Close } from "@mui/icons-material"
import { Paper, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Role, RoleType } from "../../api/Authz"
import { Roles } from "../../api/portal/hooks/Roles"
import { BaseRoleChip } from "./BaseRoleChip"
import { IconText } from "./IconText"

export interface CommonRoleTooltipProps {
    commonRole: Role
    roles: Roles
}

export const CommonRoleTooltip = (props: CommonRoleTooltipProps) => {
    const { commonRole, roles } = props

    const { t } = useTranslation()
    const baseRoles = useMemo(() => roles.commonRoles.get(commonRole.id)?.roles || [], [roles, commonRole])

    return (
        <Stack m={1} mb={2} spacing={1} alignItems="flex-start">
            <Typography variant="body2">
                {t("message.contains_base_roles")}
            </Typography>
            <Paper variant="outlined" sx={{ overflowY: "auto", maxHeight: 350 }}>
                <Stack m={1} spacing={1} alignItems="flex-start">
                    {baseRoles.length > 0 ?
                        baseRoles.map(r =>
                            <BaseRoleChip
                                key={r.id}
                                baseRole={{ type: RoleType.BASE_ROLE, id: r.id }}
                                roles={roles}
                            />
                        ) :
                        <IconText icon={<Close />} text={t("message.no_roles")} italic />
                    }
                </Stack>
            </Paper>
        </Stack>
    )
}

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { baseRoleApi } from './api/portal/BaseRoles'
import { commonRoleApi } from './api/portal/CommonRoles'
import { groupApi } from './api/portal/Groups'
import { groupUsersApi } from './api/portal/GroupUsers'
import { permissionApi } from './api/portal/Permissions'
import { userApi } from './api/portal/Users'

export const store = configureStore({
    reducer: {
        [baseRoleApi.reducerPath]: baseRoleApi.reducer,
        [commonRoleApi.reducerPath]: commonRoleApi.reducer,
        [groupApi.reducerPath]: groupApi.reducer,
        [groupUsersApi.reducerPath]: groupUsersApi.reducer,
        [permissionApi.reducerPath]: permissionApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
        baseRoleApi.middleware,
        commonRoleApi.middleware,
        groupApi.middleware,
        groupUsersApi.middleware,
        permissionApi.middleware,
        userApi.middleware,
    ),
})

setupListeners(store.dispatch)

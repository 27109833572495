import { Typography } from "@mui/material"
import { LatLng } from "leaflet"
import { useConfirm } from "material-ui-confirm"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MapContainer, Marker, TileLayer } from "react-leaflet"
import { Operation } from "../../api/Authz"
import { Unit } from "../../api/Customer"
import { useAnyUnitCamerasPermission, useUnitPermission } from "../../auth/AuthorizerProvider"
import { request } from "../../config/headers"
import { eyeTrackEndpointURL } from "../../config/urls"
import { Camera } from "./api/Camera"
import { Device } from "./api/Device"
import { GPS, toLeafletPosition } from "./api/GPS"
import { ImageOverlay } from "./api/ImageOverlay"
import { CamerasDialog } from "./components/CamerasDialog"
import { CancelMenu } from "./components/CancelMenu"
import { DeviceDialog } from "./components/DeviceDialog"
import { DevicesDialog } from "./components/DevicesDialog"
import { ImageOverlaysDialog } from "./components/ImageOverlaysDialog"
import { MainMenu } from "./components/MainMenu"
import { PickCameraDialog } from "./components/PickCameraDialog"
import { PickDeviceDialog } from "./components/PickDeviceDialog"
import { PickPosition } from "./components/PickPosition"
import { DevicePopup } from "./map/DevicePopup"
import { EditImageOverlay } from "./map/EditImageOverlay"
import { MUILayers } from "./map/MUILayers"
import { MUIZoom } from "./map/MUIZoom"
import { cameraIcon, targetIcon } from "./map/MarkerIcons"

export interface TrackingProps {
    unit: Unit
}

const position: [number, number] = [50.1409058, 14.1186492]

const jsonOrReject = async (result: Response) => {
    if (!result.ok) {
        const err = await result.json();
        return await Promise.reject(err);
    }
    return result.json()
}

export const Tracking = (props: TrackingProps) => {
    const { unit } = props

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError]: [any, any] = useState(null)
    const [hybrid, setHybrid] = useState(true)

    const [cameras, setCameras] = useState<Camera[]>([])
    const [devices, setDevices] = useState<Device[]>([])
    const [devicePositions, setDevicePositions] = useState<{ [id: string]: GPS }>({})
    const [imageOverlays, setImageOverlays] = useState<ImageOverlay[]>([])

    const [devicesDialogOpen, setDevicesDialogOpen] = useState(false)
    const [camerasDialogOpen, setCamerasDialogOpen] = useState(false)
    const [imageOverlaysDialogOpen, setImageOverlaysDialogOpen] = useState(false)

    const [calibratingCamera, setCalibratingCamera] = useState<Camera | null>(null)
    const [targetingCamera, setTargetingCamera] = useState<Camera | null>(null)
    const [targetingDevice, setTargetingDevice] = useState<Device | null>(null)
    const [creatingDevice, setCreatingDevice] = useState(false)
    const [editingDevice, setEditingDevice] = useState<Device | null>(null)
    const [positioningDevice, setPositioningDevice] = useState<Device | null>(null)
    const [positioningImageOverlay, setPositioningImageOverlay] = useState<ImageOverlay | null>(null)

    const admin = useUnitPermission(Operation.MANAGE_TRACKING, unit)
    const moveSomeCameras = useAnyUnitCamerasPermission(Operation.MOVE_CAMERA, unit)

    const confirm = useConfirm()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const processActionResult = (action: string, promise: Promise<Response>) => {
        promise.then(
            (result) => {
                if (result.ok) {
                    enqueueSnackbar(`${action} successful.`, { variant: 'success' })
                } else {
                    result.text().then(
                        (text) => {
                            const msg = `${action} failed (HTTP ${result.status}): ${text}.`
                            console.log(msg, error)
                            enqueueSnackbar(msg, { variant: 'error' })
                        }
                    ).catch(
                        (error) => {
                            const msg = `${action} failed (HTTP ${result.status}): no details available.`
                            console.log(msg, error)
                            enqueueSnackbar(msg, { variant: 'error' })
                        }
                    )
                }
            },
            (error) => {
                const msg = `${action} failed due to network error.`
                console.log(msg, error)
                enqueueSnackbar(msg, { variant: 'error' })
            })
    }

    const onCalibrate = (device: Device, camera: Camera | null) => {
        if (!camera) {
            console.log("Calibrating camera undefined.", device)
            return
        }
        console.log("Calibrating camera on device", camera, device)
        processActionResult(
            `Calibrating camera ${camera.Name}`,
            fetch(eyeTrackEndpointURL(unit, "cameras/" + camera.ID + "/calibration"), {
                method: 'PUT',
                body: device.ID.toString(),
                headers: request.headers,
            }),
        )
    }

    const onTarget = (device: Device, camera: Camera | null) => {
        if (!camera) {
            console.log("Targeting camera undefined.", device)
            return
        }
        console.log("Targeting camera on device", camera, device)
        processActionResult(
            `Targeting camera ${camera.Name} on device ${device.Name}`,
            fetch(eyeTrackEndpointURL(unit, "cameras/" + camera.ID + "/target"), {
                method: 'PUT',
                body: device.ID.toString(),
                headers: request.headers,
            })
        )
    }

    const updateImageOverlay = (imageOverlay: ImageOverlay) => {
        console.log("Updating image overlay", imageOverlay)
        fetch(eyeTrackEndpointURL(unit, "image-overlays/" + imageOverlay.ID), {
            method: 'PUT',
            body: JSON.stringify(imageOverlay),
            headers: request.headers,
        })
            .then(jsonOrReject)
            .then(
                (result) => {
                    console.log(result)
                    setImageOverlays(imageOverlays.map(io => {
                        if (io.ID === imageOverlay.ID) {
                            return result as ImageOverlay
                        }
                        return io
                    }))
                },
                (error) => {
                    setError(error)
                })
    }

    const insertImageOverlay = (imageOverlay: ImageOverlay) => {
        console.log("Inserting image overlay", imageOverlay)
        fetch(eyeTrackEndpointURL(unit, "image-overlays"), {
            method: 'POST',
            body: JSON.stringify(imageOverlay),
            headers: request.headers,
        })
            .then(jsonOrReject)
            .then(
                (result) => {
                    console.log(result)
                    setImageOverlays(imageOverlays.concat([result as ImageOverlay]))
                },
                (error) => {
                    setError(error)
                })
    }

    const onUpdateImageOverlay = (imageOverlay: ImageOverlay) => {
        if (imageOverlay.ID === -1) {
            insertImageOverlay(imageOverlay)
        } else {
            updateImageOverlay(imageOverlay)
        }
        setPositioningImageOverlay(null)
    }

    const onDeleteImageOverlay = (imageOverlay: ImageOverlay) => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.delete_overlay", { overlay: imageOverlay.Name }),
            cancellationText: t("action.cancel"),
            confirmationText: t("action.delete"),
            confirmationButtonProps: {
                color: 'secondary',
            },
        }).then(() => {
            fetch(eyeTrackEndpointURL(unit, "image-overlays/" + imageOverlay.ID), {
                method: 'DELETE',
                headers: request.headers,
            })
                .then(
                    (result) => {
                        console.log(result)
                    },
                    (error) => {
                        setError(error)
                    })
            setImageOverlays(imageOverlays.filter(o => o !== imageOverlay))
        }).catch(() => { })
    }

    const onCreateImageOverlay = () => {
        setPositioningImageOverlay({
            ID: -1,
            Name: "",
            URL: "https://eyetowers-external-sharing.s3.eu-central-1.amazonaws.com/overlays/overlay.svg",
            NWLat: NaN,
            NWLng: NaN,
            NELat: NaN,
            NELng: NaN,
            SWLat: NaN,
            SWLng: NaN,
            SELat: NaN,
            SELng: NaN,
        })
    }

    const onSetDevicePosition = (device: Device, pos: LatLng) => {
        console.log("Positioning device", device, pos)
        fetch(eyeTrackEndpointURL(unit, "devices/" + device.ID + "/positions"), {
            method: 'POST',
            body: JSON.stringify({
                Latitude: pos.lat,
                Longitude: pos.lng,
            }),
            headers: request.headers,
        })
            .then(
                (result) => {
                    console.log(result)
                },
                (error) => {
                    setError(error)
                })
        setDevicePositions(positions => {
            positions[device.ID] = {
                Latitude: pos.lat,
                Longitude: pos.lng,
                CreatedAt: new Date().toUTCString()
            }
            return positions
        })
    }

    const onClearDevicePosition = (device: Device) => {
        console.log("Clearing device position", device)
        fetch(eyeTrackEndpointURL(unit, "devices/" + device.ID + "/position"), {
            method: 'DELETE',
            headers: request.headers,
        })
            .then(
                (result) => {
                    console.log(result)
                },
                (error) => {
                    setError(error)
                })
        const newPositions: { [id: string]: GPS } = {}
        devices.filter((d) => devicePositions[d.ID] && d.ID !== device.ID).forEach(
            (d) => { newPositions[d.ID] = devicePositions[d.ID] }
        )
        setDevicePositions(positions => {
            delete positions[device.ID]
            return positions
        })
    }

    const onDeleteDevice = (device: Device) => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.delete_target", { target: `${device.Name}/${device.IMEI}` }),
            cancellationText: t("action.cancel"),
            confirmationText: t("action.delete"),
            confirmationButtonProps: {
                color: 'secondary',
            },
        }).then(() => {
            console.log("Deleting device", device)
            fetch(eyeTrackEndpointURL(unit, "devices/" + device.ID), {
                method: 'DELETE',
                headers: request.headers,
            })
                .then(
                    (result) => {
                        console.log(result)
                    },
                    (error) => {
                        enqueueSnackbar(error.Message || JSON.stringify(error), { variant: 'error' })
                    })
            setDevices(devices => devices.filter(d => d.ID !== device.ID))
        }).catch(() => { })
    }

    const onCreateDevice = (device: Device) => {
        console.log("Inserting device", device)
        fetch(eyeTrackEndpointURL(unit, "devices"), {
            method: 'POST',
            body: JSON.stringify(device),
            headers: request.headers,
        })
            .then(jsonOrReject)
            .then(
                (result) => {
                    console.log(result)
                    setDevices(devices => devices.concat([result as Device]))
                },
                (error) => {
                    enqueueSnackbar(error.Message || JSON.stringify(error), { variant: 'error' })
                })
    }

    const onSaveDevice = (device: Device) => {
        console.log("Saving device", device)
        fetch(eyeTrackEndpointURL(unit, `devices/${device.ID}`), {
            method: 'PUT',
            body: JSON.stringify(device),
            headers: request.headers,
        })
            .then(jsonOrReject)
            .then(
                (result) => {
                    console.log(result)
                    setDevices(devices => devices.map(d => d.ID === device.ID ? result as Device : d))
                },
                (error) => {
                    enqueueSnackbar(error.Message || JSON.stringify(error), { variant: 'error' })
                })
    }

    useEffect(() => {

        Promise.all([
            fetch(eyeTrackEndpointURL(unit, "image-overlays"), request)
                .then(jsonOrReject)
                .then(
                    (result) => {
                        setImageOverlays(result as ImageOverlay[])
                    },
                    (error) => {
                        setError(error)
                    }
                ),

            fetch(eyeTrackEndpointURL(unit, "cameras"), request)
                .then(jsonOrReject)
                .then(
                    (result) => {
                        setCameras(result as Camera[])
                    },
                    (error) => {
                        setError(error)
                    }
                ),

            fetch(eyeTrackEndpointURL(unit, "devices"), request)
                .then(jsonOrReject)
                .then(
                    result => {
                        const devs = result as Device[]
                        const pos: { [id: string]: GPS } = {}
                        Promise.all(
                            devs.map(dev => {
                                return fetch(eyeTrackEndpointURL(unit, "devices/" + dev.ID + "/position"), request)
                                    .then(jsonOrReject)
                                    .then(result => {
                                        const position = result as GPS
                                        pos[dev.ID] = position
                                    }, error => {
                                        console.log("Loading device position", dev.ID, error)
                                    })
                            })
                        ).then(
                            () => {
                                setDevicePositions(pos)
                                setDevices(result)
                            },
                            error => {
                                setError(error)
                            }
                        )
                    },
                    (error) => {
                        setError(error);
                    }
                )]).then(
                    () => {
                        setIsLoaded(true)
                    }
                )
    }, [unit])

    if (error) {
        return (
            <Typography>
                {JSON.stringify(error)}
            </Typography>
        )
    }
    if (!isLoaded) {
        return (
            <Typography>
                {t("message.loading")}
            </Typography>
        )
    }

    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
    const getCamerasForDevice = (d: Device) => cameras.filter(c => c.DeviceID === d.ID)
    const getMinUnusedID = () => {
        for (let i = 1; i <= 64; i++) {
            if (devices.every(d => d.ID !== i)) {
                return i
            }
        }
        return 0
    }

    const here = {
        apiKey: 'W7N1AsS4SbPLEtG8My3okOODzZv1AL9DB52XgWd8qVU'
    }
    const style = 'hybrid.day'
    const hereTileUrl = `https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?apiKey=${here.apiKey}&ppi=320`
    const hereAttribution = '&copy; HERE 2024'

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <MapContainer
                zoom={18}
                zoomControl={false}
                center={position}
                style={{ cursor: positioningDevice ? "crosshair" : "default", height: '100%', width: '100%' }}
            >

                {
                    imageOverlays.map(io =>
                        <EditImageOverlay
                            imageOverlay={io}
                            editable={io.ID === positioningImageOverlay?.ID}
                            onSave={onUpdateImageOverlay}
                            onCancel={() => setPositioningImageOverlay(null)}
                            key={io.Name}
                        />
                    )
                }
                {positioningImageOverlay != null && positioningImageOverlay.ID === -1 &&
                    <EditImageOverlay
                        imageOverlay={positioningImageOverlay}
                        editable={true}
                        onSave={onUpdateImageOverlay}
                        onCancel={() => setPositioningImageOverlay(null)}
                        key="__new_image_overlay"
                    />
                }

                <MUIZoom />
                <MUILayers onRotate={() => setHybrid(h => !h)} />

                {hybrid ?
                    <TileLayer
                        maxZoom={22}
                        maxNativeZoom={18}
                        attribution={hereAttribution}
                        url={hereTileUrl} /> :
                    <TileLayer
                        maxZoom={22}
                        maxNativeZoom={19}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                }

                {
                    devices.filter(d => d.ID in devicePositions).map(d => {
                        const devCams = getCamerasForDevice(d)
                        return (
                            <Marker position={toLeafletPosition(devicePositions[d.ID])} key={`device-${d.ID}`}
                                icon={devCams.length !== 0 ? cameraIcon(zeroPad(d.ID, 2)) : targetIcon(zeroPad(d.ID, 2))}
                            >
                                <DevicePopup
                                    admin={admin}
                                    moveCameras={moveSomeCameras}
                                    unit={unit}
                                    device={d}
                                    cameras={devCams}
                                    position={devicePositions[d.ID]}
                                    onClearDevicePosition={() => onClearDevicePosition(d)}
                                    onPickDevicePosition={() => setPositioningDevice(d)}
                                    onTargetDevice={() => setTargetingDevice(d)}
                                    onEditDevice={() => setEditingDevice(d)}
                                    onDeleteDevice={() => onDeleteDevice(d)}
                                    onTargetCamera={setTargetingCamera}
                                    onCalibrateCamera={setCalibratingCamera}
                                />
                            </Marker >
                        )
                    })
                }

                <MainMenu
                    open={!positioningDevice && !positioningImageOverlay}
                    admin={admin}
                    onDevices={() =>
                        setDevicesDialogOpen(true)
                    }
                    onCameras={() =>
                        setCamerasDialogOpen(true)
                    }
                    onImageOverlays={() =>
                        setImageOverlaysDialogOpen(true)
                    }
                />
                <CancelMenu
                    open={positioningDevice != null}
                    onCancel={() =>
                        setPositioningDevice(null)
                    }
                />

                <DevicesDialog
                    open={devicesDialogOpen}
                    admin={admin}
                    moveCameras={moveSomeCameras}
                    devices={devices}
                    devicePositions={devicePositions}
                    onClose={() =>
                        setDevicesDialogOpen(false)
                    }
                    onPickDevicePosition={(device) => {
                        setPositioningDevice(device)
                        setDevicesDialogOpen(false)
                    }}
                    onClearDevicePosition={(device) => {
                        onClearDevicePosition(device)
                        setDevicesDialogOpen(false)
                    }}
                    onTargetDevice={(device) => {
                        setTargetingDevice(device)
                        setDevicesDialogOpen(false)
                    }}
                    onEditDevice={setEditingDevice}
                    onDeleteDevice={onDeleteDevice}
                    onNewDevice={() => setCreatingDevice(true)}
                />
                <CamerasDialog
                    open={camerasDialogOpen}
                    admin={admin}
                    unit={unit}
                    cameras={cameras}
                    onClose={() =>
                        setCamerasDialogOpen(false)
                    }
                    onCalibrate={(camera) => {
                        setCalibratingCamera(camera)
                        setCamerasDialogOpen(false)
                    }}
                    onTarget={(camera) => {
                        setTargetingCamera(camera)
                        setCamerasDialogOpen(false)
                    }}
                />
                <ImageOverlaysDialog
                    open={imageOverlaysDialogOpen}
                    admin={admin}
                    imageOverlays={imageOverlays}
                    onClose={() =>
                        setImageOverlaysDialogOpen(false)
                    }
                    onCreate={() => {
                        onCreateImageOverlay()
                        setImageOverlaysDialogOpen(false)
                    }}
                    onDelete={onDeleteImageOverlay}
                    onEdit={(imageOverlay) => {
                        setPositioningImageOverlay(imageOverlay)
                        setImageOverlaysDialogOpen(false)
                    }}
                />
                {calibratingCamera &&
                    <PickDeviceDialog
                        title={t("tracking.calibrate_camera_on")}
                        devices={devices}
                        onPicked={(d) => {
                            onCalibrate(d, calibratingCamera)
                            setCalibratingCamera(null)
                        }}
                        onCancel={() =>
                            setCalibratingCamera(null)
                        }
                    />
                }
                {targetingCamera &&
                    <PickDeviceDialog
                        title={t("tracking.target_camera_on")}
                        devices={devices}
                        onPicked={(device) => {
                            onTarget(device, targetingCamera)
                            setTargetingCamera(null)
                        }}
                        onCancel={() =>
                            setTargetingCamera(null)
                        }
                    />
                }
                {targetingDevice &&
                    <PickCameraDialog
                        title={t("tracking.target_by_camera")}
                        unit={unit}
                        cameras={cameras}
                        onPicked={(camera) => {
                            onTarget(targetingDevice, camera)
                            setTargetingDevice(null)
                        }}
                        onCancel={() =>
                            setTargetingDevice(null)
                        }
                    />
                }
                {positioningDevice &&
                    <PickPosition
                        onPositionPicked={(position) => {
                            onSetDevicePosition(positioningDevice, position)
                            setPositioningDevice(null)
                        }}
                    />
                }
                {creatingDevice &&
                    <DeviceDialog
                        defaultID={getMinUnusedID()}
                        onCancel={() => setCreatingDevice(false)}
                        onSave={(device) => {
                            setCreatingDevice(false)
                            onCreateDevice(device)
                        }}
                    />
                }
                {editingDevice &&
                    <DeviceDialog
                        device={editingDevice}
                        onCancel={() => setEditingDevice(null)}
                        onSave={(device) => {
                            setEditingDevice(null)
                            onSaveDevice(device)
                        }}
                    />
                }
            </MapContainer>
        </div >
    )
}
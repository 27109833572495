import { History, NotificationsActive } from "@mui/icons-material"
import { FormControlLabel, Stack, Switch, useTheme } from "@mui/material"
import { t } from "i18next"
import { Item, StateON } from "../../api/OpenHAB"
import { NotificationIcon } from "./NotificationIcon"

export interface PartitionInfoProps {
    label: string
    arm: Item
    disabled: boolean
    alarm: boolean
    memoryAlarm: boolean
    flipSwitch: (item: Item) => void
}

export const PartitionInfo = (props: PartitionInfoProps) => {
    const { label, arm, disabled, alarm, memoryAlarm, flipSwitch } = props
    const theme = useTheme()

    return <Stack direction="row"
        px={1} height="100%"
        sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}
    >
        <FormControlLabel
            control={
                <Switch
                    checked={arm.state === StateON}
                    disabled={disabled}
                    onClick={() => flipSwitch(arm)}
                />
            }
            label={label}
        />
        <NotificationIcon
            onTooltip={t("alarm.alarm_active")}
            offTooltip={t("alarm.alarm_inactive")}
            condition={alarm}
            iconCtor={p => <NotificationsActive sx={p} />}
        />
        <NotificationIcon
            onTooltip={t("alarm.in_memory_active")}
            offTooltip={t("alarm.in_memory_inactive")}
            condition={memoryAlarm}
            iconCtor={p => <History sx={p} />}
        />
    </Stack>
}
import { BatteryAlert, CloudOff, History, NotificationsActive, Sensors } from "@mui/icons-material";
import { FormControlLabel, Stack, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotificationIcon } from "./NotificationIcon";

export interface ZoneInfoProps {
    label: string;
    bypass: boolean;
    disabled: boolean;
    alarm: boolean;
    memoryAlarm: boolean;
    violation: boolean;
    lowBattery: boolean;
    noConnection: boolean;
    flipSwitch: (switchOn: boolean) => void;
}

export const ZoneInfo = (props: ZoneInfoProps) => {
    const { label, bypass, disabled, alarm, memoryAlarm, violation, lowBattery, noConnection, flipSwitch } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            height="100%"
            px={2}
            sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}
        >
            <FormControlLabel
                sx={{ flexGrow: 1 }}
                control={
                    <>
                        <Tooltip
                            title={bypass ? t("action.unbypass_tooltip") : t("action.bypass_tooltip")}
                            disableInteractive
                        >
                            <Switch
                                checked={bypass}
                                disabled={disabled}
                                onClick={() => {
                                    flipSwitch(bypass);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={label} disableInteractive>
                            <Typography
                                sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: 100,
                                }}
                            >
                                {label}
                            </Typography>
                        </Tooltip>
                    </>
                }
                label={""}
            />

            <NotificationIcon
                onTooltip={t("alarm.alarm_active")}
                offTooltip={t("alarm.alarm_inactive")}
                condition={alarm}
                iconCtor={(p) => <NotificationsActive sx={p} />}
            />
            <NotificationIcon
                onTooltip={t("alarm.in_memory_active")}
                offTooltip={t("alarm.in_memory_inactive")}
                condition={memoryAlarm}
                iconCtor={(p) => <History sx={p} />}
            />
            <NotificationIcon
                onTooltip={t("alarm.violation_active")}
                offTooltip={t("alarm.violation_inactive")}
                condition={violation}
                iconCtor={(p) => <Sensors sx={p} />}
            />
            <NotificationIcon
                onTooltip={t("alarm.low_battery_active")}
                offTooltip={t("alarm.low_battery_inactive")}
                condition={lowBattery}
                iconCtor={(p) => <BatteryAlert sx={p} />}
            />
            <NotificationIcon
                onTooltip={t("alarm.disconnect_active")}
                offTooltip={t("alarm.disconnect_inactive")}
                condition={noConnection}
                iconCtor={(p) => <CloudOff sx={p} />}
            />
        </Stack>
    );
};

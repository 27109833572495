import { useCallback, useEffect, useReducer } from "react";
import { useCooldown } from "./cooldown";

type Callback = () => Promise<void>

type State = {
    busy: boolean
    queue: Callback[],
}

type Action =
    | { type: "Push", callback: Callback, max: number }
    | { type: "Started" }
    | { type: "Finished" }
    ;

function reducer(state: State, action: Action) {
    switch (action.type) {
        case "Push":
            const queue = state.queue.length < action.max ?
                state.queue :
                state.queue.slice(0, -1)
            return {
                ...state,
                queue: queue.concat(action.callback),
            }
        case "Started":
            return {
                ...state,
                busy: true,
                queue: state.queue.slice(1),
            }
        case "Finished":
            return {
                ...state,
                busy: false,
            }
    }
}

export function useQueue(max: number, throttle: number) {

    const [state, dispatch] = useReducer(reducer, { queue: [], busy: false })

    const { cooling, trigger } = useCooldown(throttle)
    const push = useCallback((callback: Callback) => dispatch({ type: "Push", callback, max }), [max])


    useEffect(() => {
        if (state.busy || cooling || state.queue.length === 0) {
            return
        }
        trigger()
        dispatch({ type: "Started" })
        state.queue[0]().finally(() => dispatch({ type: "Finished" }))
    }, [state, cooling, dispatch, trigger])

    return { busy: state.busy, push }
}

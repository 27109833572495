import { PaletteMode } from "@mui/material";
import { ReactNode, createContext, useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

interface ConfigContextType {
    embedded: boolean
    mode: PaletteMode
}

const ConfigContext = createContext<ConfigContextType>({} as ConfigContextType);

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
    const [params] = useSearchParams()

    const embedded = useMemo(() => params.get("embedded") === "true", [params])
    const mode = useMemo(() => {
        const m = params.get("mode")
        switch (m) {
            case "dark":
            case "light":
                return m
        }
        return "dark"
    }, [params])

    return (
        <ConfigContext.Provider value={{ embedded, mode }}>
            {children}
        </ConfigContext.Provider>
    );
}

const useConfig = () => useContext(ConfigContext)
export default useConfig
import { CellTower, Checklist, Domain, Leaderboard, MeetingRoom, NotificationsActive, PhotoCamera, PinDrop, QuestionMark, Videocam, VideoLibrary } from "@mui/icons-material"
import { useTheme } from "@mui/material"
import { Role, RoleType } from "../../api/Authz"
import { Roles } from "../../api/portal/hooks/Roles"

export interface RoleIconProps {
    role: Role
    roles: Roles
}

export const RoleIcon = (props: RoleIconProps) => {
    const { role, roles } = props
    const theme = useTheme()
    const htmlColor = theme.palette.text.secondary

    switch (role.type) {
        case RoleType.BASE_ROLE:
            const br = roles.baseRoles.get(role.id)
            if (!br) {
                break
            }
            if (br.name.startsWith("access:")) {
                return <MeetingRoom fontSize="small" htmlColor={htmlColor} />
            }
            if (br.name.startsWith("alarm:")) {
                return <NotificationsActive fontSize="small" htmlColor={htmlColor} />
            }
            if (br.name.startsWith("recording:")) {
                return <VideoLibrary fontSize="small" htmlColor={htmlColor} />
            }
            if (br.name.startsWith("statistics:")) {
                return <Leaderboard fontSize="small" htmlColor={htmlColor} />
            }
            if (br.name.startsWith("timelapse:")) {
                return <PhotoCamera fontSize="small" htmlColor={htmlColor} />
            }
            if (br.name.startsWith("tracking:")) {
                return <PinDrop fontSize="small" htmlColor={htmlColor} />
            }
            if (br.name.startsWith("video:")) {
                return <Videocam fontSize="small" htmlColor={htmlColor} />
            }
            if (br.name === "admin" || br.name === "reader") {
                return <Domain fontSize="small" htmlColor={htmlColor} />
            }
            if (br.name === "unit") {
                return <CellTower fontSize="small" htmlColor={htmlColor} />
            }
            break
        case RoleType.COMMON_ROLE:
            return <Checklist fontSize="small" htmlColor={htmlColor} />
    }
    return <QuestionMark fontSize="small" htmlColor={htmlColor} />
}

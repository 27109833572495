import { OptionsObject, ProviderContext, SnackbarKey, SnackbarMessage } from "notistack";

export const noSnackBar = {
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => "no snackbar",
    closeSnackbar: (key?: SnackbarKey) => { }
}

export const snackbarError = (msg: string, snackbar?: ProviderContext) => {
    if (snackbar) {
        snackbar.enqueueSnackbar(msg, {
            variant: 'error',
        })
    }
}

export const snackbarSuccess = (msg: string, snackbar: ProviderContext) => {
    snackbar.enqueueSnackbar(msg, {
        variant: 'success',
    })
}

export async function http<T>(action: string, request: RequestInfo, snackbar?: ProviderContext, init?: RequestInit): Promise<T> {
    let response: Response
    try {
        response = await fetch(request, init);
    } catch {
        const msg = `${action} failed due to network error.`
        console.log(msg)
        snackbarError(msg, snackbar)
        return Promise.reject(new Error(msg))
    }

    if (!response.ok) {
        try {
            const text = await response.text()
            const msg = `${action} failed (HTTP ${response.status}): ${text}.`
            snackbarError(msg, snackbar)
            return Promise.reject(new Error(msg))
        } catch {
            const msg = `${action} failed (HTTP ${response.status}): no details available.`
            console.log(msg)
            snackbarError(msg, snackbar)
            return Promise.reject(new Error(msg))
        }
    }

    try {
        const json = await response.json()
        return json as T
    } catch {
        const msg = `${action} failed parsing JSON response: no details available.`
        console.log(msg)
        snackbarError(msg, snackbar)
        return Promise.reject(new Error(msg))
    }
}

export async function rawHttp(action: string, request: RequestInfo, snackbar?: ProviderContext, init?: RequestInit): Promise<string> {
    let response: Response
    try {
        response = await fetch(request, init);
    } catch {
        const msg = `${action} failed due to network error.`
        console.log(msg)
        snackbarError(msg, snackbar)
        return Promise.reject(new Error(msg))
    }

    if (!response.ok) {
        try {
            const text = await response.text()
            const msg = `${action} failed (HTTP ${response.status}): ${text}.`
            snackbarError(msg, snackbar)
            return Promise.reject(new Error(msg))
        } catch {
            const msg = `${action} failed (HTTP ${response.status}): no details available.`
            console.log(msg)
            snackbarError(msg, snackbar)
            return Promise.reject(new Error(msg))
        }
    }

    try {
        return await response.text()
    } catch {
        const msg = `${action} failed reading response: no details available.`
        console.log(msg)
        snackbarError(msg, snackbar)
        return Promise.reject(new Error(msg))
    }
}

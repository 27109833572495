import { LatLng, LeafletMouseEvent } from "leaflet";
import { useMapEvents } from "react-leaflet";

export interface PickPositionProps {
    onPositionPicked: (position: LatLng) => void
}

export function PickPosition(props: PickPositionProps) {
    const { onPositionPicked } = props

    useMapEvents({
        click: (e: LeafletMouseEvent) => {
            onPositionPicked(e.latlng)
        }
    })

    return null
}

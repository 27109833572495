import { CameraAlt, CellTower, Close, DoneAll, LocationOn } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CameraScope, isAllScope, Scope, SiteScope, UnitScope } from "../../api/Authz"
import { Site, Unit } from "../../api/Customer"
import { IconText } from "./IconText"

export type ScopeBoxProps = {
    scope: Scope
    sites: Map<number, Site>
    units: Map<string, Unit>
}

export function ScopeBox(props: ScopeBoxProps) {
    const { scope, sites, units } = props

    const { t } = useTranslation()

    const siteUnits = useMemo(() =>
        new Map(Array.from(sites.values()).map(s => [s.ID, Array.from(units.values()).filter(u => u.SiteID === s.ID)])),
        [units, sites],
    )

    const renderScope = () => {
        if (scope.customers?.length !== 1) {
            return <Typography>invalid customer scope, expects singular customer scope</Typography>
        }
        const customer = scope.customers[0]
        if (customer.all) {
            return <Typography>invalid customer scope, matches all customers</Typography>
        }
        return renderSites(customer.sites || [])
    }

    const renderSites = (scope: SiteScope[]) => {
        const allSites = isAllScope(scope)
        const selectedSites = Array.from(sites.values()).filter(site => scope.some(s => s.id === "" + site.ID))

        return (
            <Stack p={1} spacing={1}>
                {!allSites && selectedSites.length === 0 &&
                    <IconText icon={<Close />} text={t("field.no_sites")} italic />
                }
                {allSites ?
                    <IconText icon={<DoneAll />} text={t("field.all_sites")} italic /> :
                    selectedSites.map(site => (
                        <Stack key={site.ID}>
                            <IconText icon={<LocationOn />} bold text={site.DisplayName} />
                            {renderUnits(scope.find(s => s?.id === "" + site.ID)?.units || [], site)}
                        </Stack>
                    ))
                }
            </Stack >
        )
    }

    const renderUnits = (scope: UnitScope[], site: Site) => {
        const allUnits = isAllScope(scope)
        const selectedUnits = siteUnits.get(site.ID)?.filter(unit => scope.some(u => u.shortName === unit.ShortName)) || []

        return (
            <Stack pl={3}>
                {!allUnits && selectedUnits.length === 0 &&
                    <IconText icon={<Close />} text={t("field.no_units")} italic />
                }
                {allUnits ?
                    <IconText icon={<DoneAll />} text={t("field.all_units")} italic /> :
                    selectedUnits.map(unit => (
                        <Stack key={unit.ShortName} >
                            <IconText icon={<CellTower />} text={unit.DisplayName} />
                            {renderCameras(scope.find(u => u?.shortName === unit.ShortName)?.cameras || [], unit)}
                        </Stack>
                    ))
                }
            </Stack>
        )
    }

    const renderCameras = (scope: CameraScope[], unit: Unit) => {
        const allCameras = isAllScope(scope)
        const selectedCameras = unit.UnitConfig.Cameras.filter(camera => scope.some(c => c.id === camera.ID + "")) || []

        return (
            <Stack pl={3}>
                {!allCameras && selectedCameras.length === 0 &&
                    <IconText icon={<Close />} text={t("field.no_cameras")} italic />
                }
                {allCameras ?
                    <IconText icon={<DoneAll />} text={t("field.all_cameras")} italic /> :
                    selectedCameras.map(camera => (
                        <IconText key={camera.ID} icon={<CameraAlt />} text={t("camera.nth_camera", { id: camera.ID })} />
                    ))
                }
            </Stack>
        )
    }

    return renderScope()
}

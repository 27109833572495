import { CreditCard, Domain, LockPerson, People } from "@mui/icons-material";
import { Box, IconButton, Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Operation } from "../api/Authz";
import { Customer, Offering, Unit } from "../api/Customer";
import { useCustomerPermission } from "../auth/AuthorizerProvider";
import { Milli } from "../config/time";
import { retainConfig } from "../config/urls";
import { SiteInfo } from "./SiteInfo";

export interface CustomerInfoProps {
    customer: Customer
    offerings: Offering[]
    onManageUsers: () => void
    onManageIdentifiers: () => void
}

export function CustomerInfo(props: CustomerInfoProps) {
    const { customer, offerings, onManageUsers, onManageIdentifiers } = props

    const theme = useTheme()
    const { t } = useTranslation()

    const allowListUsers = useCustomerPermission(Operation.LIST_CUSTOMER_USERS, customer.ID)
    const allowListIdentifiers = useCustomerPermission(Operation.LIST_CUSTOMER_IDENTIFIERS, customer.ID)
    const allowManagePermissions = useCustomerPermission(Operation.UI_VIEW_CUSTOMER_PERMISSIONS, customer.ID)

    const siteUnits = useMemo(
        () => {
            const result = new Map<number, Unit[]>()
            customer.Units.forEach(u => {
                const units = result.get(u.SiteID) || []
                units.push(u)
                result.set(u.SiteID, units)
            })
            return result
        },
        [customer],
    )

    const renderSites = useMemo(
        () => {
            if (!siteUnits.size) {
                return (
                    <Stack direction="column" sx={{
                        borderColor: theme.palette.divider,
                        borderWidth: 1,
                        borderTopStyle: "solid",
                    }}
                    >
                        <Typography variant="body1" fontStyle="italic" color={theme.palette.text.secondary} p={2}>
                            {t("customer.no_unit")}
                        </Typography>
                    </Stack>
                )
            }
            return customer.Sites
                .filter(s => siteUnits.has(s.ID))
                .map(s =>
                    <SiteInfo key={s.ID} site={s} units={siteUnits.get(s.ID) || []} offerings={offerings} />
                )
        },
        [siteUnits, customer, offerings, t, theme])

    const { hash } = useLocation()

    useEffect(() => {
        const cancel = setTimeout(() => {
            const el = document.getElementById(hash.substring(1))
            if (!el) {
                return
            }
            const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
            // Offset to move below the toolbar.
            const yOffset = -52;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }, 300 * Milli)

        return () => clearTimeout(cancel)
    }, [hash])

    return (
        <Paper sx={{ py: 1, m: 1 }}>
            <Stack>
                <Stack
                    direction="row"
                    px={2}
                    spacing={0.5}
                    alignItems="center"
                >
                    <Domain fontSize="small" htmlColor={theme.palette.text.secondary} />
                    <Tooltip title={customer.DisplayName} disableInteractive>
                        <Typography variant="body1" fontWeight="bold" noWrap flexShrink={1}>
                            {customer.DisplayName}
                        </Typography>
                    </Tooltip>
                    <Box flexGrow={1} />
                    <Stack direction="row">
                        <Tooltip title={t("action.manage_identifiers_tooltip")} disableInteractive>
                            <span>
                                <IconButton color="primary" onClick={onManageIdentifiers} disabled={!allowListIdentifiers}><CreditCard /></IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={t("action.manage_users_tooltip")} disableInteractive>
                            <span>
                                <IconButton color="primary" onClick={onManageUsers} disabled={!allowListUsers}><People /></IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={t("action.manage_customer_permissions_tooltip")} disableInteractive>
                            <span>
                                <IconButton
                                    color="primary"
                                    disabled={!allowManagePermissions}
                                    to={retainConfig({ pathname: `./permissions/customers/${customer.ID}` })}
                                    component={Link}
                                >
                                    <LockPerson />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </Stack>
                {renderSites}
            </Stack >
        </Paper >
    )
}

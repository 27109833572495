import { CheckBoxOutlineBlank } from "@mui/icons-material";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Stack, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventType } from "../../api/Event";
import { Node, Selected, useTreeSet } from "../../hooks/treeset";
import { EqualFilter, EventFilter, IsMatchAll, NewFilter } from "../../services/EventFilter";

export interface EventFilterDialogProps {
    initialFilter: EventFilter
    defaultFilter: EventFilter
    filterTree: Node<EventType>
    disableEmpty?: boolean
    disableFull?: boolean
    dialogTitle?: string
    enableLabel?: string
    allowByText?: boolean
    onClose: () => void
    onChanged: (filter: EventFilter) => void
}

const toWords = (s: string) => {
    const parts = s.trim().split(/\s+/)
    return parts.filter(w => w.trim().length > 0).map(s => s.startsWith("/") && s.endsWith("/") ? new RegExp(s.slice(1, -1)) : s)
}

const fromWords = (s: (string | RegExp)[]) => Array.from(s).map(s => s instanceof RegExp ? `/${s.source}/` : s).join(" ")

export function EventFilterDialog(props: EventFilterDialogProps) {
    const {
        initialFilter,
        filterTree,
        disableEmpty,
        disableFull,
        dialogTitle,
        enableLabel,
        allowByText,
        onClose,
        onChanged,
    } = props
    const { t } = useTranslation()
    const theme = useTheme()

    const { ids, nodes, flip } = useTreeSet(filterTree, initialFilter.ids)
    const [enabled, setEnabled] = useState(initialFilter.enabled)
    const [includes, setIncludes] = useState(initialFilter.includes)
    const [excludes, setExcludes] = useState(initialFilter.excludes)

    const onAccept = () => {
        const filter = NewFilter(enabled, ids, includes, excludes)
        if (IsMatchAll(filter, filterTree) && disableFull) {
            filter.enabled = false
        }
        if (EqualFilter(initialFilter, filter)) {
            onClose()
        } else {
            onChanged(filter)
        }
    }

    const renderTree = (node: Node<EventType>, indent: number): ReactElement =>
        <Fragment key={node.id}>
            <FormControlLabel
                label={t(node.value?.label || node.id)}
                style={{ paddingLeft: `${28 * indent}px` }}
                control={
                    <Checkbox
                        checked={nodes.get(node.id) === Selected.YES}
                        indeterminate={nodes.get(node.id) === Selected.PARTIAL}
                        onChange={() => flip(node.id)}
                        size="small"
                        indeterminateIcon={<CheckBoxOutlineBlank />}
                        disabled={!enabled}
                        style={{ padding: "4px" }}
                    />
                } />
            {node.children?.map(ch => renderTree(ch, indent + 1))}
        </Fragment>

    return (
        <Dialog onClose={onClose} onSubmit={onClose} aria-labelledby="event-filter-dialog-title" maxWidth='xs' open>
            <DialogTitle id="event-filter-dialog-title">
                {dialogTitle || t("dialog.event_filter.title")}
                <Divider variant="fullWidth" flexItem />
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack spacing={0}>
                            <FormControlLabel
                                label={enableLabel || t("dialog.event_filter.enabled")}
                                control={
                                    <Checkbox
                                        checked={enabled}
                                        onChange={e => setEnabled(e.target.checked)}
                                        size="small"
                                    />
                                } />
                            {allowByText &&
                                <>
                                    <Typography pt="0.5em" fontWeight="bold" color={!enabled ? theme.palette.text.disabled : undefined}>
                                        {t("dialog.event_filter.by_text")}
                                    </Typography>
                                    <Tooltip title={t("dialog.words_includes_tooltip")} disableInteractive>
                                        <TextField
                                            size="small"
                                            defaultValue={fromWords(includes)}
                                            disabled={!enabled}
                                            label={t("dialog.words_includes")}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="none"
                                            color="success"
                                            margin="dense"
                                            InputLabelProps={{ shrink: true }}
                                            focused={includes.length > 0}
                                            onChange={e => setIncludes(toWords(e.target.value))}
                                            fullWidth
                                        />
                                    </Tooltip>
                                    <Tooltip title={t("dialog.words_excludes_tooltip")} disableInteractive>
                                        <TextField
                                            size="small"
                                            defaultValue={fromWords(excludes)}
                                            disabled={!enabled}
                                            label={t("dialog.words_excludes")}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            autoCapitalize="none"
                                            color="error"
                                            margin="dense"
                                            InputLabelProps={{ shrink: true }}
                                            focused={excludes.length > 0}
                                            onChange={e => setExcludes(toWords(e.target.value))}
                                            fullWidth
                                        />
                                    </Tooltip>
                                </>
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack>
                            <Typography pt="0.5em" fontWeight="bold" color={!enabled ? theme.palette.text.disabled : undefined}>
                                {t("dialog.event_filter.by_type")}
                            </Typography>
                            {
                                renderTree(filterTree, 1)
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onAccept}
                    variant="contained"
                    disabled={disableEmpty && enabled && ids.size === 0}
                >
                    {t("action.ok")}
                </Button>
                <Button onClick={onClose} variant="outlined">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

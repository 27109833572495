
export interface PanTiltZoom {
    Pan: number
    Tilt: number
    Zoom: number
}

export interface Preset {
    token: string
    Name: string
}

export interface Target {
    ID: string
    Name: string
    IMEI: string
}

export interface Position {
    Longitude: number
    Latitude: number
}

export interface GetPresetsResponse {
    Preset: Preset[]
}

export interface PTZNode {
    HomeSupported: boolean
    MaximumNumberOfPresets: Number
    AuxiliaryCommands: string[]
}

export interface GetNodesResponse {
    PTZNode: PTZNode[]
}

export interface GetImagingOptionsResponse {
    ImagingOptions: ImagingOptions
}

export interface ImagingOptions {
    Focus?: FocusOptions
}

export interface FocusOptions {
    AutoFocusModes: string[]
}

export interface GetImagingSettingsResponse {
    ImagingSettings: ImagingSettings
}

export interface ImagingSettings {
    Focus?: FocusSettings
}

export interface FocusSettings {
    AutoFocusMode: string
}


export interface DeviceInformation {
    DisplayName: string
    Manufacturer: string
    Model: string
    FirmwareVersion: string
    SerialNumber: string
    HardwareId: string
    Status: DeviceStatus
    Error: string
}

export enum DeviceStatus {
    Online = "Online",
    OnvifError = "OnvifError",
    Offline = "Offline",
}

export interface RebootResponse {
    Message: string
}

export interface InterfacesResponse {
    NetworkInterfaces: NetworkInterface[]
}

export interface NetworkInterface {
    IPv4: IPv4NetworkInterface
}

export interface IPv4NetworkInterface {
    Enabled: boolean
    Config: IPv4Configuration
}

export interface IPv4Configuration {
    FromDHCP: PrefixedIPv4Address
    Manual: PrefixedIPv4Address[]
    DHCP: boolean
}

export interface PrefixedIPv4Address {
    Address: string
}
import { Dangerous } from "@mui/icons-material"
import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, useTheme } from "@mui/material"

export interface PageErrorProps {
    message: string
}

export const PageError = (props: PageErrorProps) => {
    const { message } = props

    const theme = useTheme()

    return (
        <Stack alignItems="center" height="100%">
            <Box flexGrow={1} />
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Dangerous fontSize="large" />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant="h5" color={theme.palette.text.secondary}>{message}</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <Box flexGrow={2} />
        </Stack>
    )
}

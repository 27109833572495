import { Theme, createTheme } from "@mui/material";
import { blue, orange, red } from "@mui/material/colors";

declare module '@mui/material/styles' {
    interface PaletteOptions {
        panel?: {
            border?: string,
            background?: string,
        }
    }

    interface Palette {
        panel: {
            border: string,
            background: string,
        }
    }
}

export const darker = (amount: number) => `rgba(0, 0, 0, ${amount})`
export const lighter = (amount: number) => `rgba(255, 255, 255, ${amount})`
export const overlayGradient = (color: string) => `linear-gradient(${color}, ${color})`
export const lighten = (amount: number) => overlayGradient(lighter(amount))
export const darken = (amount: number) => overlayGradient(darker(amount))
export const emphasized = (amount: number, theme: Theme) => {
    let dark = theme.palette.mode === "dark"
    if (amount < 0) {
        dark = !dark
        amount = -amount
    }
    return dark ?
        lighter(amount) :
        darker(amount)
}
export const emphasize = (amount: number, theme: Theme) => {
    let dark = theme.palette.mode === "dark"
    if (amount < 0) {
        dark = !dark
        amount = -amount
    }
    return dark ?
        lighten(amount) :
        darken(amount)
}

const darkPalette = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#0db9f0',
        },
        secondary: {
            main: red[500],
        },
        background: {
            default: '#151515',
            paper: '#2a2a2a',
        },
        panel: {
            border: '#356274',
            background: 'rgba(22,41,49,0.8)',
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundImage: lighten(0.05),
                },
                body: {
                    backgroundImage: darken(0.15),
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundImage: lighten(0.1),
                },
            },
        },
    }
})

const lightPalette = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: blue[600],
        },
        secondary: {
            main: red[600],
        },
        background: {
            default: '#ffffff',
            paper: '#f0f0f0',
        },
        warning: {
            main: orange[600],
        },
        panel: {
            border: blue[600],
            background: 'rgba(230,230,230,0.9)',
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundImage: lighten(0.05),
                },
                body: {
                    backgroundImage: lighten(0.5),
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundImage: lighten(0.05),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundImage: darken(0.2),
                },
            }
        },
    }
})

const createAppTheme = (paletteTheme: Theme) => createTheme(paletteTheme, {
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: paletteTheme.palette.background.paper,
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                size: "small",
                margin: "dense",
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                margin: "dense",
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '8px 8px 8px 8px',
                    margin: '2px 2px 2px 2px',
                },
            },
            defaultProps: {
                size: "large",
            }
        },
    }
})

export const createDarkTheme = () => createAppTheme(darkPalette)
export const createLightTheme = () => createAppTheme(lightPalette)

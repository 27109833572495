import { Check, Login } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { retainConfig } from "../config/urls";
import { ETDialogTitle } from "./ETDialogTitle";

export const RecoveredDialog = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <Dialog aria-labelledby="recovered-dialog-title" maxWidth='xs' open fullWidth>
            <ETDialogTitle />
            <DialogContent>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <Check color="success" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>
                                {t("message.password_reset_link_sent")}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    startIcon={<Login />}
                    onClick={() => navigate(retainConfig({ pathname: "/login" }), { replace: true })}
                    variant="contained"
                    color="primary"
                >
                    {t("action.sign_in")}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

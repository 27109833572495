import { Box, Chip, Grid, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Operation } from "../../api/Authz";
import { SendCommandRequest } from "../../api/Command";
import { Site, Unit } from "../../api/Customer";
import { Event, Labels } from "../../api/Event";
import { useUnitPerimeterPermission, useUnitPermission } from "../../auth/AuthorizerProvider";
import { http, noSnackBar } from "../../backend/request";
import { request } from "../../config/headers";
import { monoEndpointURL } from "../../config/urls";
import { Thing, useAlarmEvent } from "../../services/AlarmEvent";
import { allEvents } from "../../services/EventStream";
import { PartitionInfo } from "./PartitionInfo";
import { SwitchHistoryChart } from "./SwitchHistoryChart";
import { ConnectionStatus } from "../common/ConnectionStatus";
import { ZoneInfo } from "./ZoneInfo";

const regexAlarmThingName = /([a-zA-Z0-9]+)\.alarm_[0-9]+\.((zone|partition)_([0-9]+))/;
const regexAlarmThingNameExpectedSize = 5;

interface Partition {
    id: number;
    displayName: string;
    thingName: string;
    labels: Labels;
    isSystem: boolean;
}

interface Zone {
    id: number;
    displayName: string;
    thingName: string;
    labels: Labels;
    partitionID: number;
    isSystem: boolean;
}

interface State {
    alarm?: boolean;
    alarmInMemory?: boolean;
    violation?: boolean;
    lowBattery?: boolean;
    disconnected?: boolean;
    bypass?: boolean;
    armed?: boolean;
}

export interface AlarmProps {
    site: Site;
    unit: Unit;
}

export function NewAlarm(props: AlarmProps) {
    const { site, unit } = props;
    const [partitions, setPartitions] = useState<Partition[]>([]);
    const [zones, setZones] = useState<Zone[]>([]);
    const [states, setStates] = useState<Map<string, State>>(new Map());
    const { t } = useTranslation();
    const theme = useTheme();

    const allowBypassZone = useUnitPerimeterPermission(Operation.BYPASS_ZONE, unit);
    const allowBypassSystemZone = useUnitPerimeterPermission(Operation.BYPASS_SYSTEM_ZONE, unit);
    const allowAlarmEvents = useUnitPermission(Operation.UI_VIEW_EVENTS, unit);

    const addOrUpdateState = (id: string, newState: Partial<State>) => {
        setStates((prevStates) => {
            const newStates = new Map(prevStates);
            if (newStates.has(id)) {
                const existingState = newStates.get(id);
                const updatedState = { ...existingState, ...newState };
                newStates.set(id, updatedState);
            } else {
                newStates.set(id, newState as State);
            }
            return newStates;
        });
    };

    const upsertSorted = (array: any[], newElement: any): any[] => {
        const index = array.findIndex((element) => element.id >= newElement.id);
        if (index === -1) {
            return [...array, newElement];
        } else if (array[index].id === newElement.id) {
            return [...array.slice(0, index), newElement, ...array.slice(index + 1)];
        } else {
            return [...array.slice(0, index), newElement, ...array.slice(index)];
        }
    };

    const onThing = useCallback(
        (thing: Thing) => {
            const matches = thing.thing.match(regexAlarmThingName);
            if (matches?.length !== regexAlarmThingNameExpectedSize) {
                return;
            }

            const addPartition = (newPartition: Partition) => {
                setPartitions((prev) => upsertSorted(prev, newPartition));
            };

            const addZone = (newZone: Zone) => {
                setZones((prev) => upsertSorted(prev, newZone));
            };

            const unitName = matches[1];
            const objectName = matches[3]; // "partition" or "zone".
            const objectID = Number(matches[4]);

            if (unitName !== unit.ShortName) {
                return;
            }
            const isSystem = thing.labels.get("member_system") === "true";

            switch (objectName) {
                case "partition":
                    addPartition({
                        id: objectID,
                        displayName: thing.displayName || t("alarm.partition_display_name", { id: objectID }),
                        thingName: thing.thing,
                        isSystem: isSystem,
                        labels: thing.labels,
                    });
                    break;
                case "zone":
                    const partitionID = thing.labels.get("partition");
                    if (partitionID === undefined) {
                        break;
                    }
                    addZone({
                        id: objectID,
                        displayName: thing.displayName || t("alarm.zone_display_name", { id: objectID }),
                        thingName: thing.thing,
                        isSystem: isSystem,
                        labels: thing.labels,
                        partitionID: parseInt(partitionID, 10),
                    });
                    break;
                default:
                    return;
            }
        },
        [unit.ShortName, t]
    );

    const onEvent = useCallback((event: Event) => {
        const matches = event.thing.match(regexAlarmThingName);
        if (matches?.length !== regexAlarmThingNameExpectedSize) {
            return;
        }

        let newState: State = {};
        const value = !!event.value;
        switch (event.item) {
            case "alarm":
                newState.alarm = value;
                break;
            case "alarm_in_memory":
                newState.alarmInMemory = value;
                break;
            case "violation":
                newState.violation = value;
                break;
            case "low_battery":
                newState.lowBattery = value;
                break;
            case "disconnected":
                newState.disconnected = value;
                break;
            case "bypass":
                newState.bypass = value;
                break;
            case "armed":
                newState.armed = value;
                break;
            default:
                return;
        }
        const stateID = matches[2]; // Examples: "partition_1" or "zone_2".
        addOrUpdateState(stateID, newState);
    }, []);

    const { online } = useAlarmEvent(site.ID, unit.ShortName, allowAlarmEvents, true, allEvents, onThing, onEvent);

    const sendCommand = useCallback(
        (item: string, value: number, c: any) => {
            const command: SendCommandRequest = {
                command: {
                    thing: c.thingName,
                    item: item,
                    command: {
                        number: value,
                    },
                },
            };
            http(`Sending command`, monoEndpointURL(`sites/${site.ID}/commands`), noSnackBar, {
                method: "POST",
                headers: request.headers,
                body: JSON.stringify(command),
            })
                .then((_) => console.log("Successfuly set item"))
                .catch((e) => console.log(e));
        },
        [site.ID]
    );

    const flipArmSwitch = useCallback(
        (armed: boolean, partition: Partition) => sendCommand("arm", armed ? 0 : 1, partition),
        [sendCommand]
    );

    const flipBypassSwitch = useCallback(
        (bypass: boolean, zone: Zone) => sendCommand("bypass", bypass ? 0 : 1, zone),
        [sendCommand]
    );

    const partitionStates = (id: number): State | undefined => {
        return states.get("partition_" + id);
    };

    const zoneStates = (id: number): State | undefined => {
        return states.get("zone_" + id);
    };

    const allowBypass = (partitionArmed: boolean, zone: Zone): boolean => {
        if (partitionArmed) {
            return false;
        }
        return zone.isSystem ? allowBypassSystemZone : allowBypassZone;
    };

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <ConnectionStatus online={online} />
                <Grid container paddingBottom={5}>
                    <>
                        {partitions && (
                            <Grid item xs={12}>
                                <Stack direction="row" sx={{ alignItems: "center" }} pb={2}>
                                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                        {t("offering.alarm")}
                                    </Typography>
                                </Stack>
                            </Grid>
                        )}
                        {partitions.map((partition) => (
                            <Grid container spacing={1} paddingBottom={5}>
                                <Grid item xs={12}>
                                    <Stack direction="row">
                                        <Typography
                                            variant="h6"
                                            sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                                        >
                                            {partition.displayName}
                                            {partition.isSystem && (
                                                <Box component="span" sx={{ ml: 1 }}>
                                                    <Chip
                                                        variant="outlined"
                                                        color="secondary"
                                                        label={t("alarm.system")}
                                                        size="small"
                                                    />
                                                </Box>
                                            )}
                                        </Typography>
                                    </Stack>
                                </Grid>

                                <Grid key={partition.id} item xs={12} sm={4} lg={2}>
                                    <PartitionInfo
                                        armed={!!partitionStates(partition.id)?.armed}
                                        alarmID={1} // Need to be changed later
                                        partitionID={partition.id}
                                        isSystem={partition.isSystem}
                                        unit={unit}
                                        alarm={!!partitionStates(partition.id)?.alarm}
                                        memoryAlarm={!!partitionStates(partition.id)?.alarmInMemory}
                                        flipSwitch={(armed) => flipArmSwitch(armed, partition)}
                                    />
                                </Grid>
                                <Grid key={partition.id + "-arm-history"} item xs={12} sm={4} lg={5}>
                                    <SwitchHistoryChart
                                        label={t("alarm.arming_history")}
                                        siteID={site.ID}
                                        partitionID={partition.id}
                                        unit={unit}
                                        item={"armed"}
                                        color={theme.palette.primary.main}
                                        currentState={!!partitionStates(partition.id)?.armed}
                                    />
                                </Grid>
                                <Grid key={partition.id + "-alarm-history"} item xs={12} sm={4} lg={5}>
                                    <SwitchHistoryChart
                                        label={t("alarm.alarm_history")}
                                        siteID={site.ID}
                                        partitionID={partition.id}
                                        unit={unit}
                                        item={"alarm"}
                                        color={theme.palette.error.main}
                                        showCount={true}
                                        currentState={!!partitionStates(partition.id)?.alarm}
                                    />
                                </Grid>
                                {/* {zones && (
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                {t("alarm.partition_zones", { partition: partition.displayName })}
                                            </Typography>
                                        </Grid>
                                    )} */}
                                {zones
                                    .filter((z) => z.partitionID === partition.id)
                                    .map((zone) => (
                                        <Grid key={zone.id} item xs={12} sm={6} md={4} lg={3}>
                                            <ZoneInfo
                                                label={zone.displayName}
                                                bypass={!!zoneStates(zone.id)?.bypass}
                                                disabled={!allowBypass(!!partitionStates(partition.id)?.armed, zone)}
                                                alarm={!!zoneStates(zone.id)?.alarm}
                                                memoryAlarm={!!zoneStates(zone.id)?.alarmInMemory}
                                                violation={!!zoneStates(zone.id)?.violation}
                                                lowBattery={!!zoneStates(zone.id)?.lowBattery}
                                                noConnection={!!zoneStates(zone.id)?.disconnected}
                                                flipSwitch={(bypass) => flipBypassSwitch(bypass, zone)}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        ))}
                    </>
                </Grid>
            </Box>
        </Stack>
    );
}

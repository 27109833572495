import { Button, Card, CardActions, CardContent, TextField, Typography } from "@mui/material"
import { DistortAction, FreeRotateAction, LatLng } from "leaflet"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMap } from "react-leaflet"
import { ImageOverlay } from "../api/ImageOverlay"
import { DistortableImageOverlay } from "./DistortableImageOverlay"
import { disablePropagation } from "./Propagation"

export interface EditImageOverlayProps {
    imageOverlay: ImageOverlay
    editable: boolean
    onSave: (imageOverlay: ImageOverlay) => void
    onCancel: () => void
}

export function EditImageOverlay(props: EditImageOverlayProps) {
    const { imageOverlay, editable, onSave, onCancel } = props
    const [corners, setCorners] = useState<LatLng[]>([])
    const [initialized, setInitilized] = useState(false)
    const [name, setName] = useState("")
    const [url, setURL] = useState("")

    const map = useMap()
    const { t } = useTranslation()

    const doCancel = () => {
        resetImage()
        onCancel()
    }

    const doSave = () => {
        if (!name) {
            alert(t("message.required_field"))
            return
        }
        if (!url) {
            alert(t("message.required_field"))
            return
        }
        onSave({
            ID: imageOverlay.ID,
            Name: name,
            URL: url,
            NWLat: corners[0].lat,
            NWLng: corners[0].lng,
            NELat: corners[1].lat,
            NELng: corners[1].lng,
            SWLat: corners[2].lat,
            SWLng: corners[2].lng,
            SELat: corners[3].lat,
            SELng: corners[3].lng,
        })
    }

    const resetImage = useCallback(() => {
        if (imageOverlay.ID === -1) {
            const bounds = map.getBounds()
            const padWidth = (bounds.getWest() - bounds.getEast()) / 8
            const padHeight = (bounds.getNorth() - bounds.getSouth()) / 8
            const n = bounds.getNorth() - padHeight
            const s = bounds.getSouth() + padHeight
            const w = bounds.getWest() - padWidth
            const e = bounds.getEast() + padWidth
            setCorners([
                new LatLng(n, w),
                new LatLng(n, e),
                new LatLng(s, w),
                new LatLng(s, e),
            ])
        } else {
            setCorners([
                new LatLng(imageOverlay.NWLat, imageOverlay.NWLng),
                new LatLng(imageOverlay.NELat, imageOverlay.NELng),
                new LatLng(imageOverlay.SWLat, imageOverlay.SWLng),
                new LatLng(imageOverlay.SELat, imageOverlay.SELng),
            ])
        }
        setName(imageOverlay.Name)
        setURL(imageOverlay.URL)
        setInitilized(true)
    }, [imageOverlay, map])

    useEffect(() => {
        resetImage()
    }, [imageOverlay, resetImage])

    if (!initialized) {
        return null
    }

    return <>
        <DistortableImageOverlay
            url={url}
            actions={[DistortAction, FreeRotateAction]}
            mode="distort"
            editable={editable}
            selected={editable}
            suppressToolbar={false}
            corners={corners}
            crossOrigin={false}
            onUpdate={setCorners}
        />
        {editable &&
            <>
                <div className="leaflet-bottom leaflet-left" ref={disablePropagation}>
                    <div className="leaflet-bar leaflet-control">
                        <form noValidate autoComplete="off">
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        {t(imageOverlay.ID === -1 ? "action.new_overlay" : "action.edit_overlay")}
                                    </Typography>
                                    <TextField
                                        required
                                        id="name"
                                        label={t("field.name")}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                        onBlur={(e) => { setName(e.target.value) }}
                                    />
                                    <br />
                                    <TextField
                                        required
                                        id="url"
                                        label={t("field.url")}
                                        variant="outlined"
                                        size="small"
                                        value={url}
                                        onChange={(e) => { setURL(e.target.value) }}
                                        onBlur={(e) => { setURL(e.target.value) }}
                                        fullWidth
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" onClick={doSave}>{t("action.save")}</Button>
                                    <Button variant="contained" onClick={doCancel}>{t("action.cancel")}</Button>
                                </CardActions>
                            </Card>
                        </form>
                    </div>
                </div>
            </>
        }
    </>
}

import { DialogTitle, Divider, Stack } from "@mui/material";
import useConfig from "../config/Provider";
import { staticURL } from "../config/urls";

export const ETDialogTitle = () => {
    const { mode } = useConfig()

    return (
        <DialogTitle>
            <Stack alignItems="start" width="100%">
                <img
                    alt="EyeTowers Logo"
                    style={{ maxHeight: 36 }}
                    src={staticURL(mode === "dark" ? "logo.svg" : "logo-light.svg")}
                />
                <Divider variant="fullWidth" flexItem />
            </Stack>
        </DialogTitle>
    )
}

import { HelpOutline, LocationOn, NotificationImportantOutlined, NotificationsOffOutlined, NotificationsOutlined, Videocam } from "@mui/icons-material";
import { Box, Chip, Grid, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ItemState, SiteAlarmState, UnitAlarmState } from "../api/Alarm";
import { Operation } from "../api/Authz";
import { Offering, Site, Unit } from "../api/Customer";
import { useAnyUnitsPerimeterPermission, useAnyUnitsPermission } from "../auth/AuthorizerProvider";
import { http } from "../backend/request";
import { request } from "../config/headers";
import { endpointURL, retainConfig } from "../config/urls";
import { emphasize } from "../theme/Theme";
import { UnitInfo } from "./UnitInfo";

const unknownUnitAlarmState: UnitAlarmState = {
    Armed: ItemState.Unknown,
    UnitID: 0,
    PowerSaving: ItemState.Unknown,
}

export interface SiteInfoProps {
    site: Site
    units: Unit[]
    offerings: Offering[]
}

export function SiteInfo(props: SiteInfoProps) {
    const { site, units, offerings } = props

    const [siteArmState, setSiteArmState] = React.useState<SiteAlarmState | null>()
    const [version, setVersion] = React.useState(0)

    const confirm = useConfirm()
    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    const allowSomeGetPartition = useAnyUnitsPerimeterPermission(Operation.GET_PARTITION, units)
    const allowSomeArmPartition = useAnyUnitsPerimeterPermission(Operation.ARM_PARTITION, units)
    const allowSomeDisarmPartition = useAnyUnitsPerimeterPermission(Operation.DISARM_PARTITION, units)
    const allowSiteVideo = useAnyUnitsPermission(Operation.UI_VIEW_VIDEO, units)

    const getAlarmStateColor = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return "secondary"
            case ItemState.Off:
                return "success"
            case ItemState.Partial:
                return "warning"
            case ItemState.Unknown:
                return "default"
        }
    }

    const getAlarmStateText = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return t("alarm.armed")
            case ItemState.Off:
                return t("alarm.disarmed")
            case ItemState.Partial:
                return t("alarm.partial")
            case ItemState.Unknown:
                return t("alarm.unknown")
        }
    }

    const getAlarmStateIcon = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return <NotificationsOutlined />
            case ItemState.Off:
                return <NotificationsOffOutlined />
            case ItemState.Partial:
                return <NotificationImportantOutlined />
            case ItemState.Unknown:
                return <HelpOutline />
        }
    }

    const getAlarmStateChip = (
        key: string,
        armTooltip: string,
        disarmTooltip: string,
        state: ItemState,
        onClick: (state: ItemState) => void,
    ) => {
        return (
            <Tooltip title={t(state === ItemState.On || state === ItemState.Partial ? disarmTooltip : armTooltip)} disableInteractive>
                <Chip
                    key={key}
                    icon={getAlarmStateIcon(state)}
                    label={getAlarmStateText(state).toLowerCase()}
                    color={getAlarmStateColor(state)}
                    onClick={() => onClick(state)}
                    size="small"
                />
            </Tooltip>
        )
    }

    useEffect(() => {
        if (allowSomeGetPartition) {
            http<SiteAlarmState>(`Loading site (${site.ID}) arm state`, endpointURL(`alarm/sites/${site.ID}/armed`), undefined, request)
                .then(setSiteArmState, () => { })
        } else {
            setSiteArmState(null)
        }
    }, [site, allowSomeGetPartition, version])

    const setUnitAlarmState = (unit: Unit, state: ItemState) => {
        confirm({
            title: t("confirm.generic"),
            description: t(ItemState.On ? "confirm.alarm_unit_arm" : "confirm.alarm_unit_disarm", { "unit": unit.ShortName }),
            confirmationText: t(state === ItemState.On ? "action.arm" : "action.disarm"),
            cancellationText: t("action.cancel"),
            confirmationButtonProps: {
                color: state === ItemState.On ? 'secondary' : 'success',
            },
        })
            .then(() => {
                http<void>(`Setting unit ${unit.ShortName} arm state to "${state}"`, endpointURL(`alarm/units/${unit.ID}/armed`), snackbar, {
                    method: 'PUT',
                    headers: request.headers,
                    body: JSON.stringify(state),
                })
                    .finally(() => setVersion(v => v + 1))
                    .catch(e => console.log(e))
            })
            .catch(() => { })
    }

    const disableUnitPowerSave = (unit: Unit) => {
        confirm({
            title: t("confirm.power_save_unit_disable_title", { "unit": unit.ShortName }),
            description: t("confirm.power_save_unit_disable_description"),
            confirmationText: t("action.disable"),
            cancellationText: t("action.cancel"),
            confirmationButtonProps: {
                color: 'success',
            },
        })
            .then(() => {
                http<void>(`Disabling unit ${unit.ShortName} power save`, endpointURL(`units/${unit.ID}/state/power-save`), snackbar, {
                    method: 'PUT',
                    headers: request.headers,
                    body: JSON.stringify(ItemState.Off),
                })
                    .finally(() => setVersion(v => v + 1))
                    .catch(e => console.log(e))
            })
            .catch(() => { })
    }

    const setSiteAlarmState = (state: ItemState) => {
        confirm({
            title: t("confirm.generic"),
            description: t(ItemState.On ? "confirm.alarm_site_arm" : "confirm.alarm_site_disarm", { "site": site.DisplayName }),
            confirmationText: t(state === ItemState.On ? "action.arm" : "action.disarm"),
            cancellationText: t("action.cancel"),
            confirmationButtonProps: {
                color: state === ItemState.On ? 'secondary' : 'success',
            },
        })
            .then(() => {
                http<void>(`Setting site ${site.DisplayName} arm state to "${state}"`, endpointURL(`alarm/sites/${site.ID}/armed`), snackbar, {
                    method: 'PUT',
                    headers: request.headers,
                    body: JSON.stringify(state),
                })
                    .finally(() => setVersion(v => v + 1))
                    .catch(e => console.log(e))
            })
            .catch(() => { })
    }
    const enableSiteAlarm = () => setSiteAlarmState(ItemState.On)
    const disableSiteAlarm = () => setSiteAlarmState(ItemState.Off)

    const OnSiteAlarmClicked = (state: ItemState) => {
        if (state === ItemState.On || state === ItemState.Partial) {
            if (!allowSomeDisarmPartition) {
                snackbar.enqueueSnackbar(t("message.forbidden_site_disarm", { "site": site.DisplayName }), { variant: 'warning' })
            } else {
                disableSiteAlarm()
            }
        } else {
            if (!allowSomeArmPartition) {
                snackbar.enqueueSnackbar(t("message.forbidden_site_arm", { "site": site.DisplayName }), { variant: 'warning' })
            } else {
                enableSiteAlarm()
            }
        }
    }

    const renderSiteArmedState = () => {
        if (!allowSomeGetPartition) {
            return <></>
        }
        return getAlarmStateChip(
            `site-alarm-state-${site.ID}`,
            "action.site_arm_tooltip",
            "action.site_disarm_tooltip",
            siteArmState?.Armed || ItemState.Unknown,
            state => OnSiteAlarmClicked(state)
        )
    }

    const name = (isSmall ? "" : t("site") + " ") + site.DisplayName

    if (!units.length) {
        return (
            <Typography variant="body1" p={2} fontStyle="italic">{t("customer.no_unit")}</Typography>
        )
    }

    return (
        <>
            <Stack key={"site-header-" + site.ID} id={`site-${site.ID}`} direction="row" spacing={0.5} alignItems="center" px={2}
                sx={{
                    borderColor: theme.palette.divider,
                    borderWidth: 1,
                    borderTopStyle: "solid",
                    borderBottomStyle: "solid",
                    backgroundImage: emphasize(0.05, theme),
                }}>
                <LocationOn fontSize="small" htmlColor={theme.palette.text.secondary} />
                <Tooltip title={`${t("site")} ${site.DisplayName}`} disableInteractive>
                    <Typography variant="body1" noWrap flexShrink={1}>
                        {name}
                    </Typography>
                </Tooltip>
                {renderSiteArmedState()}
                <Box flexGrow={1} />
                <Stack direction="row">
                    <Tooltip title={t("action.view_site_video_tooltip")} disableInteractive>
                        <span>
                            <IconButton
                                color="primary"
                                to={retainConfig({ pathname: `./video/sites/${site.ID}` })}
                                component={Link}
                                disabled={!allowSiteVideo}
                            >
                                <Videocam />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack>
            </Stack >
            <Grid key={"site-contents-" + site.ID} container spacing={1} wrap="wrap" sx={{ px: 2, pt: 1, pb: 2, overflow: "hidden" }}>
                {units.map(unit => (
                    <Grid key={"unit-placeholder-" + unit.ID} item xs={12} sm={6} md={4} xl={3} >
                        <UnitInfo
                            unit={unit}
                            alarmState={siteArmState?.Units.find(u => u.UnitID === unit.ID) || unknownUnitAlarmState}
                            offerings={offerings}
                            onDisableUnitPowerSave={() => disableUnitPowerSave(unit)}
                            onSetUnitAlarmState={(state: ItemState) => setUnitAlarmState(unit, state)}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

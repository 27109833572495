
import { Download, FilterAlt, ManageSearch } from "@mui/icons-material"
import { Stack, Typography, useTheme } from "@mui/material"
import { memo, useEffect, useState } from "react"
import CsvDownloader from 'react-csv-downloader'
import { Datas } from "react-csv-downloader/dist/esm/lib/csv"
import { useTranslation } from "react-i18next"
import { Site, Unit } from "../../api/Customer"
import { CurrentTime, Playback } from "../../api/Video"
import { EqualLocalDay, Second, TimeRange, ToFilenameDateTime, ToLocalDate, ToShortLocalDateTime, ToShortLocalTime, setIntervalAndRun } from "../../config/time"
import { CameraButton } from "../video/CameraButton"

export type EventListTitleProps = {
    range: TimeRange
    site: Site
    units: Unit[]
    filtered: boolean
    count: number
    playback: Playback
    onEventsDatas: () => Datas
    onFilterEvents: () => void
}

export const EventListTitle = memo((props: EventListTitleProps) => {
    const { site, units, range, filtered, count, playback, onEventsDatas, onFilterEvents } = props

    const theme = useTheme()
    const { t } = useTranslation()

    const [currentTime, setCurrentTime] = useState(CurrentTime(playback))

    useEffect(() => {
        const interval = setIntervalAndRun(() => setCurrentTime(CurrentTime(playback)), Second)
        return () => clearInterval(interval)
    }, [playback])

    const renderRange = () => {
        if (range.start === 0) {
            return null
        }
        const start = new Date(range.start)
        const end = new Date(range.end || currentTime)
        if (EqualLocalDay(start, end)) {
            if (EqualLocalDay(start, new Date())) {
                return `${ToShortLocalTime(start)} - ${ToShortLocalTime(end)}`
            }
            return `${ToLocalDate(start)}, ${ToShortLocalTime(start)} - ${ToShortLocalTime(end)}`
        }
        return `${ToShortLocalDateTime(start)} - ${ToShortLocalDateTime(end)}`
    }

    return (
        <Stack
            direction="row"
            width="100%"
            alignItems="center"
            overflow="hidden"
            p={0.5}
            spacing={0.5}
            sx={{
                backgroundColor: theme.palette.panel.background,
                boxShadow: "0px 3px 3px -3px black",
                zIndex: "1",
            }}
        >
            <ManageSearch fontSize="small" color="primary" />
            <Typography
                fontWeight="bold"
                fontSize="0.9em"
                textOverflow="ellipsis"
                noWrap
                flexShrink={0}
            >
                {t("events")}
                {count > 0 && ` (${count})`}
            </Typography>
            <Typography
                fontSize="0.8em"
                textOverflow="ellipsis"
                noWrap
                flexShrink={1}
                flexGrow={1}
                color={theme.palette.text.secondary}
                pt={0.5}
            >
                {renderRange()}
            </Typography>
            <CameraButton tooltip="dialog.event_filter.tooltip" toggled={filtered} toggledColor={theme.palette.warning.main} icon={<FilterAlt />} onClick={onFilterEvents} />
            <CsvDownloader filename={`${t("events")}-${units.length > 1 ? site.DisplayName : units[0].ShortName}-${ToFilenameDateTime(range.start)}-${ToFilenameDateTime(range.end || Date.now())}.csv`} datas={onEventsDatas}>
                <CameraButton tooltip="dialog.event_filter.download_tooltip" icon={<Download />} onClick={() => { }} />
            </CsvDownloader>
        </Stack>
    )
})

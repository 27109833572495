import { TFunction } from "i18next";
import { User } from "../api/User";

export const formatUserLong = (t: TFunction, user?: User) =>
    user ?
        `${user.Name} ${user.Surname} (${user.Email})` :
        t("field.unknown")

export const formatUserShort = (t: TFunction, user?: User) =>
    user ?
        `${user.Name} ${user.Surname}` :
        t("field.unknown")

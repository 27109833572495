import { useCallback, useMemo, useReducer } from "react";

export const noAlarms = new Set<string>()
export type Flags = Set<string>

type State<K> = {
    map: Map<K, Flags>,
}

type Action<K> =
    | { type: "Flag", key: K, flag: string }
    | { type: "Unflag", key: K, flag: string }
    ;

function reducer<K>(state: State<K>, action: Action<K>) {
    switch (action.type) {
        case "Flag": {
            const flags = new Set(state.map.get(action.key))
            if (flags.has(action.flag)) {
                return state
            }
            flags.add(action.flag)
            const r = new Map<K, Flags>(state.map)
            r.set(action.key, flags)
            return {
                ...state,
                map: r,
            }
        }
        case "Unflag": {
            const flags = new Set(state.map.get(action.key))
            if (!flags.delete(action.flag)) {
                return state
            }
            const r = new Map<K, Flags>(state.map)
            if (flags.size === 0) {
                r.delete(action.key)
            } else {
                r.set(action.key, flags)
            }
            return {
                ...state,
                map: r,
            }
        }
    }
}

export function useFlags<K>() {

    const [state, dispatch] = useReducer(reducer, { map: new Map<K, Flags>() })

    const setFlag = useCallback((key: K, flag: string) => dispatch({ type: "Flag", key, flag }), [])
    const clearFlag = useCallback((key: K, flag: string) => dispatch({ type: "Unflag", key, flag }), [])
    const getFlags = useCallback((key: K) => new Set<string>(state.map.get(key)), [state.map])
    const hasFlag = useCallback((key: K, flag: string) => getFlags(key).has(flag), [getFlags])
    const noFlags = useMemo(() => state.map.size === 0, [state.map])

    return { hasFlag, getFlags, noFlags, setFlag, clearFlag }
}

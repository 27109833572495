import { Button, Dialog, DialogActions, DialogTitle, Divider, List, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { asCameraSubject, Operation } from "../../../api/Authz";
import { Unit } from "../../../api/Customer";
import { Camera } from "../api/Camera";
import useAuthorizer from "../../../auth/AuthorizerProvider";

export interface PickCameraDialogProps {
    title: string
    unit: Unit
    cameras: Camera[],
    onPicked: (camera: Camera) => void
    onCancel: () => void
}

export function PickCameraDialog(props: PickCameraDialogProps) {
    const { title, unit, cameras, onCancel, onPicked } = props

    const { t } = useTranslation()
    const { allowOperation } = useAuthorizer()

    const renderCamera = (camera: Camera) => {
        const allowedMove = allowOperation(Operation.MOVE_CAMERA, asCameraSubject(unit, camera.ID))
        return (
            <ListItemButton
                key={camera.ID}
                disabled={!allowedMove}
                onClick={() => {
                    if (allowedMove) {
                        onPicked(camera)
                    }
                }}>
                <ListItemText primary={camera.Name} />
            </ListItemButton>
        )
    }

    return (
        <Dialog onClose={onCancel} aria-labelledby="pick-camera-dialog-title" open={true}>
            <DialogTitle id="pick-camera-dialog-title">{title}</DialogTitle>
            <Divider />
            <List dense>
                {cameras.map(renderCamera)}
            </List>
            <Divider />
            <DialogActions>
                <Button onClick={onCancel} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './auth/AuthProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

// Setup internationalization.
import { ThemedApp } from './ThemedApp';
import { AuthorizerProvider } from './auth/AuthorizerProvider';
import { ConfigProvider } from './config/Provider';
import "./i18n";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter basename='/portal/'>
    <React.StrictMode>
      <ConfigProvider>
        <Provider store={store}>
          <AuthProvider>
            <AuthorizerProvider>
              <ThemedApp />
            </AuthorizerProvider>
          </AuthProvider>
        </Provider>
      </ConfigProvider>
    </React.StrictMode>
  </BrowserRouter >
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { CircularProgress, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RTKMutation, RTKQuery } from "../../../api/portal/hooks/RTK";
import { Milli } from "../../../config/time";
import { useCooldown } from "../../../hooks/cooldown";

export interface LoadingDialogProps {
    queries?: RTKQuery[]
    mutations?: RTKMutation[]
}

export const LoadingDialog = (props: LoadingDialogProps) => {
    const { queries, mutations } = props

    const { cooling, trigger } = useCooldown(500 * Milli)
    const { t } = useTranslation()

    const activeQuery = useMemo(() => !!queries?.some(q => q.isFetching), [queries])
    const activeMutation = useMemo(() => !!mutations?.some(m => m.isLoading), [mutations])
    const active = useMemo(() => activeQuery || activeMutation, [activeQuery, activeMutation])

    useEffect(() => {
        if (active) {
            trigger()
        }
    }, [active, trigger])

    return (
        <Dialog open={active && !cooling}>
            <DialogContent sx={{ py: "2rem", px: "4rem" }}>
                <Stack direction="row" spacing={3} alignItems="center">
                    <CircularProgress thickness={5} size={24} />
                    <Typography>
                        {activeQuery ?
                            t("message.loading") :
                            t("message.saving")
                        }
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

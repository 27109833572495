import { AccountCircle, Autorenew, ExpandLess, ExpandMore, Logout, Search } from "@mui/icons-material";
import { Chip, Collapse, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Customer, Unit } from "../api/Customer";
import { User } from "../api/User";
import useAuth from "../auth/AuthProvider";
import { http, snackbarSuccess } from "../backend/request";
import { request } from "../config/headers";
import { endpointURL, retainConfig } from "../config/urls";
import { formatUserLong } from "../widgets/format";
import { UnitIcon } from "./UnitIcon";

export interface AccountMenuProps {
    actor: User
    customers: Customer[]
}

const splitShortName = (u: Unit) => {
    const shortNameRE = /^([a-zA-Z]*)([0-9]*).*$/
    const parts = shortNameRE.exec(u.ShortName)
    if (parts === null || parts.length !== 3) {
        return {
            code: "et",
            number: 0,
        }
    }
    return {
        code: parts[1],
        number: parseInt(parts[2]),
    }
}

const sortUnits = (u1: Unit, u2: Unit) => {
    const s1 = splitShortName(u1)
    const s2 = splitShortName(u2)

    if (s1.code > s2.code || (s1.code === s2.code && s1.number > s2.number)) {
        return 1
    }
    if (s1.code < s2.code || (s1.code === s2.code && s1.number < s2.number)) {
        return -1
    }
    return 0
}

export function AccountMenu(props: AccountMenuProps) {
    const { actor, customers } = props
    const { t, i18n } = useTranslation()
    const auth = useAuth()
    const navigate = useNavigate()
    const theme = useTheme()

    const [searching, setSearching] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
    const menuOpen = Boolean(anchorEl)

    const confirm = useConfirm()
    const snackbar = useSnackbar()

    const units = useMemo(() => customers.flatMap(c => c.Units).sort(sortUnits), [customers])

    const onOpen = (event: React.MouseEvent) => setAnchorEl(event.currentTarget as Element)
    const onClose = () => setAnchorEl(null)

    const onResetPassword = () => {
        const message = t("confirm.reset_password", { user: formatUserLong(t, actor) })
        confirm({
            title: t("confirm.generic"),
            description: message,
            confirmationText: t("action.reset"),
            confirmationButtonProps: {
                color: 'secondary',
            },
        })
            .then(() => {
                http<any>(message, endpointURL(`users/${actor.ID}/password`), snackbar, {
                    method: 'DELETE',
                    headers: request.headers,
                })
                    .then(() => snackbarSuccess(t("action.reset_password_done", { email: actor.Email }), snackbar))
                    .catch(e => console.log(e))
            })
            .catch(() => { })
    }

    const renderLanguage = (label: string, code: string) => {
        // We offer some leeway  since the default system language is often more specific, e.g.,
        // "en-GB" not "en".
        const selected = i18n.language === code || i18n.language.startsWith(code + "-")
        return (
            <Chip
                size="small"
                label={label}
                sx={{
                    fontWeight: "bold",
                    fontSize: "0.7em",
                    px: ".3em",
                }}
                color={selected ? "primary" : "default"}
                clickable={!selected} onClick={() => {
                    onClose()
                    i18n.changeLanguage(code)
                }}
            />
        )
    }

    return (
        <Stack direction="row" alignItems="center">
            <Tooltip title={t("action.show_user_account_menu")} disableInteractive>
                <span>
                    <IconButton size="small" sx={{ padding: "4px" }} onClick={onOpen} color="primary" aria-label="settings" component="span">
                        <AccountCircle />
                    </IconButton>
                </span>
            </Tooltip>
            <Typography sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                {actor.Name}&nbsp;{actor.Surname}
            </Typography>
            <Menu
                id="account-menu"
                keepMounted
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={onClose}
            >
                <MenuItem onClick={onClose} disabled>
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText>
                        {actor.Name}&nbsp;{actor.Surname}
                    </ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => setSearching(x => !x)}>
                    <ListItemIcon>
                        <Search />
                    </ListItemIcon>
                    <ListItemText>
                        {t("action.goto_unit")}
                    </ListItemText>
                    {searching ? <ExpandLess /> : <ExpandMore />}
                </MenuItem>
                <Collapse in={searching} timeout="auto" unmountOnExit>
                    {units.map(u =>
                        <MenuItem
                            key={u.ShortName}
                            sx={{ pl: "2em", backgroundColor: theme.palette.background.paper }}
                            onClick={() => {
                                setSearching(false)
                                onClose()
                                navigate(retainConfig({
                                    pathname: "/",
                                    hash: u.ShortName,
                                }))
                            }}
                        >
                            <ListItemIcon>
                                <UnitIcon unit={u} />
                            </ListItemIcon>
                            <ListItemText>
                                {u.ShortName}
                            </ListItemText>
                        </MenuItem>
                    )}
                </Collapse>
                <MenuItem onClick={onResetPassword}>
                    <ListItemIcon>
                        <Autorenew />
                    </ListItemIcon>
                    <ListItemText>
                        {t("action.reset_password")}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => auth.logout(navigate)} disabled={!auth.actor}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText>
                        {t("action.logout")}
                    </ListItemText>
                </MenuItem>
                <Divider />
                <Stack direction="row" spacing={0.5} justifyContent="center">
                    {renderLanguage("EN", "en")}
                    {renderLanguage("CZ", "cs")}
                </Stack>
            </Menu>
        </Stack>
    )
}

import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { Site, Unit } from "../api/Customer"
import { Live, SetDelay } from "../api/Video"
import { Paging, SanitizePinned } from "../config/paging"
import { UnitCameras } from "../features/video/UnitCameras"

export interface VideoProps {
    site: Site
    units: Unit[]
}

const asNumber = (key: string, defaultValue: number, params: URLSearchParams) => {
    const value = params.get(key)
    if (!value) {
        return defaultValue
    }
    return +value
}

const asArray = (key: string, params: URLSearchParams): number[] => {
    const value = params.getAll(key)
    if (value.length === 0) {
        return []
    }
    return value.map(v => (+v || 0) - 1)
}

export const Video = (props: VideoProps) => {
    const { site, units } = props

    const [searchParams] = useSearchParams()

    const initialPlayback = useMemo(() => {
        const encoded = searchParams.get("timestamp")
        if (!encoded) {
            return Live()
        }
        return SetDelay(Live(), Date.now() - +encoded)
    }, [searchParams])

    const initialPaging = useMemo((): Paging => {
        const size = asNumber("size", 0, searchParams)
        const page = asNumber("page", 0, searchParams)
        const count = units.flatMap(u => u.UnitConfig.Cameras).length || 0
        const pinned = SanitizePinned(asArray("lock", searchParams), count)
        return {
            Page: page,
            PageSize: size,
            Pages: 0,
            Pinned: pinned,
            Count: count,
        }
    }, [searchParams, units])

    return (
        <UnitCameras
            site={site}
            units={units}
            initialPlayback={initialPlayback}
            initialPaging={initialPaging}
        />
    )
}

import { Box, Stack } from "@mui/material"
import React from "react"
import { Customer, Offering } from "../api/Customer"
import { User } from "../api/User"
import { CustomerInfo } from "../components/CustomerInfo"
import { IdentifierManagementDialog } from "../components/IdentifierManagementDialog"
import { UnitAppBar } from "../components/UnitAppBar"
import { UserManagementDialog } from "../components/UserManagementDialog"
import { minPageWidth } from "../config/sizing"

export interface CustomersProps {
    actor: User
    customers: Customer[]
    offerings: Offering[]
}

export function Customers(props: CustomersProps) {
    const { actor, customers, offerings } = props

    const [customerUserManagement, setCustomerUserManagement] = React.useState(0)
    const [identifierManagement, setIdentifierManagement] = React.useState(0)

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", minWidth: minPageWidth, flexDirection: "column" }}>
            <UnitAppBar actor={actor} customers={customers} />
            <Stack sx={{ py: 2, px: 1 }}>
                {
                    customers.map((customer) =>
                        <CustomerInfo
                            key={`customer-info-${customer.ID}`}
                            customer={customer}
                            offerings={offerings}
                            onManageUsers={() => setCustomerUserManagement(customer.ID)}
                            onManageIdentifiers={() => setIdentifierManagement(customer.ID)}
                        />
                    )
                }
            </Stack>
            <UserManagementDialog
                actor={actor}
                customerID={customerUserManagement}
                customers={customers}
                onClose={() => setCustomerUserManagement(0)} />
            <IdentifierManagementDialog
                customerID={identifierManagement}
                onClose={() => setIdentifierManagement(0)} />
        </Box>
    )
}

import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { BaseRole, CommonRole, Role, RoleType, SubjectType } from "../../Authz"
import { useGetBaseRolesQuery } from "../BaseRoles"
import { useGetCommonRolesQuery } from "../CommonRoles"
import { RTKQuery } from "./RTK"

export const roleKey = (r: Role) => `${r.type}-${r.id}`

export interface Roles {
    roleName: (role: Role) => string
    roleDescription: (role: Role) => string
    baseRoles: Map<string, BaseRole>
    commonRoles: Map<string, CommonRole>
    customerRoles: Role[]
    baseRoleImpliedBy: (role: Role, selected?: Role[]) => Role[]
    baseRoleImplies: (role: Role) => BaseRole[]
    roleQueries: RTKQuery[]
    refetch: () => void
}

export const useRoles = () => {
    const baseRolesQuery = useGetBaseRolesQuery()
    const commonRolesQuery = useGetCommonRolesQuery()
    const refetchBaseRoles = baseRolesQuery.refetch
    const refetchCommonRoles = commonRolesQuery.refetch
    const baseRoles = useMemo(() => new Map(baseRolesQuery.data?.map(br => [br.id, br])), [baseRolesQuery.data])
    const commonRoles = useMemo(() => new Map(commonRolesQuery.data?.map(br => [br.id, br])), [commonRolesQuery.data])

    const { t } = useTranslation()

    const roleName = useCallback(
        (r: Role) => {
            switch (r.type) {
                case RoleType.BASE_ROLE:
                    const br = baseRoles.get(r.id)
                    if (!br)
                        break
                    return t(`role.base.${br.name.replace(":", "_")}`)
                case RoleType.COMMON_ROLE:
                    const cr = commonRoles.get(r.id)
                    if (!cr)
                        break
                    return t(`role.common.${cr.name}`)
            }
            return t("field.unknown")
        },
        [t, baseRoles, commonRoles]
    )

    const roleDescription = useCallback(
        (r: Role) => {
            switch (r.type) {
                case RoleType.BASE_ROLE:
                    const br = baseRoles.get(r.id)
                    if (!br)
                        break
                    return t(`role.base.${br.name.replace(":", "_")}_description`)
                case RoleType.COMMON_ROLE:
                    const cr = commonRoles.get(r.id)
                    if (!cr)
                        break
                    return t(`role.common.${cr.name}_description`)
            }
            return t("field.unknown")
        },
        [t, baseRoles, commonRoles]
    )

    const customerRoles = useMemo(() =>
        (
            commonRolesQuery.data?.filter(
                r => r.level === SubjectType.CUSTOMER
            ).map(
                r => ({ id: r.id, type: RoleType.COMMON_ROLE })
            ) || []
        ).concat(
            baseRolesQuery.data?.filter(
                r => r.level === SubjectType.CUSTOMER
            ).map(
                r => ({ id: r.id, type: RoleType.BASE_ROLE })
            ) || []
        ),
        [baseRolesQuery.data, commonRolesQuery.data]
    )

    const baseRoleOperations = useMemo(() =>
        new Map(baseRolesQuery.data?.map(br => [br.id, new Set(br.operations)])),
        [baseRolesQuery.data]
    )

    const superBaseRoles = useMemo(() =>
        new Map(baseRolesQuery.data?.map(br => [
            br.id,
            new Set(
                baseRolesQuery.data
                    ?.filter(
                        or => or.id !== br.id && br.operations?.every(
                            o => baseRoleOperations.get(or.id)?.has(o)
                        )
                    )?.map(
                        or => or.id
                    )
            ),
        ])),
        [baseRolesQuery.data, baseRoleOperations]
    )

    const subBaseRoles = useMemo(() =>
        new Map(baseRolesQuery.data?.map(br => [
            br.id,
            new Set(
                baseRolesQuery.data
                    ?.filter(
                        or => or.id !== br.id && or.operations?.every(
                            o => baseRoleOperations.get(br.id)?.has(o)
                        )
                    )?.map(
                        or => or.id
                    )
            ),
        ])),
        [baseRolesQuery.data, baseRoleOperations]
    )

    const superCommonRoles = useMemo(() =>
        new Map(baseRolesQuery.data?.map(br => [
            br.id,
            new Set(commonRolesQuery.data
                ?.filter(
                    cr => !!cr.roles.find(
                        or => or.type === RoleType.BASE_ROLE && (
                            or.id === br.id ||
                            superBaseRoles.get(br.id)?.has(or.id)
                        )
                    )
                ).map(
                    cr => cr.id
                )
            ),
        ])),
        [baseRolesQuery.data, commonRolesQuery.data, superBaseRoles]
    )

    const baseRoleImpliedBy = useCallback((role: Role, selected?: Role[]) => {
        if (role.type !== RoleType.BASE_ROLE) {
            return []
        }
        const brImplications = superBaseRoles.get(role.id)
        const crImplications = superCommonRoles.get(role.id)
        return selected?.filter(r =>
            (r.type === RoleType.BASE_ROLE && brImplications?.has(r.id)) ||
            (r.type === RoleType.COMMON_ROLE && crImplications?.has(r.id))) || []
    },
        [superBaseRoles, superCommonRoles]
    )

    const baseRoleImplies = useCallback((role: Role) => {
        if (role.type !== RoleType.BASE_ROLE) {
            return []
        }
        const subRoles = subBaseRoles.get(role.id)
        return baseRolesQuery.data?.filter(br => subRoles?.has(br.id)) || []
    },
        [subBaseRoles, baseRolesQuery.data]
    )

    const refetch = useCallback(
        () => {
            refetchBaseRoles()
            refetchCommonRoles()
        },
        [refetchBaseRoles, refetchCommonRoles]
    )

    const result = useMemo(
        (): Roles => ({
            roleName,
            roleDescription,
            baseRoles,
            commonRoles,
            customerRoles,
            baseRoleImpliedBy,
            baseRoleImplies,
            roleQueries: [baseRolesQuery, commonRolesQuery],
            refetch,
        }),
        [
            roleName, roleDescription, baseRoles, commonRoles, customerRoles,
            baseRoleImpliedBy, baseRoleImplies, baseRolesQuery, commonRolesQuery, refetch,
        ])

    return result
}

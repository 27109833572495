import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { formatUserLong } from "../../../widgets/format"
import { Actor, ActorType } from "../../Authz"
import { User } from "../../User"
import { useGetCustomerGroupsQuery } from "../Groups"
import { useGetCustomerUsersQuery } from "../Users"
import { RTKQuery } from "./RTK"

export const actorKey = (a: Actor) => `${a.type}-${a.id}`

export interface CustomerActors {
    actorName: (actor: Actor) => string
    actors: Actor[]
    asUser: (a: Actor) => User | undefined
    actorQueries: RTKQuery[]
    refetch: () => void
}

export const useCustomerActors = (customerID: number) => {
    const usersQuery = useGetCustomerUsersQuery(customerID)
    const groupsQuery = useGetCustomerGroupsQuery(customerID)
    const refetchUsers = usersQuery.refetch
    const refetchGroups = groupsQuery.refetch
    const users = useMemo(() => new Map(usersQuery.data?.map(u => [u.ID, u])), [usersQuery.data])
    const groups = useMemo(() => new Map(groupsQuery.data?.map(g => [g.id, g])), [groupsQuery.data])

    const { t } = useTranslation()

    const actorName = useCallback(
        (a: Actor) => {
            const defaultName = t("field.unknown")
            switch (a.type) {
                case ActorType.USER:
                    const u = users.get(+a.id)
                    return u ? formatUserLong(t, u) : defaultName
                case ActorType.GROUP:
                    return groups.get(a.id)?.displayName || defaultName
                default:
                    return defaultName
            }
        },
        [t, users, groups]
    )

    const asUser = useCallback(
        (a: Actor) => users.get(+a.id),
        [users]
    )

    const actors = useMemo(() =>
        (
            groupsQuery.data?.map(
                g => ({ id: g.id, type: ActorType.GROUP })
            ) || []
        ).concat(
            usersQuery.data?.map(
                u => ({ id: "" + u.ID, type: ActorType.USER })
            ) || []
        ),
        [usersQuery.data, groupsQuery.data]
    )

    const refetch = useCallback(
        () => {
            refetchUsers()
            refetchGroups()
        },
        [refetchUsers, refetchGroups]
    )

    const result = useMemo(
        (): CustomerActors => ({
            actorName,
            actors,
            asUser,
            actorQueries: [usersQuery, groupsQuery],
            refetch,
        }),
        [actorName, actors, asUser, usersQuery, groupsQuery, refetch])

    return result
}

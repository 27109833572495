import { Chip, Tooltip } from "@mui/material"
import { Role } from "../../api/Authz"
import { Roles } from "../../api/portal/hooks/Roles"
import { wideTooltip } from "../../config/sizing"
import { CommonRoleTooltip } from "./CommonRoleTooltip"
import { RoleIcon } from "./RoleIcon"

const commonRoleBackground = "rgba(13, 185, 255, 0.25)"

export interface CommonRoleChipProps {
    commonRole: Role
    roles: Roles
}

export const CommonRoleChip = (props: CommonRoleChipProps) => {
    const { commonRole, roles } = props

    return (
        <Tooltip
            title={<CommonRoleTooltip commonRole={commonRole} roles={roles} />}
            componentsProps={wideTooltip}
        >
            <Chip
                icon={<RoleIcon role={commonRole} roles={roles} />}
                label={roles.roleName(commonRole)}
                sx={{
                    pl: 1,
                    backgroundColor: commonRoleBackground,
                }}
            />
        </Tooltip>
    )
}

import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ActorPermission, AlarmScope, asCameraSubject, asCustomerSubject, asDefaultPerimeterPartition, asDefaultSystemPartition, asPartitionSubject, asSiteSubject, asUnitSubject, BaseRole, CameraScope, CameraSubject, CommonRole, CustomerScope, CustomerSubject, GateScope, GateSubject, isAllScope, Operation, OperationSubject, PartitionScope, PartitionSubject, RoleType, Scope, SiteScope, SiteSubject, Subject, SubjectType, UnitScope, UnitSubject } from "../api/Authz";
import { Site, Unit } from "../api/Customer";
import { useLazyGetOwnPermissionsQuery } from "../api/portal/Permissions";
import useAuth from "./AuthProvider";

const addBaseRoleOperations = (
    operations: Map<SubjectType, Set<Operation>>,
    id: string,
    baseRoles: Map<string, BaseRole>,
) => {
    baseRoles.get(id)?.operations?.forEach(op => {
        const level = OperationSubject(op)
        var ops = operations.get(level)
        if (ops === undefined) {
            ops = new Set<Operation>()
            operations.set(level, ops)
        }
        ops.add(op)
    })
}

const addCommonRoleOperations = (
    operations: Map<SubjectType, Set<Operation>>,
    id: string,
    baseRoles: Map<string, BaseRole>,
    commonRoles: Map<string, CommonRole>,
) =>
    commonRoles.get(id)?.roles.flatMap(r =>
        r.type === RoleType.BASE_ROLE ? addBaseRoleOperations(operations, r.id, baseRoles) : []
    ) || []

const permissionOperations = (
    p: ActorPermission,
    baseRoles: Map<string, BaseRole>,
    commonRoles: Map<string, CommonRole>,
) => {
    const operations = new Map<SubjectType, Set<Operation>>()
    p.roles?.forEach(r => {
        r.type === RoleType.BASE_ROLE ?
            addBaseRoleOperations(operations, r.id, baseRoles) :
            addCommonRoleOperations(operations, r.id, baseRoles, commonRoles)
    })
    return operations
}

const toKey = (prefix: string, op: Operation) => prefix + ":" + op
const globalPrefix = "g"
const customerPrefix = (prefix: string, id?: string) => prefix + ":c=" + id
const sitePrefix = (prefix: string, id?: string) => prefix + ":s=" + id
const unitPrefix = (prefix: string, name?: string) => prefix + ":u=" + name
const cameraPrefix = (prefix: string, id?: string) => prefix + ":c=" + id
const gatePrefix = (prefix: string, id?: string) => prefix + ":g=" + id
const alarmPrefix = (prefix: string, id?: string) => prefix + ":a=" + id
const partitionPrefix = (prefix: string, id?: string) => prefix + ":p=" + id

const indexLevels = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    prefix: string,
    levels: SubjectType[],
) =>
    levels.forEach(l => operations.get(l)?.forEach(op => index.add(toKey(prefix, op))))

const indexCamera = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    camera: CameraScope,
    prefix: string
) => {
    prefix = cameraPrefix(prefix, camera.id)
    indexLevels(index, operations, prefix, [SubjectType.CAMERA])
}

const indexGate = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    gate: GateScope,
    prefix: string
) => {
    prefix = gatePrefix(prefix, gate.id)
    indexLevels(index, operations, prefix, [SubjectType.GATE])
}

const indexPartition = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    partition: PartitionScope,
    prefix: string
) => {
    prefix = partitionPrefix(prefix, partition.id)
    indexLevels(index, operations, prefix, [SubjectType.PARTITION])
}

const indexAlarm = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    alarm: AlarmScope,
    prefix: string
) => {
    prefix = alarmPrefix(prefix, alarm.id)
    if (isAllScope(alarm.partitions)) {
        indexLevels(
            index, operations, prefix, [
            SubjectType.PARTITION,
        ])
        return
    }
    alarm.partitions?.forEach(p => indexPartition(index, operations, p, prefix))
}

const indexUnit = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    unit: UnitScope,
    prefix: string
) => {
    prefix = unitPrefix(prefix, unit.shortName)
    indexLevels(index, operations, prefix, [SubjectType.UNIT])
    if (isAllScope(unit.cameras)) {
        indexLevels(
            index, operations, prefix, [
            SubjectType.CAMERA,
        ])
    } else {
        unit.cameras?.forEach(c => indexCamera(index, operations, c, prefix))
    }
    if (isAllScope(unit.gates)) {
        indexLevels(
            index, operations, prefix, [
            SubjectType.GATE,
        ])
    } else {
        unit.gates?.forEach(g => indexGate(index, operations, g, prefix))
    }
    if (isAllScope(unit.alarms)) {
        indexLevels(
            index, operations, prefix, [
            SubjectType.PARTITION,
        ])
    } else {
        unit.alarms?.forEach(a => indexAlarm(index, operations, a, prefix))
    }
}

const indexSite = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    site: SiteScope,
    prefix: string
) => {
    prefix = sitePrefix(prefix, site.id)
    indexLevels(index, operations, prefix, [SubjectType.SITE])
    if (isAllScope(site.units)) {
        indexLevels(
            index, operations, prefix, [
            SubjectType.UNIT,
            SubjectType.CAMERA,
            SubjectType.PARTITION,
            SubjectType.GATE,
        ])
        return
    }
    site.units?.forEach(s => indexUnit(index, operations, s, prefix))
}

const indexCustomer = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    customer: CustomerScope,
    prefix: string
) => {
    prefix = customerPrefix(prefix, customer.id)
    indexLevels(index, operations, prefix, [SubjectType.CUSTOMER])
    if (isAllScope(customer.sites)) {
        indexLevels(
            index, operations, prefix, [
            SubjectType.SITE,
            SubjectType.UNIT,
            SubjectType.CAMERA,
            SubjectType.PARTITION,
            SubjectType.GATE,
        ])
        return
    }
    customer.sites?.forEach(s => indexSite(index, operations, s, prefix))
}

const indexScope = (
    index: Set<string>,
    operations: Map<SubjectType, Set<Operation>>,
    scope: Scope,
) => {
    const prefix = globalPrefix
    indexLevels(index, operations, prefix, [SubjectType.GLOBAL])
    if (isAllScope(scope.customers)) {
        indexLevels(
            index, operations, prefix, [
            SubjectType.CUSTOMER,
            SubjectType.SITE,
            SubjectType.UNIT,
            SubjectType.CAMERA,
            SubjectType.PARTITION,
            SubjectType.GATE,
        ])
        return
    }
    scope.customers?.forEach(c => indexCustomer(index, operations, c, prefix))
}

const indexPermission = (
    index: Set<string>,
    p: ActorPermission,
    baseRoles: Map<string, BaseRole>,
    commonRoles: Map<string, CommonRole>,
) => {
    indexScope(index, permissionOperations(p, baseRoles, commonRoles), p.scope)
}

const globalKeys = (op: Operation) => [
    toKey(globalPrefix, op),
]

const customerKeys = (op: Operation, customer?: CustomerSubject) => {
    if (!customer) {
        return []
    }
    const customerKey = customerPrefix(globalPrefix, customer.customerId)
    return [
        toKey(globalPrefix, op),
        toKey(customerKey, op),
    ]
}

const siteKeys = (op: Operation, site?: SiteSubject) => {
    if (!site) {
        return []
    }
    const customerKey = customerPrefix(globalPrefix, site.customerId)
    const siteKey = sitePrefix(customerKey, site.siteId)
    return [
        toKey(globalPrefix, op),
        toKey(customerKey, op),
        toKey(siteKey, op),
    ]
}

const unitKeys = (op: Operation, unit?: UnitSubject) => {
    if (!unit) {
        return []
    }
    const customerKey = customerPrefix(globalPrefix, unit.customerId)
    const siteKey = sitePrefix(customerKey, unit.siteId)
    const unitKey = unitPrefix(siteKey, unit.unitName)
    return [
        toKey(globalPrefix, op),
        toKey(customerKey, op),
        toKey(siteKey, op),
        toKey(unitKey, op),
    ]
}

const cameraKeys = (op: Operation, camera?: CameraSubject) => {
    if (!camera) {
        return []
    }
    const customerKey = customerPrefix(globalPrefix, camera.customerId)
    const siteKey = sitePrefix(customerKey, camera.siteId)
    const unitKey = unitPrefix(siteKey, camera.unitName)
    const cameraKey = cameraPrefix(unitKey, camera.cameraId)
    return [
        toKey(globalPrefix, op),
        toKey(customerKey, op),
        toKey(siteKey, op),
        toKey(unitKey, op),
        toKey(cameraKey, op),
    ]
}

const gateKeys = (op: Operation, gate?: GateSubject) => {
    if (!gate) {
        return []
    }
    const customerKey = customerPrefix(globalPrefix, gate.customerId)
    const siteKey = sitePrefix(customerKey, gate.siteId)
    const unitKey = unitPrefix(siteKey, gate.unitName)
    const gateKey = gatePrefix(unitKey, gate.gateId)
    return [
        toKey(globalPrefix, op),
        toKey(customerKey, op),
        toKey(siteKey, op),
        toKey(unitKey, op),
        toKey(gateKey, op),
    ]
}

const partitionKeys = (op: Operation, partition?: PartitionSubject) => {
    if (!partition) {
        return []
    }
    const customerKey = customerPrefix(globalPrefix, partition.customerId)
    const siteKey = sitePrefix(customerKey, partition.siteId)
    const unitKey = unitPrefix(siteKey, partition.unitName)
    const alarmKey = alarmPrefix(unitKey, partition.alarmId)
    const partitionKey = partitionPrefix(alarmKey, partition.partitionId)
    return [
        toKey(globalPrefix, op),
        toKey(customerKey, op),
        toKey(siteKey, op),
        toKey(unitKey, op),
        toKey(alarmKey, op),
        toKey(partitionKey, op),
    ]
}

const subjectKeys = (op: Operation, subject: Subject) => {
    switch (subject.type) {
        case SubjectType.GLOBAL:
            return globalKeys(op)
        case SubjectType.CUSTOMER:
            return customerKeys(op, subject.customer)
        case SubjectType.SITE:
            return siteKeys(op, subject.site)
        case SubjectType.UNIT:
            return unitKeys(op, subject.unit)
        case SubjectType.CAMERA:
            return cameraKeys(op, subject.camera)
        case SubjectType.GATE:
            return gateKeys(op, subject.gate)
        case SubjectType.PARTITION:
            return partitionKeys(op, subject.partition)
        default:
            console.error("Unsupported subject type: ", subject)
            return []
    }
}

const authorize = (op: Operation, subject: Subject, index: Set<string>) =>
    subjectKeys(op, subject).some(key => index.has(key))

interface AuthorizerContextType {
    initialized: boolean
    allowOperation: (operation: Operation, subject: Subject) => boolean
}

const AuthorizerContext = createContext<AuthorizerContextType>({} as AuthorizerContextType);

export const AuthorizerProvider = ({ children }: { children: ReactNode }) => {

    const [initialized, setInitialized] = useState(false)
    const [permissionsQueryTrigger, permissionsQuery] = useLazyGetOwnPermissionsQuery()
    const { actor } = useAuth()

    useEffect(
        () => {
            if (actor === undefined) {
                return
            }
            // Only load permissions if we have a valid actor, i.e., when the user is logged in.
            permissionsQueryTrigger().then(() => setInitialized(true))
        },
        [actor, permissionsQueryTrigger],
    )

    const baseRoles = useMemo(
        () => new Map(Object.entries(permissionsQuery.data?.included?.baseRole || {})),
        [permissionsQuery.data]
    )

    const commonRoles = useMemo(
        () => new Map(Object.entries(permissionsQuery.data?.included?.commonRole || {})),
        [permissionsQuery.data]
    )

    const index = useMemo(
        () => {
            // Build query optimization index to speed up all the authorization queries (in the order
            // of thousands in the main Portal home page for the "etadmin" user).
            const index = new Set<string>()
            permissionsQuery.data?.data?.forEach(p => indexPermission(index, p, baseRoles, commonRoles))
            return index
        },
        [permissionsQuery.data, baseRoles, commonRoles]
    )

    const allowOperation = useCallback(
        (operation: Operation, subject: Subject) => {
            if (subject.type !== OperationSubject(operation)) {
                console.error("Mismatched operation and subject type: ", operation, subject)
                return false
            }
            const allowed = authorize(operation, subject, index)
            console.log("Permission check: ", subject, operation, allowed)
            return allowed
        },
        [index]
    )

    return (
        <AuthorizerContext.Provider value={{ allowOperation, initialized }}>
            {children}
        </AuthorizerContext.Provider>
    );
}

export const useCustomerPermission = (operation: Operation, customerID?: number) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        allowOperation(operation, asCustomerSubject(customerID)),
        [allowOperation, operation, customerID])

    return allowed
}

export const useSitePermission = (operation: Operation, site?: Site) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        allowOperation(operation, asSiteSubject(site)),
        [allowOperation, operation, site])

    return allowed
}

export const useUnitPermission = (operation: Operation, unit?: Unit) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        allowOperation(operation, asUnitSubject(unit)),
        [allowOperation, operation, unit])

    return allowed
}

export const useCameraPermission = (operation: Operation, unit?: Unit, cameraID?: number) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        allowOperation(operation, asCameraSubject(unit, cameraID)),
        [allowOperation, operation, unit, cameraID])

    return allowed
}

export const useUnitPerimeterPermission = (operation: Operation, unit?: Unit) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        allowOperation(operation, asDefaultPerimeterPartition(unit)),
        [allowOperation, operation, unit])

    return allowed
}

export const useUnitSystemPermission = (operation: Operation, unit: Unit) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        allowOperation(operation, asDefaultSystemPartition(unit)),
        [allowOperation, operation, unit])

    return allowed
}

export const usePartitionPermission = (operation: Operation, unit: Unit, alarmID: number, partitionID: number) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        allowOperation(operation, asPartitionSubject(unit, alarmID, partitionID)),
        [allowOperation, operation, unit, alarmID, partitionID])

    return allowed
}

export const useAnyUnitsPermission = (operation: Operation, units: Unit[]) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        units.some(u => allowOperation(operation, asUnitSubject(u))),
        [allowOperation, operation, units])

    return allowed
}

export const useAnyUnitCamerasPermission = (operation: Operation, unit: Unit) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        unit.UnitConfig.Cameras.some(c => allowOperation(operation, asCameraSubject(unit, c.ID))),
        [allowOperation, operation, unit])

    return allowed
}

export const useAnyUnitsCamerasPermission = (operation: Operation, units: Unit[]) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        units.some(unit => unit.UnitConfig.Cameras.some(c => allowOperation(operation, asCameraSubject(unit, c.ID)))),
        [allowOperation, operation, units])

    return allowed
}

export const useAnyUnitsPerimeterPermission = (operation: Operation, units: Unit[]) => {
    const { allowOperation } = useAuthorizer()

    const allowed = useMemo(() =>
        units.some(u => allowOperation(operation, asDefaultPerimeterPartition(u))),
        [allowOperation, operation, units])

    return allowed
}

const useAuthorizer = () => useContext(AuthorizerContext)
export default useAuthorizer

import { Clear, DeleteForever, Edit } from "@mui/icons-material"
import { Box, IconButton, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"

export interface IdentifierActionsProps {
    allowEdit: boolean
    allowDelete: boolean
    allowUnassign: boolean
    onEdit: () => void
    onDelete: () => void
    onUnassign: () => void
}

export function IdentifierActions(props: IdentifierActionsProps) {
    const { allowEdit, allowDelete, allowUnassign, onEdit, onDelete, onUnassign } = props

    const { t } = useTranslation()

    return (
        <Box>
            <Tooltip title={t("action.unassign_identifier_tooltip")} disableInteractive>
                <span>
                    <IconButton
                        size="small"
                        color="primary"
                        aria-label="unassign identifier"
                        disabled={!allowUnassign}
                        onClick={onUnassign}>
                        <Clear />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={t("action.edit_identifier_tooltip")} disableInteractive>
                <span>
                    <IconButton
                        size="small"
                        color="primary"
                        aria-label="edit identifier"
                        disabled={!allowEdit}
                        onClick={onEdit}>
                        <Edit />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={t("action.delete_identifier_tooltip")} disableInteractive>
                <span>
                    <IconButton
                        size="small"
                        color="error"
                        aria-label="delete identifier"
                        disabled={!allowDelete}
                        onClick={onDelete}>
                        <DeleteForever />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    )
}

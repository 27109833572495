import { Settings } from "@mui/icons-material";
import { Box, Button, FormControlLabel, Grid, Stack, Switch, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { asGateSubject, Operation } from "../../api/Authz";
import { CameraConfig, Site, Unit } from "../../api/Customer";
import { flipUnitSwitch, Item, Payload, StateON, unitGateOpen, unitLight } from "../../api/OpenHAB";
import { OpenHABStream } from "../../api/OpenHABStream";
import { Live } from "../../api/Video";
import useAuthorizer, { useSitePermission, useUnitPermission } from "../../auth/AuthorizerProvider";
import { http } from "../../backend/request";
import { openHabEndpointURL } from "../../config/urls";
import { noAlarms } from "../../hooks/flags";
import { LightSwitch } from "../common/LightSwitch";
import { OneHourWindow } from "../common/TimelineChoice";
import { LabelledCamera } from "../video/LabelledCamera";

const getGateID = (name: string) => {
    const found = name.match(/^GATE(\d+)_SWITCH$/)
    if (!found) {
        return 0
    }
    return +found[1]
}

export interface AccessProps {
    site: Site
    unit: Unit
}

export function Access(props: AccessProps) {
    const { site, unit } = props

    const [items, setItems] = useState<Item[]>([])
    const [gateNames, setGateNames] = useState<string[]>([])
    const [gateCameras, setGateCameras] = useState<Map<string, CameraConfig[]>>(new Map())

    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()
    const { allowOperation } = useAuthorizer()
    const allowVideo = useUnitPermission(Operation.UI_VIEW_VIDEO, unit)
    const allowSwitchLight = useUnitPermission(Operation.SWITCH_LIGHT, unit)
    const allowManageAccess = useSitePermission(Operation.MANAGE_SITE_ACCESS, site)

    const isRelevant = useCallback(
        (name: string) =>
            (name.startsWith("GATE") && name.endsWith("_SWITCH")) ||
            (name === "LIGHTSWITCH"),
        []
    )

    const reloadItems = useCallback(
        () => {
            http<Item[]>('Getting unit items', openHabEndpointURL(unit, `items`), snackbar)
                .then(result => setItems(
                    result
                        .filter(item => isRelevant(item.name))
                        .sort((a, b) => a.name > b.name ? 1 : -1)
                ))
                .catch(e => console.log(e))
        },
        [isRelevant, snackbar, unit]
    )

    useEffect(() => {
        const newMapping = new Map<string, CameraConfig[]>(
            items
                .filter(i => i.name.startsWith("GATE") && i.name.endsWith("_SWITCH"))
                .map(i => [
                    i.name,
                    i.tags
                        .filter(t => t.startsWith("CAM_"))
                        .map(t => {
                            return {
                                ID: +t.substring(4),
                            }
                        })
                        .sort((c1, c2) => c1.ID - c2.ID)
                ]))
        // TODO(use deep comparison, instead of this very shallow one).
        if (newMapping.size !== gateCameras.size) {
            setGateCameras(newMapping)
        }
    }, [gateCameras, items])

    useEffect(() => {
        const newNames = items
            .filter(i => i.name.startsWith("GATE") && i.name.endsWith("_SWITCH"))
            .map(i => i.name)
        // TODO(use deep comparison, instead of this very shallow one).
        if (newNames.length !== gateNames.length) {
            setGateNames(newNames)
        }
    }, [gateNames, items])

    const onOpenHABMessage = useCallback(
        (name: string, payload: Payload) => {
            console.log("changing item", name, "to", payload.value)
            setItems(old => old.map(item => item.name === name ? {
                name: item.name,
                groupNames: item.groupNames,
                tags: item.tags,
                label: item.label,
                state: payload.value,
            } : item))
        },
        [])

    const onOpenHABConnected = useCallback(
        () => {
            console.log("stream connected")
            reloadItems()
        },
        [reloadItems]
    )

    const flipSwitch = useCallback(
        (item: Item, path: string) => flipUnitSwitch(item, path, snackbar),
        [snackbar]
    )

    const renderGate = useCallback(
        (name: string) => {
            const item = items.find(i => i.name === name)
            if (!item) {
                return null
            }

            const gateID = getGateID(item.name)
            const isOpen = item.state === StateON
            const allowed = allowOperation(
                isOpen ? Operation.CLOSE_GATE : Operation.OPEN_GATE,
                asGateSubject(unit, gateID)
            )
            return (
                <Grid key={`switch-${item.name}`} item xs={12}>
                    <Stack direction="row" px={1} sx={{ alignItems: "center", backgroundColor: theme.palette.grey[800] }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isOpen}
                                    disabled={!allowed}
                                    onClick={() => flipSwitch(item, unitGateOpen(unit, gateID))}
                                />
                            }
                            label={item.label}
                        />
                    </Stack>
                </Grid>
            )
        },
        [unit, items, flipSwitch, allowOperation, theme]
    )

    const lightSwitch = useMemo(() => items.find(item => item.name === "LIGHTSWITCH"), [items])

    const renderCameras = useCallback(
        (name: string) => {
            if (!allowVideo) {
                return null
            }
            const cameras = gateCameras.get(name)
            return cameras ?
                cameras.map(camera => <Grid key={`${name}-camera-${camera}`} item xs={12} md={6}>
                    <LabelledCamera
                        site={site}
                        unit={unit}
                        camera={camera}
                        playback={Live()}
                        toggledHistory={false}
                        small={false}
                        pinned={false}
                        motionAlarms={noAlarms}
                        hasEvents={false}
                        eventsPlayback={false}
                        window={OneHourWindow}
                        onSetEventsPlayback={() => { }}
                        onHistory={() => { }}
                        onPlaybackChange={() => { }}
                    />
                </Grid>) :
                null
        },
        [gateCameras, unit, site, allowVideo]
    )

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <OpenHABStream
                    unit={unit}
                    itemFilter={isRelevant}
                    onMessage={onOpenHABMessage}
                    onConnected={onOpenHABConnected}
                />
                <Grid container spacing={1} >
                    {gateNames.length > 0 &&
                        <Grid item xs={12}>
                            <Typography variant="h5">{t("access.gates")}</Typography>
                        </Grid>
                    }
                    {
                        gateNames.map(name => [renderGate(name), renderCameras(name)])
                    }
                    {lightSwitch &&
                        [
                            <Grid key="light-header" item xs={12}>
                                <Typography variant="h6">{t("unit.lights")}</Typography>
                            </Grid>
                            ,
                            <Grid key="light-swicth" item xs={12}>
                                <LightSwitch
                                    light={lightSwitch}
                                    disabled={!allowSwitchLight}
                                    flipSwitch={i => flipSwitch(i, unitLight(unit))}
                                />
                            </Grid>
                        ]
                    }
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("action.settings")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            startIcon={<Settings sx={{ mr: 0.5 }} />}
                            onClick={() => window.open(`https://acs.eyetowers.io`, '_blank')}
                            disabled={!allowManageAccess}
                        >
                            {t("access.management")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Stack >
    )
}

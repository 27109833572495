import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../api/User";
import { validateEmail, validateNonEmpty, validateUsername } from "../widgets/validation";

export interface UserDialogProps {
    open: boolean
    user?: User
    customerID: number
    onClose: () => void
    onCreate: (user: User) => void
    onUpdate: (user: User) => void
}

export function UserDialog(props: UserDialogProps) {
    const { open, user, customerID, onClose, onCreate, onUpdate } = props
    const [id, setID] = React.useState(user?.ID)
    const [username, setUsername] = React.useState(user?.Username)
    const [email, setEmail] = React.useState(user?.Email)
    const [name, setName] = React.useState(user?.Name)
    const [surname, setSurname] = React.useState(user?.Surname)
    const [usernameError, setUsernameError] = React.useState("")
    const [emailError, setEmailError] = React.useState("")
    const [nameError, setNameError] = React.useState("")
    const [surnameError, setSurnameError] = React.useState("")

    const { t } = useTranslation()
    const theme = useTheme()

    useEffect(() => {
        if (open) {
            clearError()
        }
    }, [open])

    useEffect(() => {
        setID(user?.ID)
        setUsername(user?.Username)
        setName(user?.Name)
        setEmail(user?.Email)
        setSurname(user?.Surname)
    }, [user])

    const hasError = () => !checkUsername(username) || !checkName(name) || !checkEmail(email) || !checkSurname(surname)
    const clearError = () => {
        setUsernameError("")
        setNameError("")
        setEmailError("")
        setSurnameError("")
    }

    const onSave = () => {
        if (hasError()) {
            return
        }
        // Do save it here.
        const toSave = {
            ID: id || 0,
            Username: username || "",
            Name: name || "",
            Email: email || "",
            Surname: surname || "",
            CustomerID: customerID,
        }
        if (!user) {
            onCreate(toSave)
        } else {
            onUpdate(toSave)
        }
    }

    const setUsernameChecked = (s: string) => {
        setUsername(s)
        checkUsername(s)
    }
    const checkUsername = (s?: string) => validateUsername(s, t, setUsernameError)

    const setEmailChecked = (s: string) => {
        setEmail(s)
        checkEmail(s)
    }
    const checkEmail = (s?: string) => validateEmail(s, t, setEmailError)

    const setNameChecked = (s: string) => {
        setName(s)
        checkName(s)
    }
    const checkName = (s?: string) => validateNonEmpty(s, t, setNameError)

    const setSurnameChecked = (s: string) => {
        setSurname(s)
        checkSurname(s)
    }
    const checkSurname = (s?: string) => validateNonEmpty(s, t, setSurnameError)

    return (
        <Dialog onClose={onClose} aria-labelledby="user-dialog-title" maxWidth='sm' open={open}>
            <DialogTitle id="user-dialog-title">
                {t(user ? "dialog.edit_user.title" : "dialog.new_user.title")}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing(2)}>
                    <Grid item xs={6}>
                        <TextField
                            id="Username"
                            label={t("field.username")}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="none"
                            disabled={!!user}
                            defaultValue={user?.Username}
                            error={!!usernameError}
                            helperText={usernameError}
                            onChange={(event) => setUsernameChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <TextField
                            id="Name"
                            label={t("field.first_name")}
                            autoComplete="off"
                            autoCorrect="off"
                            defaultValue={user?.Name}
                            error={!!nameError}
                            helperText={nameError}
                            onChange={(event) => setNameChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="Surname"
                            label={t("field.surname")}
                            autoComplete="off"
                            autoCorrect="off"
                            defaultValue={user?.Surname}
                            error={!!surnameError}
                            helperText={surnameError}
                            onChange={(event) => setSurnameChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="Email"
                            label={t("field.email")}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="none"
                            disabled={!!user}
                            defaultValue={user?.Email}
                            error={!!emailError}
                            helperText={emailError}
                            onChange={(event) => setEmailChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSave} variant="contained" color="primary" >
                    {t(user ? "action.save" : "action.create")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

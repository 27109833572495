import { Box } from '@mui/material';
import { useState } from 'react';
import { staticURL } from '../config/urls';


export interface ImageWithLoadingProps {
  src: string
  alt: string
  onError?: () => void;
}

export function ImageWithLoading(props: ImageWithLoadingProps) {
  const { src, alt, onError } = props
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fallbackSrc = staticURL("camera-logo.svg")
  const loadingSrc = staticURL('loading.svg')

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false)
    setIsError(true);
    onError?.()
  };

  const hidden = isLoading || isError

  return (
    <Box>
      {isLoading && <img
        src={loadingSrc}
        width="100%"
        alt={alt}
        style={{ display: "block" }}
      />}
      {isError &&
        <img
          src={fallbackSrc}
          width="100%"
          alt={alt}
          style={{ display: "block" }}
        />}
      <img
        src={src}
        width="100%"
        onLoad={handleLoad}
        onError={handleError}
        alt={alt}
        loading='lazy'
        style={{
          display: "block",
          zIndex: hidden ? -1 : undefined,
          position: hidden ? "absolute" : undefined,
        }}
      />
    </Box>
  );
};

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, InputLabel, MenuItem, Select, Slider, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Customer } from "../api/Customer";
import { GlobalAdminID } from "../api/Roles";
import { User } from "../api/User";
import { http } from "../backend/request";
import { request } from "../config/headers";
import { AddDuration, Day } from "../config/time";
import { endpointURL } from "../config/urls";
import { formatUserLong } from "../widgets/format";

export interface PickExternalUserDialogProps {
    open: boolean
    customers: Customer[]
    targetCustomerID: number
    onClose: () => void
    onPicked: (user: User, expiration: Date) => void
}

const noneID = -1
const defaultExpiration = 1

export function PickExternalUserDialog(props: PickExternalUserDialogProps) {
    const { open, customers, targetCustomerID, onClose, onPicked } = props

    const [customerID, setCustomerID] = useState(noneID)
    const [userID, setUserID] = useState(noneID)
    const [expiration, setExpiration] = useState(defaultExpiration)
    const [users, setUsers] = useState<User[]>([])

    const { t } = useTranslation()
    const snackbar = useSnackbar()
    const theme = useTheme()

    const clearUsers = useCallback(() => {
        setUsers([])
        setUserID(noneID)
    }, [])

    useEffect(() => {
        clearUsers()
        if (customerID === noneID) {
            return
        }
        http<User[]>("Loading customer users", endpointURL(`customers/${customerID}/users`), snackbar, request)
            .then(result => setUsers(result))
            .catch(clearUsers)
    }, [customerID, snackbar, clearUsers])

    const allowedCustomers = useMemo(() =>
        customers.filter(c => c.ID !== targetCustomerID)
        , [customers, targetCustomerID])

    const allowedUsers = useMemo(() =>
        users.filter(u => u.ID !== GlobalAdminID && u.CustomerID !== targetCustomerID)
        , [users, targetCustomerID])

    const onAdd = () => {
        const user = users.find(u => u.ID === userID)
        if (!user) {
            return
        }
        onPicked(user, AddDuration(new Date(), Day * expiration))
    }

    const emptyMenuItem = (
        <MenuItem value={noneID}>
            <Typography color={theme.palette.error.main} fontStyle="italic">{t("message.not_selected")}</Typography>
        </MenuItem>
    )

    if (!open) {
        return null
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="pick-external-user-dialog-title" maxWidth='xs' fullWidth open>
            <DialogTitle id="pick-external-user-dialog-title">{t("dialog.pick_external_user.title")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="customer-select-label">{t("field.customer")}</InputLabel>
                            <Select
                                fullWidth
                                onChange={e => setCustomerID(e.target.value as number)}
                                labelId="customer-select-label"
                                value={customerID}
                            >
                                {emptyMenuItem}
                                {
                                    allowedCustomers.map(c => (
                                        <MenuItem value={c.ID} key={c.ID}>
                                            {c.DisplayName}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="company-select-label">{t("field.user")}</InputLabel>
                            <Select
                                fullWidth
                                onChange={e => setUserID(e.target.value as number)}
                                labelId="company-select-label"
                                value={userID}
                            >
                                {emptyMenuItem}
                                {
                                    allowedUsers.map(u => (
                                        <MenuItem value={u.ID} key={u.ID}>
                                            {formatUserLong(t, u)}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={12} mt={1}>
                        <FormControl variant="standard" fullWidth>
                            <FormLabel id="expiration-group-label">{t("message.expiration_in_days")}</FormLabel>
                            <Box m={1}>
                                <Slider
                                    aria-labelledby="expiration-groups-label"
                                    defaultValue={defaultExpiration}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks={Array.from({ length: 14 }, (_, i) => {
                                        return {
                                            value: i + 1,
                                            label: (i + 1).toString(),
                                        }
                                    })}
                                    onChange={(_, value) => setExpiration(value as number)}
                                    min={1}
                                    max={14}
                                />
                            </Box>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAdd} disabled={userID === noneID} variant="contained" color="primary">
                    {t("action.add")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

import { Chip, Tooltip } from "@mui/material"
import { Role } from "../../api/Authz"
import { Roles } from "../../api/portal/hooks/Roles"
import { wideTooltip } from "../../config/sizing"
import { BaseRoleTooltip } from "./BaseRoleTooltip"
import { RoleIcon } from "./RoleIcon"

export interface BaseRoleChipProps {
    baseRole: Role
    roles: Roles
}

export const BaseRoleChip = (props: BaseRoleChipProps) => {
    const { baseRole, roles } = props

    return (
        <Tooltip
            title={<BaseRoleTooltip baseRole={baseRole} roles={roles} />}
            componentsProps={wideTooltip}
        >
            <Chip
                icon={<RoleIcon role={baseRole} roles={roles} />}
                label={roles.roleName(baseRole)}
                sx={{ pl: 1 }}
            />
        </Tooltip>
    )
}

import { CircularProgress, Stack } from "@mui/material";

export const LoadingBox = () => {
    return (
        <Stack
            p={5}
            direction="column"
            width="100%"
            height="100%"
            alignItems="center"
        >
            <CircularProgress thickness={5} size={24} sx={{ animationDuration: "700ms" }} />
        </Stack >
    )
}

import { Paper, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Role, RoleType } from "../../api/Authz"
import { Roles } from "../../api/portal/hooks/Roles"
import { BaseRoleChip } from "./BaseRoleChip"

export interface BaseRoleTooltipProps {
    baseRole: Role
    roles: Roles
}

export const BaseRoleTooltip = (props: BaseRoleTooltipProps) => {
    const { baseRole, roles } = props

    const { t } = useTranslation()
    const impliedRoles = useMemo(() => roles.baseRoleImplies(baseRole), [roles, baseRole])

    return (
        <Stack m={1} mb={2} spacing={1} alignItems="flex-start">
            <Typography variant="body2">
                {roles.roleDescription(baseRole)}
            </Typography>
            {impliedRoles.length > 0 &&
                <>
                    <Typography variant="body2">
                        {t("message.contains_base_roles")}
                    </Typography>
                    <Paper variant="outlined" sx={{ overflowY: "auto", maxHeight: 350 }}>
                        <Stack m={1} spacing={1} alignItems="flex-start">
                            {impliedRoles.map(r =>
                                <BaseRoleChip
                                    key={r.id}
                                    baseRole={{ type: RoleType.BASE_ROLE, id: r.id }}
                                    roles={roles}
                                />
                            )
                            }
                        </Stack>
                    </Paper>
                </>
            }
        </Stack>
    )
}

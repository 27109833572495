import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Preset } from "../api/PTZ";

export interface PickPresetDialogProps {
    open: boolean
    presets: Preset[]
    onClose: () => void
    onPicked: (preset: Preset) => void
    parent?: Element
}

export function PickPresetDialog(props: PickPresetDialogProps) {
    const { open, presets, onClose, onPicked, parent } = props

    const { t } = useTranslation()

    const defaultNameRegexp = useMemo(() => /^Preset [0-9]+$/, [])
    const matchesDefaultName = useCallback((name: string) => !!name.match(defaultNameRegexp), [defaultNameRegexp])
    const isValid = useCallback((preset: Preset) => !matchesDefaultName(preset.Name), [matchesDefaultName])
    const validPresets = useMemo(() => presets.filter(isValid), [presets, isValid])
    const invalidPresets = useMemo(() => presets.filter(p => !isValid(p)), [presets, isValid])

    return (
        <Dialog onClose={onClose} aria-labelledby="pick-preset-dialog-title" maxWidth='lg' fullWidth open={open} container={parent}>
            <DialogTitle id="pick-preset-dialog-title">
                {t("dialog.pick_preset.title")}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} >
                    {validPresets.map(p =>
                        <Grid key={p.token} item xs={12} sm={6} md={3} lg={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => onPicked(p)}
                                fullWidth
                            >{p.Name}</Button>
                        </Grid>)
                    }
                    {invalidPresets.map(p =>
                        <Grid key={p.token} item xs={12} sm={6} md={3} lg={2}>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={() => onPicked(p)}
                                fullWidth
                            >{p.Name}</Button>
                        </Grid>)
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

import { Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { Unit } from "../../api/Customer";
import { http, noSnackBar } from "../../backend/request";
import { monoEndpointURL } from "../../config/urls";
import { TimeRange, ToShortLocalDateTime, ToShortLocalTime } from "../../config/time";
import { Labels, MaxEvents, asLabels } from "../../api/Event";
import { request } from "../../config/headers";
import { PaginationResponse } from "../../api/Pagination";

interface Value {
    time: number;
    value: number;
}

const INTERVAL = 5 * 60 * 1000;
const yesterday = () => new Date().getTime() - 24 * 60 * 60 * 1000;

const pushValueInMinutes = (prev: number, current: Value, interval: number, till: number, target: Value[]) => {
    if (prev >= current.time) {
        current.time = prev + 1;
    }
    target.push(current);

    let now = current.time + interval;
    let last = current;

    while (now < till) {
        last = {
            time: now,
            value: last.value,
        };
        target.push(last);
        now += interval;
    }
    return last;
};

const extendData = (data: Value[], startTime: number, currentState: boolean) => {
    if (data.length === 0) {
        const newValue: Value = {
            time: startTime,
            value: currentState ? 1 : 0,
        };
        data.push(newValue);
    } else if (data[0].time > startTime) {
        const newValue: Value = {
            time: startTime,
            value: data[0].value === 0 ? 1 : 0,
        };
        data.unshift(newValue);
    }

    const result: Value[] = [];
    const end = new Date().getTime();
    let last = data[0];

    for (let i = 0; i < data.length; i++) {
        const next = i + 1;
        const nextTime = next < data.length ? data[next].time : end;
        last = pushValueInMinutes(last.time, data[i], INTERVAL, nextTime, result);
    }
    console.log("loaded", data);
    console.log("extended", result);
    return result;
};

interface listEventsResponse {
    data: eventPayload[];
    pagination: PaginationResponse;
}

interface eventPayload {
    value?: valueEvent;
}

interface valueEvent {
    thing: string;
    item: string;
    value: value;
}

interface value {
    labels: Object;
    clientId: string;
    timestamp: string;
    operation: string;
    number?: number;
    text?: string;
}

export interface Event {
    timestamp: Date;
    labels: Labels;
    item: string;
    thing: string;
    value: number;
    clientId: string;
}

export interface SwitchHistoryChartProps {
    label: string;
    siteID: number;
    unit: Unit;
    partitionID: number;
    item: string;
    color: string;
    showCount?: boolean;
    currentState: boolean;
}

export function SwitchHistoryChart(props: SwitchHistoryChartProps) {
    const { label, siteID, unit, partitionID, item, color, showCount, currentState } = props;
    const [data, setData] = useState<Value[]>([]);
    const theme = useTheme();

    const loadEventHistory = useCallback(
        async (siteID: number, unit: string, range: TimeRange): Promise<{ events: Event[] }> => {
            const query = new URLSearchParams();
            unit && query.append("unit", unit);
            range.end && query.append("end", `${new Date(range.end).toISOString()}`);
            query.append("start", `${new Date(range.start).toISOString()}`);
            query.append("limit", `${MaxEvents}`);
            query.append("thing", `${unit}.alarm_1.partition_${partitionID}`); // paramerterize alarm ID
            query.append("item", `${item}`);
            const url = `${monoEndpointURL(`sites/${siteID}/events`)}?${query.toString()}`;

            return http<listEventsResponse>(
                `Loading ${unit ? `unit (${unit})` : `site`} events`,
                url,
                noSnackBar,
                request
            ).then((s) => {
                const events = s.data
                    .filter((e) => e.value?.value?.operation === "CHANGED")
                    .map((e) => extractEvent(e));
                events.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
                return { events };
            });
        },
        [item, partitionID]
    );

    const extractEvent = (o: eventPayload): Event => {
        const thing = o.value?.thing || "";
        const item = o.value?.item || "";

        const timestamp = new Date(o.value?.value.timestamp || 0);
        const value = o.value?.value.number || 0;
        const labels = asLabels(o.value?.value.labels || {});
        const clientId = o.value?.value.clientId || "";

        return {
            thing,
            item,
            value,
            timestamp,
            labels,
            clientId,
        };
    };

    const reloadData = useCallback(() => {
        const time: TimeRange = {
            start: yesterday(),
            end: null,
        };
        loadEventHistory(siteID, unit.ShortName, time)
            .then((e) =>
                setData(
                    extendData(
                        e.events.map((d) => {
                            return {
                                time: d.timestamp.getTime(),
                                value: d.value,
                            };
                        }),
                        time.start,
                        currentState
                    )
                )
            )
            .catch((e) => console.log(e));
    }, [unit, siteID, loadEventHistory, currentState]);

    useEffect(() => reloadData(), [reloadData]);

    useEffect(() => {
        const interval = setInterval(() => reloadData(), INTERVAL);
        return () => clearInterval(interval);
    }, [reloadData]);

    const countON = useMemo(() => {
        let last = false;
        let count = 0;

        for (let i = 0; i < data.length; i++) {
            const cur = data[i].value === 1;
            if (!last && cur) {
                count++;
            }
            last = cur;
        }
        return count;
    }, [data]);

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.paper,
                width: "100%",
                height: "80px",
            }}
            p={1}
        >
            <Typography>
                {label}
                {showCount ? ` (${countON})` : ""}
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={data}>
                    <Tooltip
                        isAnimationActive={false}
                        contentStyle={{
                            backgroundColor: theme.palette.grey[800],
                            color: theme.palette.grey[50],
                        }}
                        itemStyle={{ color: theme.palette.primary.main }}
                        labelFormatter={ToShortLocalDateTime}
                        formatter={(s: any) => (s ? "ON" : "OFF")}
                        cursor={false}
                    />
                    <XAxis dataKey="time" fontSize="small" scale="time" tickFormatter={ToShortLocalTime} height={14} />
                    <Area
                        isAnimationActive={false}
                        type="stepAfter"
                        dataKey="value"
                        name="State"
                        fill={color}
                        fillOpacity={1}
                        stroke={color}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Stack>
    );
}

import { Button, CssBaseline, ThemeProvider } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarKey, SnackbarProvider } from "notistack";
import React, { useMemo } from "react";
import App from "./App";
import useConfig from "./config/Provider";
import { createDarkTheme, createLightTheme } from "./theme/Theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useTranslation } from "react-i18next";

export const ThemedApp = () => {

    const { mode } = useConfig()

    const notistackRef = React.createRef<SnackbarProvider>();
    const onClickDismiss = (key: SnackbarKey) => () => {
        if (notistackRef && notistackRef.current) {
            notistackRef.current.closeSnackbar(key)
        }
    }

    const theme = useMemo(() => mode === "dark" ? createDarkTheme() : createLightTheme(), [mode])
    const { i18n } = useTranslation()

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    ref={notistackRef}
                    action={(key) => (
                        <Button onClick={onClickDismiss(key)}>Dismiss</Button>
                    )}
                >
                    <ConfirmProvider>
                        <App />
                    </ConfirmProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </LocalizationProvider>
    )
}

import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ToLocalDate } from "../../config/time";

export type DateBoxProps = {
    date: Date
}

export function DateBox(props: DateBoxProps) {
    const { date } = props
    const { t } = useTranslation()
    const theme = useTheme()

    return (
        <Tooltip title={t("start_date", { date: ToLocalDate(date) })} disableInteractive PopperProps={{ disablePortal: true }}>
            <Box
                display="flex"
                justifyContent="center"
                width="100%"
                height="100%"
                sx={{
                    backgroundColor: theme.palette.grey[700],
                    userSelect: "none",
                }}
                px={0.5}
                pt={0.3}
            >
                <Typography fontWeight="bold" alignSelf="center" fontSize="0.75em" color="black" flexShrink={0} noWrap textOverflow={"ellipsis"}>
                    {ToLocalDate(date)}
                </Typography>
            </Box >
        </Tooltip>
    )
}

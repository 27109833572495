import { Dangerous } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDimensionsRef } from "rooks";
import adapter from 'webrtc-adapter';
import { asCameraSubject, Operation } from "../../api/Authz";
import { CameraConfig, Unit } from "../../api/Customer";
import { IsLive, Playback } from "../../api/Video";
import useAuthorizer from "../../auth/AuthorizerProvider";
import { noSnackBar, rawHttp } from "../../backend/request";
import { request } from "../../config/headers";
import { eyeCastEndpointURL } from "../../config/urls";

export enum CameraState {
    Offline,
    Connecting,
    Connected,
}

export interface CameraProps {
    unit: Unit
    camera: CameraConfig
    region: string
    fitParent?: boolean
    disabled?: boolean
    playback: Playback
    onVideoClick: () => void
    onStateChange?: (state: CameraState) => void
    onResolution?: (width: number, height: number) => void
    onBytesReceived?: (bytes: number) => void
    onFrames?: (frames: number) => void
}

export function Camera(props: CameraProps) {
    const {
        unit, camera, region, fitParent, disabled, playback,
        onVideoClick, onStateChange, onResolution, onBytesReceived, onFrames,
    } = props

    const [generation, setGeneration] = useState(0)
    const [width, setWidth] = useState<number | undefined>(undefined)
    const [height, setHeight] = useState<number | undefined>(undefined)
    const [controlChannel, setControlChannel] = useState<RTCDataChannel | undefined>()

    const theme = useTheme()
    const { t } = useTranslation()
    const { allowOperation } = useAuthorizer()
    const allowStream = useMemo(
        () => allowOperation(IsLive(playback) ?
            Operation.STREAM_CAMERA_LIVE :
            Operation.STREAM_CAMERA_ARCHIVE,
            asCameraSubject(unit, camera.ID)),
        [allowOperation, unit, playback, camera.ID],
    )

    const videoRef = useRef<HTMLVideoElement>(null)

    const snackbar = noSnackBar

    const reloadVideo = useCallback(() => setTimeout(() => setGeneration(g => g + 1), 5000), [])

    const statsPollMillis = 500
    const bitratePollMillis = 1000

    useEffect(() => {
        if (disabled || !allowStream || !videoRef.current || !unit || !camera) {
            return
        }

        console.log(`Cam${camera.ID}: Initializing connection for browser: "${JSON.stringify(adapter.browserDetails)}" (generation: ${generation}).`)

        const videoNode = videoRef.current
        const stream = new MediaStream()
        // No STUN configuration since we only use local candidates for the Web client.
        let pc: RTCPeerConnection | null = new RTCPeerConnection()
        let offer: RTCSessionDescriptionInit | null = null
        let exchanged = false
        let reloaded = false
        const reload = () => {
            if (reloaded) {
                console.log(`Cam${camera.ID}: Reload already triggered (generation: ${generation}).`)
                return
            }
            console.log(`Cam${camera.ID}: Reloading (generation: ${generation}).`)
            reloaded = true
            reloadVideo()
        }
        const getRemoteSdp = () => {
            if (!pc || !pc.localDescription || pc.signalingState === 'closed') {
                return
            }
            const offer = btoa(pc.localDescription.sdp)

            rawHttp('Posting SDP', eyeCastEndpointURL(unit, camera.ID, region), noSnackBar, {
                method: 'POST',
                body: offer,
                headers: request.headers,
            })
                .then(
                    data => {
                        if (pc && pc.signalingState !== 'closed') {
                            pc.setRemoteDescription(new RTCSessionDescription({
                                type: 'answer',
                                sdp: atob(data)
                            })).then(() => console.log(`Cam${camera.ID}: Remote description set.`))
                        }
                    }
                )
                .catch(e => {
                    console.log(`Cam${camera.ID}: Error exchanging offers: "${e.string}".`)
                    reload()
                })
        }

        const startStatsChecker = () => {
            const interval = setInterval(
                () => {
                    if (!pc) { return }
                    pc.getStats(stream.getVideoTracks()[0]).then(
                        stat => stat.forEach(v => {
                            if ((v as RTCStats).type !== "inbound-rtp") {
                                // Not the stats we are interested.
                                return
                            }
                            const rtpStats = v as RTCInboundRtpStreamStats
                            if (!rtpStats.framesDecoded) {
                                // No frames decoded yet, so the stream is not fully up.
                                return
                            }
                            console.log('H264 stream loaded, disabling MJPEG fallback.')
                            if (onStateChange) {
                                onStateChange(CameraState.Connected)
                            }
                            const width = stream.getTracks()[0].getSettings().width
                            const height = stream.getTracks()[0].getSettings().height
                            if (onResolution && width && height) {
                                onResolution(width, height)
                            }
                            clearInterval(interval)
                        })
                    )
                }, statsPollMillis)
            return () => {
                clearInterval(interval)
            }
        }

        const startBitrateChecker = () => {
            const interval = setInterval(
                () => {
                    if (!pc) { return }
                    pc.getStats(stream.getVideoTracks()[0]).then(
                        stat => stat.forEach(v => {
                            if ((v as RTCStats).type === "transport") {
                                const stats = v as RTCTransportStats
                                if (!stats.bytesReceived) {
                                    // No bytes received yet.
                                    return
                                }
                                if (onBytesReceived) {
                                    onBytesReceived(stats.bytesReceived)
                                }
                            }
                            if ((v as RTCStats).type === "inbound-rtp") {
                                const rtpStats = v as RTCInboundRtpStreamStats
                                if (!rtpStats.framesDecoded) {
                                    // No frames decoded yet.
                                    return
                                }
                                if (onFrames) {
                                    onFrames(rtpStats.framesDecoded)
                                }
                            }
                        })
                    )
                }, bitratePollMillis)
            return () => {
                clearInterval(interval)
            }
        }

        const exchangeOffers = () => {
            if (!exchanged && offer && pc && pc.connectionState !== 'closed' && pc.connectionState !== 'failed') {
                exchanged = true
                console.log(`Cam${camera.ID}: Local description set.`)
                getRemoteSdp()
                console.log(`Cam${camera.ID}: Sent offer tp remote.`)
            }
        }

        if (onStateChange) {
            onStateChange(CameraState.Connecting)
        }
        const channel = pc.createDataChannel("control", { id: 1, negotiated: true })
        channel.onopen = () => {
            console.log(`Cam${camera.ID}: Control channel open.`)
            setControlChannel(channel)
        }
        channel.onmessage = (event) => {
            interface StreamMessage {
                Type: string
            }
            console.log(`Cam${camera.ID}: event`, JSON.stringify(event))
            const message = JSON.parse(event.data as string) as StreamMessage
            if (message?.Type === "Reconnect") {
                console.log(`Cam${camera.ID}: Server-side reconnection request.`)
                reload()
            }
        }

        pc.onicegatheringstatechange = () => {
            console.log(`Cam${camera.ID}: ICE gathering state change: "${pc?.iceGatheringState}".`)
            if (pc?.iceGatheringState === 'complete' && offer) {
                exchangeOffers()
            }
        }

        pc.onnegotiationneeded = async () => {
            if (!pc) {
                console.log(`Cam${camera.ID}: Negotiation needed, but connection lost.`)
                return
            }
            console.log(`Cam${camera.ID}: Negotiation needed: "${pc.iceGatheringState}".`)
            offer = await pc.createOffer({
                iceRestart: false,
                offerToReceiveVideo: true,
                offerToReceiveAudio: false,
            })
            if (!pc) {
                console.log(`Cam${camera.ID}: Offer created, but connection lost.`)
                return
            }
            console.log(`Cam${camera.ID}: Offer created (type=${offer.type}):\n${offer.sdp}`)
            await pc.setLocalDescription(offer)
        }

        pc.ontrack = event => {
            stream.getTracks().forEach(t => stream.removeTrack(t))
            stream.addTrack(event.track)
            console.log(`Cam${camera.ID}: Track with ${event.streams.length} streams delivered.`)
        }

        pc.onsignalingstatechange = () => {
            console.log(`Cam${camera.ID}: Signaling state change: "${pc?.signalingState}".`)
            if (pc?.signalingState === "closed") {
                reload()
            }
        }

        pc.onconnectionstatechange = () => {
            console.log(`Cam${camera.ID}: Connection state change: "${pc?.connectionState}".`)
            if (pc?.connectionState === "failed") {
                reload()
            }
            if (pc?.connectionState === "connected") {
                console.log(`Cam${camera.ID}: Stream forwarded to HTML video element.`)
                videoNode.srcObject = stream
            }
        }

        pc.oniceconnectionstatechange = () => {
            console.log(`Cam${camera.ID}: ICE connection state change: "${pc?.iceConnectionState}".`)
            if (pc?.iceConnectionState === "failed") {
                reload()
            }
        }

        const stopStatsChecker = startStatsChecker()
        const stopBitrateChecker = startBitrateChecker()

        return () => {
            console.log(`Cam${camera.ID}: (Un/re)mounting closing (generation: ${generation}).`)
            channel.close()
            setControlChannel(undefined)
            stopStatsChecker()
            stopBitrateChecker()
            videoNode.srcObject = null
            stream.getTracks().forEach(t => stream.removeTrack(t))
            if (pc !== null) {
                pc.close()
                pc = null
            }
            if (onStateChange) {
                onStateChange(CameraState.Offline)
            }
        }
    }, [snackbar, disabled, allowStream, reloadVideo, generation, videoRef,
        camera, unit, region, onStateChange, onResolution, onBytesReceived, onFrames])

    const [wrapperRef, dimensions, element] = useDimensionsRef()

    useEffect(() => {
        setWidth(dimensions?.width)
        setHeight(dimensions?.height)
    }, [dimensions?.height, dimensions?.width, camera])

    const tryUpdateSize = useCallback(() => {
        const box = element?.getBoundingClientRect()
        if (box && (width !== box?.width || height !== box.height)) {
            setWidth(box.width)
            setHeight(box.height)
        }
    }, [width, height, element])

    useEffect(() => {
        // Make sure that we eventually adjust the video size even if we don't get the
        // corresponding notification.
        const l = () => window.requestAnimationFrame(tryUpdateSize)
        const interval = setInterval(l, 100)
        window.addEventListener("fullscreenchange", l)

        return () => {
            clearInterval(interval)
            window.removeEventListener("fullscreenchange", l)
        }
    }, [tryUpdateSize])

    useEffect(() => {
        if (!controlChannel || controlChannel.readyState !== "open") {
            console.info("Control channel not set up yet, ignoring the playback command.")
            return
        }
        console.info("Playback comand sent", playback)
        controlChannel.send(JSON.stringify(playback))
    }, [playback, controlChannel])

    return (
        <Stack width="100%" height="100%" sx={{
            position: "relative",
            display: "flex",
        }}>
            <Box ref={wrapperRef as React.Ref<unknown>} style={{ width: "100%", height: "100%" }}>
                {disabled || !allowStream ?
                    <Box
                        style={{ position: fitParent ? "absolute" : "relative", display: "flex" }}
                        width={fitParent ? width : "100%"}
                        height={fitParent ? height : "100%"}
                        overflow="hidden"
                        minHeight={48}
                        minWidth={48}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {!allowStream &&
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Dangerous htmlColor={theme.palette.grey[600]} />
                                <Typography color={theme.palette.grey[600]} variant="body2" fontWeight="bold">
                                    {t("message.forbidden_stream")}
                                </Typography>
                            </Stack>
                        }
                    </Box> :
                    <video
                        key={`cam-video-target-${camera.ID}`}
                        style={{ position: fitParent ? "absolute" : "relative", display: "flex" }}
                        ref={videoRef}
                        muted={true}
                        autoPlay={true}
                        playsInline={true}
                        controls={false}
                        disablePictureInPicture
                        onClick={onVideoClick}
                        width={fitParent ? width : "100%"}
                        height={fitParent ? height : "100%"}
                    />
                }
            </Box>
        </Stack>
    )
}

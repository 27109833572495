import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Actor, ActorType, Operation, Permission } from "../../api/Authz";
import { actorKey, CustomerActors } from "../../api/portal/hooks/Actors";
import { useAddCustomerPermissionGroupMutation, useAddCustomerPermissionUserMutation, useRemoveCustomerPermissionGroupMutation, useRemoveCustomerPermissionUserMutation } from "../../api/portal/Permissions";
import { useCustomerPermission } from "../../auth/AuthorizerProvider";
import { LoadingDialog } from "../common/dialog/LoadingDialog";
import { SavedIndicator } from "../common/dialog/SavedIndicator";
import { ActorChip } from "./ActorChip";

export interface EditActorsDialogProps {
    customerID: number
    permission: Permission
    customerActors: CustomerActors
    onClose: () => void
}

export function EditActorsDialog(props: EditActorsDialogProps) {
    const { customerID, permission, customerActors, onClose } = props

    const { t } = useTranslation()

    const users = useMemo(() => customerActors.actors.filter(a => a.type === ActorType.USER), [customerActors])
    const groups = useMemo(() => customerActors.actors.filter(a => a.type === ActorType.GROUP), [customerActors])

    const [addUser, addUserMutation] = useAddCustomerPermissionUserMutation()
    const [removeUser, removeUserMutation] = useRemoveCustomerPermissionUserMutation()
    const [addGroup, addGroupMutation] = useAddCustomerPermissionGroupMutation()
    const [removeGroup, removeGroupMutation] = useRemoveCustomerPermissionGroupMutation()

    const allowAddActor = useCustomerPermission(Operation.ADD_CUSTOMER_PERMISSION_ACTOR, customerID)
    const allowRemoveActor = useCustomerPermission(Operation.REMOVE_CUSTOMER_PERMISSION_ACTOR, customerID)

    const selected = useMemo(() =>
        new Map(permission.actors?.map(a => [actorKey(a), a])) || [],
        [permission.actors],
    )
    const isSelected = useCallback((a: Actor) => !!selected.get(actorKey(a)), [selected])

    const toggleActor = (a: Actor) => {
        const task = {
            customerID: customerID,
            permissionID: +permission.id,
            actorID: +a.id,
        }
        switch (a.type) {
            case ActorType.USER:
                if (isSelected(a)) {
                    removeUser(task)
                } else {
                    addUser(task)
                }
                break;
            case ActorType.GROUP:
                if (isSelected(a)) {
                    removeGroup(task)
                } else {
                    addGroup(task)
                }
                break;
            default:
                console.error("Unknown actor type", a.type)
        }
    }

    const renderActor = (a: Actor) => (
        <ListItem key={actorKey(a)} onClick={() => toggleActor(a)} sx={{ p: 0 }}>
            <ListItemButton disabled={isSelected(a) ? !allowRemoveActor : !allowAddActor}>
                <ListItemIcon>
                    <Checkbox checked={isSelected(a)} size="small" />
                </ListItemIcon>
                <ListItemText>
                    <ActorChip actor={a} customerActors={customerActors} />
                </ListItemText>
            </ListItemButton>
        </ListItem>
    )

    return (
        <>
            <Dialog onClose={onClose} aria-labelledby="edit-actors-dialog-title" open={true}>
                <DialogTitle id="edit-actors-dialog-title">
                    {t("dialog.permission.edit_actors_title")}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack direction="row" spacing={1}>
                        <List dense>
                            {groups.length > 0 &&
                                <>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                {t("field.groups")}:
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    {groups.map(renderActor)}
                                </>
                            }
                            {users.length > 0 &&
                                <>
                                    <ListItem>
                                        <ListItemText>
                                            <Typography>
                                                {t("field.users")}:
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    {users.map(renderActor)}
                                </>
                            }
                        </List>
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ px: 2 }}>
                    <SavedIndicator
                        mutations={[
                            addUserMutation,
                            removeUserMutation,
                            addGroupMutation,
                            removeGroupMutation,
                        ]}
                    />
                    <Box flexGrow={1} />
                    <Button onClick={onClose} variant="contained" color="primary">
                        {t("action.close")}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingDialog
                queries={customerActors.actorQueries}
                mutations={[
                    addUserMutation,
                    removeUserMutation,
                    addGroupMutation,
                    removeGroupMutation,
                ]}
            />
        </>
    )
}

import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useAuth from "../auth/AuthProvider"
import { RecoveryDialog } from "../components/RecoveryDialog"

export const Recovery = () => {
    const [messages, setMessages] = useState<string[]>([])
    const [searchParams] = useSearchParams()
    const token = searchParams.get("token")
    const navigate = useNavigate()
    const auth = useAuth()

    const startRecovery = (email: string) => {
        auth.startRecovery(email, setMessages, navigate)
    }

    return <RecoveryDialog
        failedRecovery={!!token}
        messages={messages}
        onStartRecovery={startRecovery}
    />
}

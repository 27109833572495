import { Chip, Tooltip } from "@mui/material"
import { Actor } from "../../api/Authz"
import { CustomerActors } from "../../api/portal/hooks/Actors"
import { wideTooltip } from "../../config/sizing"
import { ActorIcon } from "./ActorIcon"
import { GroupTooltip } from "./GroupTooltip"

const groupBackground = "rgba(13, 185, 255, 0.25)"

export interface GroupChipProps {
    group: Actor
    customerActors: CustomerActors
}

export const GroupChip = (props: GroupChipProps) => {
    const { group, customerActors } = props

    return (
        <Tooltip
            title={<GroupTooltip group={group} customerActors={customerActors} />}
            componentsProps={wideTooltip}
        >
            <Chip
                icon={<ActorIcon actor={group} />}
                label={customerActors.actorName(group)}
                sx={{
                    pl: 1,
                    backgroundColor: groupBackground,
                }}
            />
        </Tooltip>
    )
}

import { CameraAlt, Download, Videocam } from '@mui/icons-material';
import { Grid, IconButton, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { asCameraSubject, Operation } from '../api/Authz';
import { Site, Unit } from '../api/Customer';
import { PresetsResponse } from '../api/Snapshot';
import useAuthorizer, { useAnyUnitCamerasPermission } from '../auth/AuthorizerProvider';
import { http } from '../backend/request';
import { request } from '../config/headers';
import { snapshotEndpointURL } from '../config/urls';
import { LoadingBox } from '../features/events/LoadingBox';
import { NewFilter } from '../services/EventFilter';
import { emphasize } from "../theme/Theme";
import ImageModal from './ImageModal';
import { PageError } from './PageError';
import PresetWithSnapshot from './PresetWithSnapshot';
import { TimelapseExportDialog } from './TimelapseExportDialog';

export interface SnapshotPresetListProps {
  unit: Unit
  site: Site
}

export interface Cameras {
  id: number;
  presets: SnapshotPreset[]
}

export interface SnapshotPreset {
  preset: string;
  lastSnapshot: string;
}

export default function SnapshotPresetList(props: SnapshotPresetListProps) {
  const { unit, site } = props

  const customerID = site?.CustomerID;
  const siteID = unit?.SiteID;
  const unitName = unit?.ShortName;


  const snackbar = useSnackbar();
  const [cameras, setCameras] = useState<Cameras[]>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [exportingTimelapse, setExportingTimelapse] = useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { allowOperation } = useAuthorizer()

  const [exportPreselection, setExportPreselection] = useState<Set<string>>(new Set());

  const allowSomeExport = useAnyUnitCamerasPermission(Operation.EXPORT_CAMERA_TIMELAPSE, unit)

  const targetObject = `customers/${customerID}/sites/${siteID}/units/${unitName}/cameras`
  const targetCameras = useMemo(() =>
    unit?.UnitConfig.Cameras.filter(c => allowOperation(Operation.VIEW_CAMERA_SNAPSHOT, asCameraSubject(unit, c.ID))),
    [unit, allowOperation],
  )

  useEffect(() => {
    setCameras([]);
    setIsLoading(true)

    Promise.all(
      targetCameras?.map(async camera => {
        const endpoint = snapshotEndpointURL(`${targetObject}/${camera.ID}/presets`);
        try {
          const resp = await http<PresetsResponse>(
            "Loading camera presets",
            endpoint,
            snackbar,
            request,
          );

          if (!resp?.data || resp?.data.length === 0) {
            return {
              id: camera.ID,
              presets: [],
            };
          }

          const presetList: SnapshotPreset[] = resp.data.map(d => ({
            preset: d.preset,
            lastSnapshot: `${endpoint}/${d.preset}/last.jpg`,
          }));

          const newCamera: Cameras = {
            id: camera.ID,
            presets: presetList,
          }
          return newCamera;
        } catch (error) {
          setError(error);
          return null;
        }
      })
    )
      .then(newCameras => {
        // Filter out null values (in case of errors)
        const filteredCameras = newCameras.filter(camera => camera !== null) as Cameras[];
        const sortedCameras = filteredCameras.sort((a, b) => a!.id - b!.id);
        setCameras(sortedCameras);
      })
      .catch(err => setError(err))
      .finally(() => setIsLoading(false))
  }, [snackbar, targetCameras, targetObject]);


  const [zoomSnapshot, setZoomSnapshot] = useState<string>("");
  const handleZoomButton = (snapshotURL: string) => {
    setZoomSnapshot(snapshotURL);
  };

  const handlePresetExportButton = (camID: number, preset: string) => {
    const selection = new Set([`${camID}/${preset}`])
    setExportPreselection(selection)
    setExportingTimelapse(true)
    return
  }

  const renderCameras = () => {
    return (
      <Stack
        direction="column"
        spacing={0.5}
      >
        <Stack
          direction="row"
          px={2}
          spacing={0.5}
          alignItems="center"
        >
          <CameraAlt fontSize="small" htmlColor={theme.palette.text.secondary} />
          <Typography variant="body1" fontWeight="bold" noWrap flexShrink={1}>
            {t("offering.snapshots")}
          </Typography>
          <Box flexGrow={1} />
          <Stack direction="row">
            <Tooltip title={t(allowSomeExport ? "dialog.snapshot.export_tooltip" : "dialog.snapshot.export_tooltip_forbidden")} disableInteractive>
              <span>
                <IconButton color="primary" onClick={() => {
                  setExportPreselection(new Set())
                  setExportingTimelapse(true)
                }}
                  disabled={!allowSomeExport}
                >
                  <Download />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Stack>

        {cameras.map(cam => (
          <Stack key={cam.id}>
            <Stack
              direction="row"
              spacing={0.5}
              py={1}
              px={2}
              sx={{
                borderColor: theme.palette.divider,
                borderWidth: 1,
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                backgroundImage: emphasize(0.05, theme),
              }}>
              <Videocam fontSize="small" htmlColor={theme.palette.text.secondary} />
              <Typography
                fontSize="0.9em"
                textOverflow="ellipsis"
                noWrap
                flexShrink={1}>
                <b>Cam{cam.id}</b>
              </Typography>
            </Stack>
            {renderPresets(cam)}
          </Stack>
        ))}
      </Stack>
    )
  }

  const renderPresets = (cam: Cameras) => {
    if (cam.presets.length === 0) {
      return <Typography variant="body1" p={2} fontStyle="italic">{t("message.no_snapshots_available")}</Typography>
    }

    return (
      <Grid container spacing={1} wrap="wrap" sx={{ px: 2, pt: 1, pb: 2, overflow: "hidden", }}>
        {cam.presets.map(preset => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={preset.preset}>
            <PresetWithSnapshot
              unit={unit}
              preset={preset}
              camID={cam.id}
              handleZoomButton={handleZoomButton}
              handleExportButton={() => handlePresetExportButton(cam.id, preset.preset)}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  if (isLoading) return <LoadingBox />

  if (error) return <PageError message={t("error")} />;

  if (!cameras.length && !isLoading) {
    return <PageError message={t("message.no_snapshots_available")} />
  }


  return (
    <Stack direction="column">
      <Paper sx={{ py: 1, m: 2 }}>
        {renderCameras()}
        <ImageModal
          open={!!zoomSnapshot}
          handleClose={() => setZoomSnapshot("")}
          imageUrl={zoomSnapshot}
        />
        {exportingTimelapse &&
          <TimelapseExportDialog
            cameras={cameras}
            initialFilter={NewFilter(true, exportPreselection, [], [])}
            site={site}
            unit={unit}
            onClose={() => setExportingTimelapse(false)}
          />
        }
      </Paper>
    </Stack>
  );
}

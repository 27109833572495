import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"


export interface CamCountChoiceProps {
    value: number
    choices: number[]
    orientation: "vertical" | "horizontal"
    onChange: (count: number) => void
}

export const CamCountChoice = (props: CamCountChoiceProps) => {
    const { value, choices, orientation, onChange } = props

    return (
        <ToggleButtonGroup
            value={value}
            orientation={orientation}
            exclusive={true}
            onChange={(_, v) => v ? onChange(v) : undefined}
            color="primary"
        >
            {choices.map(c =>
                <ToggleButton
                    key={c}
                    sx={{ padding: "0.2em" }}
                    size="small"
                    value={c}
                    color="primary"
                >
                    <Typography minWidth="18px" variant="caption" fontWeight="bold">{c}</Typography>
                </ToggleButton>
            )}
        </ToggleButtonGroup>
    )
}
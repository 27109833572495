import { BatterySaver, Cloud, Help, HelpOutline, Leaderboard, MeetingRoom, NotificationImportantOutlined, NotificationsActive, NotificationsOffOutlined, NotificationsOutlined, PhotoCamera, PinDrop, VideoLibrary, Videocam } from "@mui/icons-material";
import { Box, Chip, IconButton, Stack, Tooltip, Typography, styled, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ItemState, UnitAlarmState } from "../api/Alarm";
import { Operation, asUnitSubject } from "../api/Authz";
import { AccessOffering, AlarmOffering, Offering, RecordingOffering, StatisticsOffering, TimelapseOffering, TrackingOffering, Unit, UnitType, VideoOffering, WeatherOffering } from "../api/Customer";
import useAuthorizer, { useUnitPerimeterPermission, useUnitPermission } from "../auth/AuthorizerProvider";
import { eyeTrackEndpointURL, retainConfig, unitURL } from "../config/urls";
import { ImageWithLoading } from "./ImageWithLoading";
import { UnitIcon } from "./UnitIcon";
import { UnitMenu } from "./UnitMenu";

const GrantedActionButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.light,
    padding: '7px 7px 7px 7px',
    margin: '1px 1px 1px 1px',
    backgroundColor: 'rgba(45, 45, 45, 0.75)',
    fontSize: 'small',
    '&:hover': {
        color: theme.palette.grey[200],
        backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
        color: theme.palette.grey[500],
        backgroundColor: 'rgba(45, 45, 45, 0.75)',
    },
})) as typeof IconButton;

const DeniedActionButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.secondary.light,
    padding: '7px 7px 7px 7px',
    margin: '1px 1px 1px 1px',
    background: 'rgba(5, 45, 60, 0.75)',
    '&.Mui-disabled': {
        color: '#ff4848',
        background: 'rgba(5, 45, 60, 0.75)',
    },
})) as typeof IconButton;

const offeringOperation = (o: string) => {
    switch (o) {
        case AccessOffering:
            return Operation.UI_VIEW_ACCESS
        case VideoOffering:
            return Operation.UI_VIEW_VIDEO
        case RecordingOffering:
            return Operation.UI_VIEW_VIDEO
        case StatisticsOffering:
            return Operation.UI_VIEW_STATISTICS
        case TimelapseOffering:
            return Operation.UI_VIEW_TIMELAPSE
        case TrackingOffering:
            return Operation.UI_VIEW_TRACKING
        case WeatherOffering:
            return Operation.UI_VIEW_WEATHER
        case AlarmOffering:
            return Operation.UI_VIEW_ALARM
        default:
            return Operation.OPERATION_UNSPECIFIED
    }
}

export interface UnitInfoProps {
    unit: Unit
    alarmState: UnitAlarmState
    offerings: Offering[]
    onSetUnitAlarmState: (state: ItemState) => void
    onDisableUnitPowerSave: () => void
}

export function UnitInfo(props: UnitInfoProps) {
    const { unit, alarmState, offerings, onSetUnitAlarmState, onDisableUnitPowerSave } = props

    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()
    const { allowOperation } = useAuthorizer()

    const allowGetPowerSave = useUnitPermission(Operation.GET_POWER_SAVE, unit)
    const allowDisablePowerSave = useUnitPermission(Operation.DISABLE_POWER_SAVE, unit)
    const allowGetPartition = useUnitPerimeterPermission(Operation.GET_PARTITION, unit)
    const allowArmPartition = useUnitPerimeterPermission(Operation.ARM_PARTITION, unit)
    const allowDisarmPartition = useUnitPerimeterPermission(Operation.DISARM_PARTITION, unit)

    const unitOfferings = useMemo(
        () => new Set(unit.Offerings?.map(o => o.DisplayName) || []),
        [unit],
    )

    const isAuthorized = useMemo(
        () => new Set(
            unit.Offerings
                ?.filter(o => allowOperation(offeringOperation(o.DisplayName), asUnitSubject(unit)))
                ?.map(o => o.DisplayName)
        ),
        [unit, allowOperation],
    )

    const unitSnapshot = useMemo(() =>
        isAuthorized.has(VideoOffering) ?
            eyeTrackEndpointURL(unit, `cameras/${unit.UnitConfig.SnapshotCameraID || 1}/snapshot?resolution=600x600`) :
            "",
        [isAuthorized, unit],
    )

    const getUnitIcon = (offeringID: string) => {
        switch (offeringID) {
            case AccessOffering:
                return <MeetingRoom fontSize="small" />
            case VideoOffering:
                return <Videocam fontSize="small" />
            case RecordingOffering:
                return <VideoLibrary fontSize="small" />
            case StatisticsOffering:
                return <Leaderboard fontSize="small" />
            case TimelapseOffering:
                return <PhotoCamera fontSize="small" />
            case TrackingOffering:
                return <PinDrop fontSize="small" />
            case WeatherOffering:
                return <Cloud fontSize="small" />
            case AlarmOffering:
                return <NotificationsActive fontSize="small" />
        }
        return <Help fontSize="small" />
    }

    const getUnitLink = (offeringID: string) => {
        switch (offeringID) {
            case VideoOffering:
                return `/video/units/${unit.ShortName}`
            case AlarmOffering:
                return `/alarm/units/${unit.ShortName}`
            case StatisticsOffering:
                return `/statistics/units/${unit.ShortName}`
            case TimelapseOffering:
                return `/timelapse/units/${unit.ShortName}`
            case TrackingOffering:
                return `/tracking/units/${unit.ShortName}`
            case RecordingOffering:
                return `/recording/units/${unit.ShortName}`
            case AccessOffering:
                return `/access/units/${unit.ShortName}`
            case WeatherOffering:
                return `/weather/units/${unit.ShortName}`
        }
        return unitURL(unit)
    }

    const getAlarmStateColor = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return "secondary"
            case ItemState.Off:
                return "success"
            case ItemState.Partial:
                return "warning"
            case ItemState.Unknown:
                return "default"
        }
    }

    const getAlarmStateText = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return t("alarm.armed")
            case ItemState.Off:
                return t("alarm.disarmed")
            case ItemState.Partial:
                return t("alarm.partial")
            case ItemState.Unknown:
                return t("alarm.unknown")
        }
    }

    const getAlarmStateIcon = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return <NotificationsOutlined />
            case ItemState.Off:
                return <NotificationsOffOutlined />
            case ItemState.Partial:
                return <NotificationImportantOutlined />
            case ItemState.Unknown:
                return <HelpOutline />
        }
    }

    const getAlarmStateChip = (
        key: string,
        armTooltip: string,
        disarmTooltip: string,
        state: ItemState,
        onClick: (state: ItemState) => void,
    ) => {
        return (
            <Tooltip title={t(state === ItemState.On || state === ItemState.Partial ? disarmTooltip : armTooltip)} disableInteractive>
                <Chip
                    key={key}
                    icon={getAlarmStateIcon(state)}
                    label={getAlarmStateText(state).toLowerCase()}
                    color={getAlarmStateColor(state)}
                    onClick={() => onClick(state)}
                    size="small"
                />
            </Tooltip>
        )
    }

    const enableUnitAlarm = () => onSetUnitAlarmState(ItemState.On)
    const disableUnitAlarm = () => onSetUnitAlarmState(ItemState.Off)

    const onUnitAlarmClicked = (state: ItemState) => {
        if (state === ItemState.On) {
            if (!allowDisarmPartition) {
                snackbar.enqueueSnackbar(t("message.forbidden_unit_disarm", { "unit": unit.ShortName }), { variant: 'warning' })
            } else {
                disableUnitAlarm()
            }
        } else {
            if (!allowArmPartition) {
                snackbar.enqueueSnackbar(t("message.forbidden_unit_arm", { "unit": unit.ShortName }), { variant: 'warning' })
            } else {
                enableUnitAlarm()
            }
        }
    }

    const renderUnitArmedState = () => {
        if (!allowGetPartition) {
            return <></>
        }

        return getAlarmStateChip(
            `unit-alarm-state-${unit.ID}`,
            "action.unit_arm_tooltip",
            "action.unit_disarm_tooltip",
            alarmState.Armed || ItemState.Unknown,
            state => onUnitAlarmClicked(state)
        )
    }

    const renderUnitPowerSavingState = () => {
        if (!allowGetPowerSave) {
            return <></>
        }
        if (alarmState.PowerSaving !== ItemState.On) {
            return <></>
        }
        const icon = <BatterySaver fontSize="small" htmlColor="orange" />

        return (
            <Tooltip title={t("state.power_saving_enabled")} disableInteractive>
                {allowDisablePowerSave ?
                    <IconButton
                        size="small"
                        color="info"
                        sx={{ width: 24, height: 24, padding: 0, margin: 0 }}
                        onClick={onDisableUnitPowerSave}
                    >
                        {icon}
                    </IconButton> :
                    icon
                }
            </Tooltip >
        )
    }

    const unitTypeTag = (type: UnitType) => {
        switch (type) {
            case UnitType.City: return "C"
            case UnitType.Light: return "L"
            case UnitType.Micro: return "M"
            case UnitType.Site: return "S"
            case UnitType.Heavy: return "H"
            case UnitType.Hub: return "B"
            default: return "?"
        }
    }

    const renderUnitType = () => {
        const tag = unitTypeTag(unit.Type)
        if (!tag) {
            return null
        }
        return <Tooltip title={`${t("customer.unit_type")}: ${unit.Type}`} disableInteractive>
            <Stack sx={{
                width: 16,
                height: 16,
                backgroundColor: "gray",
                borderRadius: 0.5,
                color: "black",
                mixBlendMode: "screen",
            }}>
                <Typography variant="caption" align="center" fontWeight="bold">
                    {tag}
                </Typography>
            </Stack>
        </Tooltip>
    }

    return (
        <Box
            key={"unit-panel-" + unit.ID}
            id={unit.ShortName}
            sx={{
                position: "relative",
                backgroundColor: theme.palette.background.default,
                borderStyle: "solid",
                borderColor: theme.palette.panel.border,
                borderWidth: 1,
                boxShadow: 3,
            }}>
            <Box sx={{ pl: 0.5, pr: 0.2, py: 0.5, display: 'flex', flexGrow: 1 }}>
                <Stack direction="row" width="100%" spacing={0.5} alignItems="center">
                    <UnitIcon unit={unit} />
                    <Typography>{unit.DisplayName}</Typography>
                    {renderUnitArmedState()}
                    {renderUnitPowerSavingState()}
                    <Box flexGrow={1} />
                    <UnitMenu unit={unit} />
                </Stack>
            </Box>
            <Box sx={{
                display: "block",
                width: "100%",
                height: "auto",
                position: "relative",
                overflow: "hidden",
                aspectRatio: "16/9",
            }}>
                <Box sx={{ background: "transparent", position: "absolute", right: 0, top: 0, m: 0.5 }}>
                    {renderUnitType()}
                </Box>
                <ImageWithLoading
                    src={unitSnapshot}
                    alt={unit.DisplayName}
                />
            </Box>
            <Box sx={{ background: "transparent", position: "absolute", bottom: 0, m: 0.5 }}>
                {offerings.map(offering => {
                    if (offering.DisplayName === RecordingOffering) {
                        // From revision 3, video and archive UIs are merged into one.
                        return null
                    }

                    const authorized = isAuthorized.has(offering.DisplayName)
                    const unauthorized = unitOfferings.has(offering.DisplayName) && !authorized
                    const suffix = authorized ? '' : ` (${t(unauthorized ? "customer.user_not_allowed" : "customer.service_not_subscribed")})`
                    const label = `open ${offering.DisplayName}`
                    const link = getUnitLink(offering.DisplayName)
                    const href = link.startsWith("http")
                    return (<Tooltip key={offering.ID} title={`${t("offering." + offering.DisplayName.toLowerCase())}${suffix}`} disableInteractive>
                        {(href
                            &&
                            <span>
                                {unauthorized &&
                                    <DeniedActionButton aria-label={label} disabled={!authorized} href={link}>
                                        {getUnitIcon(offering.DisplayName)}
                                    </DeniedActionButton>}
                                {!unauthorized &&
                                    <GrantedActionButton aria-label={label} disabled={!authorized} href={link}>
                                        {getUnitIcon(offering.DisplayName)}
                                    </GrantedActionButton>}
                            </span>) ||
                            <span>
                                {unauthorized &&
                                    <DeniedActionButton
                                        aria-label={label}
                                        disabled={!authorized}
                                        to={retainConfig({ pathname: link })}
                                        component={Link}
                                    >
                                        {getUnitIcon(offering.DisplayName)}
                                    </DeniedActionButton>}
                                {!unauthorized &&
                                    <GrantedActionButton
                                        aria-label={label}
                                        disabled={!authorized}
                                        to={retainConfig({ pathname: link })}
                                        component={Link}
                                    >
                                        {getUnitIcon(offering.DisplayName)}
                                    </GrantedActionButton>}
                            </span>
                        }
                    </Tooltip>)
                })}
            </Box>
        </Box>
    )
}

import { BatteryAlert, CloudOff, History, NotificationsActive, Sensors } from "@mui/icons-material"
import { FormControlLabel, Stack, Switch, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Item, StateON } from "../../api/OpenHAB"
import { NotificationIcon } from "./NotificationIcon"

export interface ZoneInfoProps {
    bypass: Item
    disabled: boolean
    alarm: boolean
    memoryAlarm: boolean
    violation: boolean
    lowBattery: boolean
    noConnection: boolean
    flipSwitch: (item: Item) => void
}

export const ZoneInfo = (props: ZoneInfoProps) => {
    const { bypass, disabled, alarm, memoryAlarm, violation, lowBattery, noConnection, flipSwitch } = props
    const theme = useTheme()
    const { t } = useTranslation()

    return <Stack direction="row" px={1} sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}>
        <FormControlLabel
            control={
                <Switch
                    checked={bypass.state === StateON}
                    disabled={disabled}
                    onClick={() => flipSwitch(bypass)}
                />
            }
            label={bypass.label}
        />
        <NotificationIcon
            onTooltip={t("alarm.alarm_active")}
            offTooltip={t("alarm.alarm_inactive")}
            condition={alarm}
            iconCtor={p => <NotificationsActive sx={p} />}
        />
        <NotificationIcon
            onTooltip={t("alarm.in_memory_active")}
            offTooltip={t("alarm.in_memory_inactive")}
            condition={memoryAlarm}
            iconCtor={p => <History sx={p} />}
        />
        <NotificationIcon
            onTooltip={t("alarm.violation_active")}
            offTooltip={t("alarm.violation_inactive")}
            condition={violation}
            iconCtor={p => <Sensors sx={p} />}
        />
        <NotificationIcon
            onTooltip={t("alarm.low_battery_active")}
            offTooltip={t("alarm.low_battery_inactive")}
            condition={lowBattery}
            iconCtor={p => <BatteryAlert sx={p} />}
        />
        <NotificationIcon
            onTooltip={t("alarm.disconnect_active")}
            offTooltip={t("alarm.disconnect_inactive")}
            condition={noConnection}
            iconCtor={p => <CloudOff sx={p} />}
        />
    </Stack>
}
import { Button, Dialog, DialogActions, DialogTitle, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Operation } from "../api/Authz";
import { Identifier, identifierName, identifierTypeElement } from "../api/Identifier";
import { User } from "../api/User";
import { useCustomerPermission } from "../auth/AuthorizerProvider";
import { http } from "../backend/request";
import { request } from "../config/headers";
import { endpointURL } from "../config/urls";
import { formatUserLong } from "../widgets/format";
import { IdentifierActions } from "./IdentifierActions";
import { IdentifierDialog } from "./IdentifierDialog";

export interface IdentifierManagementDialogProps {
    customerID: number
    onClose: () => void
}

export function IdentifierManagementDialog(props: IdentifierManagementDialogProps) {
    const { customerID, onClose } = props
    const [loaded, setLoaded] = React.useState(false)
    const [error, setError] = React.useState<any>()
    const [users, setUsers] = React.useState<User[]>([])
    const [identifiers, setIdentifiers] = React.useState<Identifier[]>([])
    const [identifierDialogOpen, setIdentifierDialogOpen] = React.useState(false)
    const [editingIdentifier, setEditingIdentifier] = React.useState<Identifier | undefined>(undefined)

    const { t } = useTranslation()
    const theme = useTheme()
    const confirm = useConfirm()
    const snackbar = useSnackbar()

    const allowCreate = useCustomerPermission(Operation.CREATE_CUSTOMER_IDENTIFIER, customerID)
    const allowEdit = useCustomerPermission(Operation.UPDATE_CUSTOMER_IDENTIFIER, customerID)
    const allowDelete = useCustomerPermission(Operation.DELETE_CUSTOMER_IDENTIFIER, customerID)

    useEffect(() => {
        if (customerID) {
            setLoaded(false)
            setError(null)
            Promise.all([
                http<User[]>("Loading customer users", endpointURL(`customers/${customerID}/users`), snackbar, request)
                    .then(
                        result => setUsers(result),
                        setError
                    ),
                http<Identifier[]>("Loading customer identifiers", endpointURL(`customers/${customerID}/identifiers`), snackbar, request)
                    .then(
                        result => setIdentifiers(result),
                        setError
                    )]
            ).then(
                () => setLoaded(true),
            )

        }
    }, [customerID, snackbar])

    const onEdit = (identifier: Identifier) => {
        setEditingIdentifier(identifier)
        setIdentifierDialogOpen(true)
    }

    const onNew = () => {
        setEditingIdentifier(undefined)
        setIdentifierDialogOpen(true)
    }

    const onCreate = (identifier: Identifier) => {
        http<Identifier>(`Creating identifier '${identifier.Type}/${identifier.Value}'`, endpointURL(`identifiers`), snackbar, {
            method: 'POST',
            headers: request.headers,
            body: JSON.stringify(identifier),
        })
            .then(
                result => setIdentifiers(identifiers.concat(result))
            )
            .catch(e => console.log(e))
            .finally(
                () => setIdentifierDialogOpen(false)
            )
    }

    const onUpdate = (identifier: Identifier) => {
        http<Identifier>(`Updating identifier '${identifier.Type}/${identifier.Value}'`, endpointURL(`identifiers/${identifier.ID}`), snackbar, {
            method: 'PUT',
            headers: request.headers,
            body: JSON.stringify(identifier),
        })
            .then(
                result => setIdentifiers(identifiers.map(i => i.ID === identifier.ID ? result : i))
            )
            .catch(e => console.log(e))
            .finally(
                () => setIdentifierDialogOpen(false)
            )
    }

    const onDelete = (identifier: Identifier) => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.delete_identifier", { identifier: `${identifierName(identifier.Type, t)}: ${identifier.Value}` }),
            cancellationText: t("action.cancel"),
            confirmationText: t("action.delete"),
            confirmationButtonProps: {
                color: 'secondary',
            },
        })
            .then(() => {
                http<any>(`Deleting identifier '${identifier.Type}/${identifier.Value}'`, endpointURL(`identifiers/${identifier.ID}`), snackbar, {
                    method: 'DELETE',
                    headers: request.headers,
                })
                    .then(() => setIdentifiers(identifiers.filter(i => i.ID !== identifier.ID)))
                    .catch(e => console.log(e))
            })
            .catch(() => { })
    }

    const onUserChange = (identifier: Identifier, userID: number) => {
        const user = users.find(u => u.ID === userID)
        if (user) {
            onAssign(identifier, user)
        } else {
            onUnassign(identifier)
        }
    }

    const onAssign = (identifier: Identifier, user: User) => {
        http<Identifier>(`Updating identifier '${identifier.Type}/${identifier.Value}'`, endpointURL(`identifiers/${identifier.ID}/assignments`), snackbar, {
            method: 'PUT',
            headers: request.headers,
            body: JSON.stringify({
                UserID: user.ID
            }),
        })
            .then(
                result => setIdentifiers(identifiers.map(i => i.ID === identifier.ID ? result : i)),
            )
            .catch(e => console.log(e))
    }

    const onUnassign = (identifier: Identifier) => {
        http<Identifier>(`Updating identifier '${identifier.Type}/${identifier.Value}'`, endpointURL(`identifiers/${identifier.ID}/assignments`), snackbar, {
            method: 'DELETE',
            headers: request.headers,
        })
            .then(
                result => setIdentifiers(identifiers.map(i => i.ID === identifier.ID ? result : i)),
            )
            .catch(e => console.log(e))
    }

    const identifierRow = (identifier: Identifier) => {
        return (
            <TableRow key={identifier.ID}>
                <TableCell>{identifierTypeElement(identifier.Type, t)}</TableCell>
                <TableCell>{identifier.Value}</TableCell>
                <TableCell>{identifier.Description}</TableCell>
                <TableCell>
                    <Select
                        value={identifier?.UserID || 0}
                        disabled={!allowEdit}
                        onChange={event => onUserChange(identifier, event.target.value as number)}
                        key={`assigned-user-${identifier.ID}`}
                        variant="standard"
                        fullWidth
                    >
                        {
                            [
                                <MenuItem key="unassigned" value={0}>
                                    <Typography style={{ fontSize: "small", fontStyle: "italic", color: theme.palette.grey[400] }}>
                                        {t("message.unassigned")}
                                    </Typography>
                                </MenuItem>
                            ].concat(
                                users.map(u =>
                                    <MenuItem key={u.ID} value={u.ID}>
                                        <Typography style={{ fontSize: "small" }}>
                                            {formatUserLong(t, u)}
                                        </Typography>
                                    </MenuItem>)
                            )
                        }
                    </Select>
                </TableCell>
                <TableCell align="right">
                    <IdentifierActions
                        allowEdit={allowEdit}
                        allowDelete={allowDelete}
                        allowUnassign={!!identifier.UserID && allowEdit}
                        onEdit={() => onEdit(identifier)}
                        onDelete={() => onDelete(identifier)}
                        onUnassign={() => onUnassign(identifier)}
                    />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="identifier-management-dialog-title" maxWidth='md' open={!!customerID}>
            <DialogTitle id="identifier-management-dialog-title">
                {t("dialog.identifier_management.title")}
            </DialogTitle>
            {error &&
                <Typography>{t("message.no_identifiers")}</Typography>
            }
            {!loaded && !error &&
                <Typography>{t("message.loading")}</Typography>
            }
            {loaded && !error &&
                <TableContainer component="div" style={{ overflow: 'auto', paddingBottom: 5, maxHeight: '75%' }}>
                    <Table aria-label="identifiers table" size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("field.type")}</TableCell>
                                <TableCell>{t("field.value")}</TableCell>
                                <TableCell>{t("field.description")}</TableCell>
                                <TableCell>{t("field.assignee")}</TableCell>
                                <TableCell>{t("field.actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {identifiers.map(i => identifierRow(i))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <DialogActions>
                <Button onClick={onNew} variant="contained" color="primary" disabled={!allowCreate}>
                    {t("action.new_identifier")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.close")}
                </Button>
            </DialogActions>
            <IdentifierDialog
                open={identifierDialogOpen}
                identifier={editingIdentifier}
                customerID={customerID}
                onClose={() => setIdentifierDialogOpen(false)}
                onUpdate={onUpdate}
                onCreate={onCreate}
            />
        </Dialog >
    )
}

import { ArrowUpward, CameraAlt, Download, Videocam, ZoomOutMap } from "@mui/icons-material";
import { Box, Grid, IconButton, ImageListItem, ImageListItemBar, Tooltip, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { ProviderContext, useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Operation } from "../api/Authz";
import { Customer } from "../api/Customer";
import { Snapshot, SnapshotsResponse } from "../api/Snapshot";
import { User } from "../api/User";
import { useCameraPermission } from "../auth/AuthorizerProvider";
import { http } from "../backend/request";
import ImageModal from "../components/ImageModal";
import { ImageWithLoading } from "../components/ImageWithLoading";
import { PageError } from "../components/PageError";
import { UnitAppBar } from "../components/UnitAppBar";
import { request } from "../config/headers";
import { minPageWidth } from "../config/sizing";
import { Second, ToShortLocalDateTime } from "../config/time";
import { retainConfig, snapshotEndpointURL } from "../config/urls";
import { LoadingBox } from "../features/events/LoadingBox";
import { useCooldown } from "../hooks/cooldown";

export interface SnapshotScrollProps {
  actor: User;
  customers: Customer[];
}

export function SnapshotScroll(props: SnapshotScrollProps) {
  const { actor, customers } = props;

  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState<string>("");
  const [hasMore, setHasMore] = useState(true)
  const [shouldLoad, setShouldLoad] = useState(true);

  const [zoomSnapshot, setZoomSnapshot] = useState("");
  const handleZoomButton = (snapshotURL: string) => {
    setZoomSnapshot(snapshotURL);
  };

  const [errorIndexes, setErrorIndexes] = useState<number[]>([]);
  const handleImageError = (index: number) => {
    setErrorIndexes((prevIndexes) => [...prevIndexes, index]);
  };


  const theme = useTheme()
  const cooldown = useCooldown(10 * Second)

  const { shortName, cameraID, preset } = useParams();
  const cameraIDnumber = cameraID ? parseInt(cameraID, 10) : NaN;

  const unit = useMemo(
    () => customers
      .flatMap(c => c.Units)
      .find(u => u.ShortName === shortName),
    [customers, shortName],
  )

  const site = useMemo(
    () => customers
      .flatMap(c => c.Sites)
      .find(s => s.ID === unit?.SiteID),
    [customers, unit],
  )

  const camera = useMemo(() => {
    return unit?.UnitConfig.Cameras.find(c => c.ID === cameraIDnumber);
  }, [unit, cameraIDnumber])

  const allowView = useCameraPermission(Operation.UI_VIEW_CAMERA_SNAPSHOTS, unit, cameraIDnumber)
  const allowDownload = useCameraPermission(Operation.EXPORT_CAMERA_TIMELAPSE, unit, cameraIDnumber)
  const allowRecording = useCameraPermission(Operation.STREAM_CAMERA_ARCHIVE, unit, cameraIDnumber)

  const customerID = site?.CustomerID;
  const siteID = unit?.SiteID;
  const unitName = unit?.ShortName;

  const limit = 20;
  const endpoint = `customers/${customerID}/sites/${siteID}/units/${unitName}/cameras/${cameraID}/presets/${preset}?limit=${limit}`;

  const fetchSnapshots = useCallback(
    async (continuationToken: string, snackbar: ProviderContext) => {
      const url = `${endpoint}&token=${continuationToken}`
      http<SnapshotsResponse>(
        "Loading snapshots",
        snapshotEndpointURL(url),
        snackbar,
        request,
      )
        .then(resp => {
          const { data, pagination } = resp;
          if (!data) {
            setHasMore(false)
            return
          }
          setSnapshots(prev => prev.concat(data));
          if (!pagination?.continuation) {
            setHasMore(false)
            return
          }
          setToken(pagination?.continuation);
        })
        .catch(() => cooldown.trigger())
        .finally(() => setIsLoading(false))
    }, [endpoint, cooldown])

  // Observer set ShouldLoad to true only when the last element node is visible to the viewport.
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback((node: any) => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setShouldLoad(true)
        }
      },
      { threshold: 1 }
    );

    if (node) observer.current.observe(node)
  }, [])

  useEffect(() => {
    if (cooldown.cooling) return;
    if (isLoading) return;
    if (!shouldLoad) return;
    if (!hasMore) return;

    setIsLoading(true)
    fetchSnapshots(token, snackbar);
    setShouldLoad(false)
  }, [isLoading, token, snackbar, shouldLoad, hasMore, cooldown.cooling, fetchSnapshots]);

  const renderSnapshot = (size: number, index: number, snapshot: Snapshot) => {
    const active = !errorIndexes.includes(index)
    return (
      <Box
        ref={size === index + 1 ? lastElementRef : undefined}
        onClick={active ? () => handleZoomButton(snapshot.url) : undefined}
        style={{ cursor: active ? "pointer" : undefined }}
      >
        <ImageWithLoading
          src={snapshot.url}
          alt={snapshot.timestamp}
          onError={() => handleImageError(index)}
        />
      </Box>
    )
  }

  const renderSnapshotsScroller = () => {
    return (
      <Stack
        display="flex"
        justifyContent={"center"}
        p={2}
      >

        <Stack p={3} spacing={1} direction={"row"} alignItems="center">
          <CameraAlt htmlColor={theme.palette.text.secondary} />
          <Typography variant="h5">{t("offering.snapshots")}</Typography>
          <Typography variant="h6" noWrap={true} color={theme.palette.text.secondary}>({t("camera.nth_camera", { id: cameraID })}, {preset})</Typography>
          <Box flexGrow={1} />
          <Tooltip title={t("dialog.snapshot.back_to_overview_tooltip")} disableInteractive>
            <span>
              <IconButton
                to={retainConfig({ pathname: `/timelapse/units/${unitName}` })}
                component={Link}
                color='primary'
              >
                <ArrowUpward />
              </IconButton>
            </span>
          </Tooltip>

        </Stack>

        <Grid
          container
          spacing={1}
          wrap="wrap"
          sx={{ px: 2, pt: 0, pb: 2, overflow: "hidden" }}
        >
          {snapshots.map((snapshot, index) => (
            <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
              <ImageListItem
                sx={{
                  position: "relative",
                  backgroundColor: theme.palette.background.default,
                  borderStyle: "solid",
                  borderColor: theme.palette.panel.border,
                  borderWidth: 1,
                  boxShadow: 3,
                  overflow: "hidden"
                }}
              >
                {renderSnapshot(snapshots.length, index, snapshot)}
                <ImageListItemBar
                  title={errorIndexes.includes(index) ? "" : ToShortLocalDateTime(snapshot.timestamp)}
                  actionIcon={
                    <Stack direction="row">
                      <Tooltip title={t(allowDownload ? "dialog.snapshot.download_snapshot_tooltip" : "dialog.snapshot.download_snapshot_tooltip_forbidden")} disableInteractive>
                        <span>
                          <IconButton
                            to={snapshot.url} download
                            component={Link}
                            color='primary'
                            disabled={!allowDownload || errorIndexes.includes(index)}>
                            <Download />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <Tooltip title={t(allowRecording ? "dialog.snapshot.view_in_archive_tooltip" : "dialog.snapshot.view_in_archive_tooltip_forbidden")} disableInteractive>
                        <span>
                          <IconButton
                            to={retainConfig({ pathname: `/video/units/${unitName}`, search: `lock=${cameraID}&timestamp=${Date.parse(snapshot.timestamp)}` })}
                            component={Link}
                            color='primary'
                            disabled={!allowRecording || errorIndexes.includes(index)}>
                            <Videocam />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <Tooltip title={t("dialog.snapshot.zoom_snapshot_tooltip")} disableInteractive>
                        <span>
                          <IconButton
                            color='primary'
                            onClick={() => handleZoomButton(snapshot.url)}
                            disabled={errorIndexes.includes(index)}
                          >
                            <ZoomOutMap />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Stack>

                  }
                />
              </ImageListItem>
            </Grid>
          ))}
        </Grid>
      </Stack>
    )
  }

  const renderBody = () => {
    if (!allowView) {
      return <PageError message={t("message.forbidden_page")} />
    }

    if (!camera) {
      return <PageError message={t("message.camera_not_found", { id: cameraID })} />
    }

    if (!hasMore && snapshots.length === 0) {
      return <PageError message={t("message.no_snapshots_available")} />
    }

    if (snapshots.length > 0) {
      return renderSnapshotsScroller()
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        minWidth: minPageWidth,
        flexDirection: "column",
      }}
    >
      <UnitAppBar actor={actor} unit={unit} site={site} customers={customers} />
      {renderBody()}
      {isLoading && <LoadingBox />}
      <ImageModal
        open={!!zoomSnapshot}
        handleClose={() => setZoomSnapshot("")}
        imageUrl={zoomSnapshot}
      />
    </Box>
  );
}

import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Customer, Offering, OfferingPriority } from './api/Customer';
import { User } from './api/User';
import useAuth from './auth/AuthProvider';
import { RequireAuth } from './auth/RequireAuth';
import { http } from './backend/request';
import { LoadingPage } from './components/LoadingPage';
import { request } from './config/headers';
import { endpointURL } from './config/urls';
import { CustomerPermissionsPage } from './routes/CustomerPermissionsPage';
import { Customers } from './routes/Customers';
import { Login } from './routes/Login';
import { Password } from './routes/Password';
import { Recovered } from './routes/Recovered';
import { Recovery } from './routes/Recovery';
import { Reset } from './routes/Reset';
import { SiteVideo } from './routes/SiteVideo';
import { SnapshotScroll } from './routes/SnapshotScroll';
import { UnitAccess } from './routes/UnitAccess';
import { UnitAlarm } from './routes/UnitAlarm';
import { UnitStatistics } from './routes/UnitStatistics';
import { UnitTimelapse } from './routes/UnitTimelapse';
import { UnitTracking } from './routes/UnitTracking';
import { UnitVideo } from './routes/UnitVideo';
import { UnitWeather } from './routes/UnitWeather';
import { NewUnitAlarm } from './routes/NewUnitAlarm';

function App() {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [error, setError] = React.useState<any>(null)

    const [actor, setActor] = React.useState<User>({ ID: 0, Username: "unauthenticated", Surname: "", Name: "", Email: "", CustomerID: 0 })
    const [customers, setCustomers] = React.useState<Customer[]>([])
    const [offerings, setOfferings] = React.useState<Offering[]>([])

    const snackbar = useSnackbar()
    const auth = useAuth()

    useEffect(() => {
        if (auth.loading) {
            setIsLoaded(false)
            return
        }
        if (!auth.actor) {
            setActor({ ID: 0, Username: "unauthenticated", Surname: "", Name: "", Email: "", CustomerID: 0 })
            setIsLoaded(true)
            return
        }

        setActor(auth.actor.User)

        Promise.all([
            http<Customer[]>("Loading user's customers", endpointURL("customers"), snackbar, request)
                .then(setCustomers),
            http<Offering[]>("Loading global offerings", endpointURL("offerings"), snackbar, request)
                .then(result => setOfferings(
                    result.sort((o1, o2) => (OfferingPriority.get(o1.DisplayName) || 1000000) - (OfferingPriority.get(o2.DisplayName) || 1000000))
                )),
        ])
            .then(() => { setIsLoaded(true) })
            .catch(setError)
    }, [snackbar, auth])

    if (error) {
        return (
            <Typography>Error while loading application data.</Typography>
        )
    }
    if (!isLoaded) {
        return <LoadingPage />
    }

    return (
        <>
            <Routes>
                <Route path="/login" element={
                    <Login />
                } />
                <Route path="/password" element={
                    <Password />
                } />
                <Route path="/recovery" element={
                    <Recovery />
                } />
                <Route path="/recovered" element={
                    <Recovered />
                } />
                <Route path="/reset" element={
                    <Reset />
                } />
                <Route path="/" element={
                    <RequireAuth>
                        <Customers actor={actor} customers={customers} offerings={offerings} />
                    </RequireAuth>
                } />
                <Route path="/video/units/:shortName" element={
                    <RequireAuth>
                        <UnitVideo actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/video/sites/:siteID" element={
                    <RequireAuth>
                        <SiteVideo actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/alarm/units/:shortName" element={
                    <RequireAuth>
                        <UnitAlarm actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/newalarm/units/:shortName" element={
                    <RequireAuth>
                        <NewUnitAlarm
                            actor={actor}
                            customers={customers}
                        />
                    </RequireAuth>
                } />
                <Route path="/access/units/:shortName" element={
                    <RequireAuth>
                        <UnitAccess actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/permissions/customers/:customerID" element={
                    <RequireAuth>
                        <CustomerPermissionsPage actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/statistics/units/:shortName" element={
                    <RequireAuth>
                        <UnitStatistics actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/timelapse/units/:shortName" element={
                    <RequireAuth>
                        <UnitTimelapse actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/timelapse/units/:shortName/cameras/:cameraID/presets/:preset" element={
                    <RequireAuth>
                        <SnapshotScroll actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/tracking/units/:shortName" element={
                    <RequireAuth>
                        <UnitTracking actor={actor} customers={customers} />
                    </RequireAuth>
                } />
                <Route path="/weather/units/:shortName" element={
                    <RequireAuth>
                        <UnitWeather actor={actor} customers={customers} />
                    </RequireAuth>
                } />
            </Routes>
        </>
    )
}

export default App;

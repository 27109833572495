import { MoreVert, SettingsApplications } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Operation } from "../api/Authz";
import { Unit } from "../api/Customer";
import { Position } from "../api/PTZ";
import { useUnitPermission } from "../auth/AuthorizerProvider";
import { http, noSnackBar } from "../backend/request";
import { eyeTrackEndpointURL, staticURL, unitURL } from "../config/urls";

export interface UnitMenuProps {
    unit: Unit
}

export function UnitMenu(props: UnitMenuProps) {
    const { unit } = props
    const { t } = useTranslation()

    const allowOpenHAB = useUnitPermission(Operation.MANAGE_OPENHAB, unit)
    const allowTracking = useUnitPermission(Operation.VIEW_TRACKING, unit)

    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
    const menuOpen = Boolean(anchorEl)

    const onOpen = (event: React.MouseEvent) => setAnchorEl(event.currentTarget as Element)
    const onClose = () => setAnchorEl(null)

    const getPosition = (handler: (position: Position) => void) => {
        http<Position>('Getting unit position', eyeTrackEndpointURL(unit, `devices/1/position`), noSnackBar)
            .then(
                result => {
                    console.log("Got unit position", result)
                    handler(result)
                }
            )
            .catch(e => console.log(e))
    }
    const openGoogleMaps = () => {
        getPosition(p => {
            const url = `https://www.google.com/maps/search/?api=1&query=${p.Latitude},${p.Longitude}`
            // We need to call windows.open in the main thread otherwise iOS blocks us.
            // See: https://stackoverflow.com/questions/20696041
            setTimeout(() => { window.open(url, "_bank", "noreferrer") })
            onClose()
        })
    }
    const openWaze = () => {
        getPosition(p => {
            const url = `https://waze.com/ul?ll=${p.Latitude},${p.Longitude}&navigate=true`
            // We need to call windows.open in the main thread otherwise iOS blocks us.
            // See: https://stackoverflow.com/questions/20696041
            setTimeout(() => { window.open(url, "_bank", "noreferrer") })
            onClose()
        })
    }
    const openOpenHAB = () => {
        window.open(`${unitURL(unit)}/habpanel/#`, "_bank", "noreferrer")
        onClose()
    }

    if (!allowTracking && !allowOpenHAB) {
        return null
    }

    return (
        <Stack direction="row" alignItems="center">
            <Tooltip title={t("action.show_unit_menu")} disableInteractive>
                <IconButton
                    size="small" color="info"
                    sx={{ width: 24, height: 24, padding: 0, margin: 0 }}
                    onClick={onOpen}
                >
                    <MoreVert fontSize="medium" color="warning" />
                </IconButton>
            </Tooltip>
            <Menu
                id="unit-menu"
                keepMounted
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={onClose}
            >
                <MenuItem
                    onClick={openGoogleMaps}
                    disabled={!allowTracking}
                >
                    <ListItemIcon>
                        <img
                            src={staticURL("gmaps.svg")}
                            alt={t("action.navigate_google_maps")}
                            width="24px"
                            height="24px"
                        />
                    </ListItemIcon>
                    <ListItemText>
                        {t("action.navigate_google_maps")}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={openWaze}
                    disabled={!allowTracking}
                >
                    <ListItemIcon>
                        <img
                            src={staticURL("waze.svg")}
                            alt={t("action.navigate_waze")}
                            width="24px"
                            height="24px"
                        />
                    </ListItemIcon>
                    <ListItemText>
                        {t("action.navigate_waze")}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={openOpenHAB}
                    disabled={!allowOpenHAB}
                >
                    <ListItemIcon>
                        <SettingsApplications color="warning" />
                    </ListItemIcon>
                    <ListItemText>
                        {t("action.admin_settings")}
                    </ListItemText>
                </MenuItem>
            </Menu>
        </Stack>
    )
}

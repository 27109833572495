import { Home, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp, ZoomIn, ZoomInMap, ZoomOut, ZoomOutMap } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Joystick, JoystickShape } from "react-joystick-component";
import { staticURL } from "../../config/urls";
import { PTZButton } from "./PTZButton";

export interface PTZControlsProps {
    allowPanTilt: boolean
    allowZoom: boolean
    allowHome: boolean
    allowFocus: boolean
    allowFocusToggle: boolean
    onAimHome: () => void
    onStartMoving: (speedX: number, speedY: number, speedZoom: number) => void
    onStopMoving: () => void
    onStartFocusing: (speed: number) => void
    onStopFocusing: () => void
    onToggleFocus: () => void
}

const size = 65
const maxSpeed = 0.75
const maxFocusSpeed = 1

export function PTZControls(props: PTZControlsProps) {
    const {
        allowPanTilt, allowZoom, allowHome, allowFocus, allowFocusToggle,
        onAimHome, onStartMoving, onStopMoving, onStartFocusing, onStopFocusing, onToggleFocus,
    } = props

    const theme = useTheme()
    const { t } = useTranslation()

    const textIcon = (text: string) =>
        <Typography fontWeight="bold" width={24} height={24}>{text}</Typography>

    return (
        <>
            <table key="ptz-buttons" style={{
                zIndex: 30,
                position: "absolute",
                top: 33,
                left: 3,
                borderWidth: "0px",
                padding: "0px",
                margin: "0px",
                backgroundColor: theme.palette.panel.background,
                borderRadius: "5px"
            }}>
                <tbody>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <PTZButton
                                tooltip={t("action.move_up_tooltip")}
                                disabled={!allowPanTilt}
                                icon={<KeyboardArrowUp />}
                                onMouseDown={() => onStartMoving(0, maxSpeed, 0)}
                                onMouseUp={onStopMoving}
                            />
                        </td>
                        <td>
                            {!allowFocus &&
                                <PTZButton
                                    tooltip={t("action.toggle_autofocus_tooltip")}
                                    disabled={!allowFocusToggle}
                                    icon={textIcon("AF")}
                                    onClick={onToggleFocus}
                                />
                            }
                        </td>
                        {allowFocus &&
                            <>
                                <td>
                                    <PTZButton
                                        tooltip={t("action.toggle_autofocus_tooltip")}
                                        disabled={!allowFocusToggle}
                                        icon={textIcon("M")}
                                        onClick={onToggleFocus}
                                        color="warning"
                                    />
                                </td>
                            </>
                        }
                    </tr><tr>
                        <td>
                            <PTZButton
                                tooltip={t("action.move_left_tooltip")}
                                disabled={!allowPanTilt}
                                icon={<KeyboardArrowLeft />}
                                onMouseDown={() => onStartMoving(-maxSpeed, 0, 0)}
                                onMouseUp={onStopMoving}
                            />
                        </td>
                        <td>
                            <PTZButton
                                tooltip={t("action.aim_home_tooltip")}
                                disabled={!allowHome}
                                icon={<Home />}
                                onClick={onAimHome}
                            />
                        </td>
                        <td>
                            <PTZButton
                                tooltip={t("action.move_right_tooltip")}
                                disabled={!allowPanTilt}
                                icon={<KeyboardArrowRight />}
                                onMouseDown={() => onStartMoving(maxSpeed, 0, 0)}
                                onMouseUp={onStopMoving}
                            />
                        </td>
                        {allowFocus &&
                            <td>
                                <PTZButton
                                    tooltip={t("action.focus_in_tooltip")}
                                    disabled={!allowFocus}
                                    icon={<ZoomInMap />}
                                    onMouseDown={() => onStartFocusing(maxFocusSpeed)}
                                    onMouseUp={onStopFocusing}
                                />
                            </td>
                        }
                    </tr><tr>
                        <td>
                            <PTZButton
                                tooltip={t("action.zoom_in_tooltip")}
                                disabled={!allowZoom}
                                icon={<ZoomIn />}
                                onMouseDown={() => onStartMoving(0, 0, maxSpeed)}
                                onMouseUp={onStopMoving}
                            />
                        </td>
                        <td>
                            <PTZButton
                                tooltip={t("action.move_down_tooltip")}
                                disabled={!allowPanTilt}
                                icon={<KeyboardArrowDown />}
                                onMouseDown={() => onStartMoving(0, -maxSpeed, 0)}
                                onMouseUp={onStopMoving}
                            />
                        </td>
                        <td>
                            <PTZButton
                                tooltip={t("action.zoom_out_tooltip")}
                                disabled={!allowZoom}
                                icon={<ZoomOut />}
                                onMouseDown={() => onStartMoving(0, 0, -maxSpeed)}
                                onMouseUp={onStopMoving}
                            />
                        </td>
                        {allowFocus &&
                            <td>
                                <PTZButton
                                    tooltip={t("action.focus_out_tooltip")}
                                    disabled={!allowFocus}
                                    icon={<ZoomOutMap />}
                                    onMouseDown={() => onStartFocusing(-maxFocusSpeed)}
                                    onMouseUp={onStopFocusing}
                                />
                            </td>
                        }
                    </tr>
                </tbody>
            </table>
            <Box
                key="ptz-joystick"
                padding={2}
                zIndex={30}
                position="absolute"
                top={33}
                right={3}
                sx={{
                    borderWidth: "0px",
                    backgroundColor: theme.palette.panel.background,
                    borderRadius: "5px"
                }}>
                <Joystick
                    size={size}
                    disabled={!allowPanTilt}
                    stickImage={allowPanTilt ? staticURL("joystick.svg") : staticURL("joystick-disabled.svg")}
                    baseImage={allowPanTilt ? staticURL("joystick-base.svg") : staticURL("joystick-base-disabled.svg")}
                    baseShape={JoystickShape.Square}
                    move={
                        e => {
                            const maxSpeed = 0.75
                            const speedX = (e.x || 0) * maxSpeed
                            const speedY = (e.y || 0) * maxSpeed
                            onStartMoving(speedX, speedY, 0)
                        }
                    }
                    stop={
                        () => {
                            onStopMoving()
                        }
                    }
                />
            </Box>
        </>
    )
}

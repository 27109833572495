import { Box, Button, Tooltip, useTheme } from "@mui/material";
import { ReactElement, cloneElement } from "react";
import { useTranslation } from "react-i18next";

export interface CameraButtonProps {
    tooltip: string
    disabled?: boolean
    toggled?: boolean
    color?: string
    toggledColor?: string
    icon: ReactElement
    toggledIcon?: ReactElement
    onClick: () => void
}

export function CameraButton(props: CameraButtonProps) {
    const { tooltip, disabled, toggled, color, toggledColor, icon, toggledIcon, onClick } = props

    const theme = useTheme()
    const { t } = useTranslation()

    const borderColor = toggled ? toggledColor || theme.palette.primary.light : undefined
    const iconColor = disabled ? undefined :
        toggled ? toggledColor || theme.palette.primary.light : (color || theme.palette.primary.main)

    return (
        <Tooltip title={t(tooltip)} disableInteractive>
            <Box>
                <Button
                    variant="outlined"
                    sx={{ padding: "0px", margin: "0px", minWidth: "0px", flexShrink: 0, borderColor: borderColor }}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {cloneElement(toggled && toggledIcon ? toggledIcon : icon, {
                        fontSize: "small",
                        htmlColor: iconColor,
                    })}
                </Button>
            </Box>
        </Tooltip>
    )
}

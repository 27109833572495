import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Identifier, identifierElement } from "../api/Identifier";

export interface AssignIdentifierDialogProps {
    open: boolean
    identifiers: Identifier[]
    onClose: () => void
    onAssigned: (identifier: Identifier) => void
}

export function AssignIdentifierDialog(props: AssignIdentifierDialogProps) {
    const { open, identifiers, onClose, onAssigned } = props
    const [identifier, setIdentifier] = React.useState<Identifier | undefined>(identifiers[0])

    const { t } = useTranslation()

    useEffect(() => {
        setIdentifier(identifiers[0])
    }, [open, identifiers])

    const setIdentifierByID = (id: number) => {
        setIdentifier(identifiers.find(i => id === i.ID))
    }

    if (!open) {
        return <></>
    }
    return (
        <Dialog onClose={onClose} aria-labelledby="Assign-identifier-dialog-title" maxWidth='xl' open={open}>
            <DialogTitle id="Assign-identifier-dialog-title">
                {t("dialog.assign_identifier.title")}
            </DialogTitle>
            <DialogContent>
                <TextField
                    id="Identifier"
                    label={t("field.identifier")}
                    defaultValue={identifiers[0]?.ID}
                    onChange={event => setIdentifierByID(parseInt(event.target.value))}
                    select
                    fullWidth={true}
                >
                    {
                        identifiers.map(i =>
                            <MenuItem key={i.ID} value={i.ID}>
                                {identifierElement(i)}
                            </MenuItem>
                        )
                    }
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => identifier && onAssigned(identifier)} variant="contained" color="primary" disabled={!identifier}>
                    {t("action.assign")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog >
    )
}
